import React, {useEffect, useRef} from "react";
import {
    ddMMyyyTo_yyyy_MM_dd_string,
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    numberWithFixedDecimals,
    numericComparator,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    Validator
} from "../../../InfoPortalInterfaces";
import {Card, FormInstance} from "antd";
import {RuleSet} from "../../../../e-cap/util/RuleResultCache";
import styles from "../../styling/infoportal.module.css";
import {CompanySelection, CurrencySelection, descriptionFilter, PartnerSelection} from "../../InfoPortalDropdowns";
import {OpenItemsSelectionDate} from "../../InfoDateSelection";
import {IntlShape} from "react-intl/src/types";
import {retrieveNR5Report} from "../../../services/ReportSearch";
import {PortalUser} from "../../../../../components";
import {
    convertFromSpecifiedDateFormatToUserDefined,
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences
} from "../../../../users/UserFormatPreferenceServices";

function validatator(form:FormInstance<any>):Validator {
    const validator:IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {required: false, picture: '', readOnly: false, hidden: false, formatMessage:null, infoMessage:null};
            if (fieldName === 'keyDate') {
                result.required = true;
            }
            if (fieldName === 'company') {
                result.required = true;
            }
            if (fieldName ==='partner') {
                result.required = true;
            }

            return result;
        }
    };
    return new Validator(validator);
}

function BalancesSimpleSearch({currentUser, intl, distinctEntitlements, initialization, reportEntitlement,form, initialValuesCache,rerenderDependentFields}: InfoPortalSearchProperties) {
    const vertical = useRef<boolean>(false);

    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{companyIds:string[], newVal:string[]}>();

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue('partner') || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    useEffect(()=> {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue('company');
            const newVal = form.getFieldValue('partner');
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <Card size={"small"} style={{ backgroundColor: "#f1f3f5" }}>
        <div className={styles.selfBilledSimpleSearchContainer}>
            <div className={styles.singleColumnGrid}>
                <CompanySelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={true}
                    currentUser={currentUser}
                    initialValue={initialValuesCache?.getValue("company")}
                    intl={intl}
                    validator={validator}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    onSelectionChange={companySelectionChange}
                    filterOptions={descriptionFilter}
                    companyEntitlement={reportEntitlement}/>
                <PartnerSelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    currentUser={currentUser} intl={intl}
                    initialValue={initialValuesCache?.getValue('partner')}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    validator={validator}
                    labelLocation={vertical.current? LabelLocation.TOP: LabelLocation.LEFT}
                    displayColon={true}
                    companyIds={initialValuesCache?.getValue('company')}
                    externalStateUpdater={partnerSelectionUpdate}
                    filterOptions={descriptionFilter}
                    partnerEntitlement={reportEntitlement}/>
            </div>

            <div className={styles.singleColumnGrid}>
                <OpenItemsSelectionDate
                    form={form}
                    intl={intl}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    initialValue={initialValuesCache?.getValue('keyDate',stringToDayjs_yyyy_MM_dd)}
                    displayColon={true}
                    validator={validator}
                    labelLocation={vertical.current? LabelLocation.TOP: LabelLocation.LEFT}/>

                <CurrencySelection
                    initialValue={initialValuesCache?.getValue("currency")}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={true}
                    currentUser={currentUser}
                    intl={intl}
                    validator={validator}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />
            </div>
        </div>
    </Card>
}

export class BalancesAgGridColumnDef implements InfoPortalAgGridColumnDef {
    getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [
            {field:'compcode', headerName:intl.formatMessage({'id': 'grid-heading-company'})},
            {field:'partnerno', headerName:intl.formatMessage({'id': 'grid-heading-partnerNumber'})},
            {
                field:'keydate',
                headerName:intl.formatMessage({'id': 'grid-heading-openItemsatKeyDate'}),
                valueGetter: params => convertFromSpecifiedDateFormatToUserDefined(user,ddMMyyyTo_yyyy_MM_dd_string(params.data.keydate),"YYYY-MM-DD"),
                comparator:dateComparatorUsingUserPreferences(user)
            },
            {
                field:'totbalance',
                headerName:intl.formatMessage({'id': 'grid-heading-totalBalance'}),
                type: 'rightAligned',
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.totbalance),
                comparator:numericComparatorUsingUserPreferences(user)
            },

            {field:'currency', headerName:intl.formatMessage({'id': 'grid-heading-currency'})},
        ]
    }

    getShortViewColumns(): string[] {
        return [];
    }

    getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [
            {field:'refdocno', headerName:intl.formatMessage({'id': 'grid-heading-partnerInvoiceNumber'})},
            {field:'docno', headerName:intl.formatMessage({'id': 'grid-heading-bmwInvoiceNumber'})},
            {field:'doctype', headerName:intl.formatMessage({'id': 'grid-heading-documentType'})},
            {
                field:'docdate',
                headerName:intl.formatMessage({'id': 'grid-heading-documentDate'}),
                valueGetter: params => convertFromSpecifiedDateFormatToUserDefined(user,ddMMyyyTo_yyyy_MM_dd_string(params.data.docdate),"YYYY-MM-DD"),
                comparator:dateComparatorUsingUserPreferences(user)
            },
            {
                field:'pstngdate',
                headerName:intl.formatMessage({'id': 'grid-heading-postingDate'}),
                valueGetter: params => convertFromSpecifiedDateFormatToUserDefined(user,ddMMyyyTo_yyyy_MM_dd_string(params.data.pstngdate),"YYYY-MM-DD"),
                comparator:dateComparatorUsingUserPreferences(user)
            },
            {
                field:'duedate',
                headerName:intl.formatMessage({'id': 'grid-heading-dueDate'}),
                valueGetter: params => convertFromSpecifiedDateFormatToUserDefined(user,ddMMyyyTo_yyyy_MM_dd_string(params.data.duedate),"YYYY-MM-DD"),
                comparator:dateComparatorUsingUserPreferences(user)
            },
            {field:'pmntblock', headerName:intl.formatMessage({'id': 'grid-heading-payment-block'})},
            {
                field:'amtdoccur',
                headerName:intl.formatMessage({'id': 'grid-heading-amountinDocumentCurrency'}),
                type: 'rightAligned',
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.amtdoccur),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field:'currency', headerName:intl.formatMessage({'id': 'grid-heading-currency'})},
            {
                field:'cleardate',
                headerName:intl.formatMessage({'id': 'grid-heading-clearingDate'}),
                valueGetter: params => convertFromSpecifiedDateFormatToUserDefined(user,ddMMyyyTo_yyyy_MM_dd_string(params.data.cleardate),"YYYY-MM-DD"),
                comparator:dateComparatorUsingUserPreferences(user)
            },
            {field:'clrdocno', headerName:intl.formatMessage({'id': 'grid-heading-clearingDocument'})},
        ];
    }
}

export class NR5Report implements InfoPortalReport {
    getEntitlement(): string {
        return "display_nr@reports";
    }

    private _config = new BalancesAgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form:FormInstance,currentUser:any, distinctEntitlements:string[]):Promise<InfoPortalGridService> {
        return retrieveNR5Report(form, currentUser, distinctEntitlements).then(result=>
            new InfoPortalGridService(
                new ReportViewConfig('NR5',this._config), result,distinctEntitlements));
    }

    hasExpertSearch(): boolean {
        return false;
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache
                       }: InfoPortalSearchProperties): JSX.Element {
        return undefined;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <BalancesSimpleSearch initialization={initialization}
                                     currentUser={currentUser}
                                     intl={intl}
                                     form={form}
                                     distinctEntitlements={distinctEntitlements}
                                     rerenderDependentFields={rerenderDependentFields}
                                     initialValuesCache={initialValuesCache} reportEntitlement={this.getEntitlement()}/>;
    }

    getReportName(): string {
        return "NR5";
    }
}