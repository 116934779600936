import {HelpDocument} from "./HelpDocument";


export function getECapHelpFiles(): HelpDocument[] {
    const language = localStorage.getItem("locale") || 'en';

    const ecapUserGuide =
        new HelpDocument(
            'ecap-user-guide',
            ['eCap User Guide EN v0.12.pdf', 'eCap User Guide DE v0.12.pdf'],
            ['en','de'],
            language);

    const customerDocumentsOverView =
        new HelpDocument(
            'document-overview-customer',
            ['eDoc Document Overview for customers v0.3.pdf', 'eDoc Belegübersicht Kunden v0.3.pdf'],
            ['en','de'],
            language);
    const supplierDocumentsOverView =
        new HelpDocument(
            'document-overview-supplier',
            ['eDoc Document Overview for Suppliers v0.2.pdf', 'eDoc Belegübersicht Lieferant v0.2.pdf'],
            ['en','de'],
            language);
    const tnrDocumentsOverView =
        new HelpDocument(
            'document-overview-tnr-overview',
            ['eDoc Document Overview and New Retail Overview for customers v1.0.pdf'],
            ['en'],
            language);

//     const purchaseOrderFlip =
//         new HelpDocument(
//             'purchase-order-flip',
//             ['Purchase Order-Flip - USER Guide & Tutorial (EN).pdf','Bestell-Flip - Benutzerleitfaden & Tutorial (DE).pdf'],
//             ['en', 'de'],
//             language);

    return [ecapUserGuide,customerDocumentsOverView, supplierDocumentsOverView, tnrDocumentsOverView];
}

export function getInfoPortalHelpFiles(): HelpDocument[] {
    const language = localStorage.getItem("locale") || 'en';

    const infoPortalUserGuide =
        new HelpDocument(
            'info-portal-user-guide',
            ['My Reports_User_Guide_(EN)_v0.2.pdf','Meine Berichte_Benutzerleitfaden (DE)_v0.2.pdf'],
            ['en','de'],
            language);

    const infoPortalTutorial =
        new HelpDocument(
            'info-portal-user-tutorial-bmw-group',
            ['BMW Group Reports - Tutorial (English)_V0.2.pdf','BMW Group Berichte - Tutorial (German)_V0.2.pdf'],
            ['en','de'],
            language);

    const infoPortalMexicoTutorial =
        new HelpDocument(
            'info-portal-user-tutorial-mexico-group',
            ['My Reports_Mexico_Tutorial (EN).pdf'],
            ['en'],
            language);

    const infoPortalChinaTutorial =
        new HelpDocument(
            'info-portal-user-tutorial-china-group',
            ['My Reports_China_Tutorial (EN).pdf'],
            ['en'],
            language);

    const infoPortalNSCTutorial =
        new HelpDocument(
            'info-portal-user-tutorial-nsc-group',
            ['My Reports_NSC`s_Tutorial (EN).pdf'],
            ['en'],
            language);

    return [infoPortalUserGuide,infoPortalTutorial,infoPortalMexicoTutorial,infoPortalChinaTutorial,infoPortalNSCTutorial];
}

export function getSurveyOnboardingHelpFiles(): HelpDocument[] {
    const language = localStorage.getItem("locale") || 'en';

    const ediSurveyOnboardingTutorial =
        new HelpDocument(
            'edi-survey-tutorial',
            ['My Onboarding_EDI Survey_Tutorial (EN)_V0.6.pdf','Mein Onboarding_EDI Survey_Tutorial (DE)_V0.6.pdf'],
            ['en','de'],
            language);

    return [ediSurveyOnboardingTutorial];
}