import {FormInstance} from "antd";
import dayjs , {Dayjs} from 'dayjs';
import {IntlShape} from "react-intl/src/types";
import {IAppComponentProps, PortalUser} from "../../components";
import client from "../../config/GraphQLApolloClient";
import {DOWNLOAD_DOC_BY_BLOB_ID_AND_APPLICATION_TYPE, DOWNLOAD_REJECTION_LETTER_BY_URL} from "./graphql/queries";
import {
    retrieveDocumentByBlobIdAndApplicationType,
    retrieveDocumentByBlobIdAndApplicationType_retrieveDocumentByBlobIdAndApplicationType as BlobResponse
} from "./graphql/__generated__/retrieveDocumentByBlobIdAndApplicationType";
import {ColumnApi, GridApi} from "ag-grid-community";
import {XSuiteDownload, XSuiteDownloadVariables} from "./graphql/__generated__/XSuiteDownload";
import {ColDef, ColGroupDef} from "ag-grid-community/dist/lib/entities/colDef";

export enum DropDownSelectType {
    RADIO,
    DROP_DOWN
}

export enum DropDownSelectionMode {
    MULTIPLE,
    SINGLE
}

export enum ReportRegion {
    BMW_GENERAL,
    BMW_MEXICO,
    BMW_CHINA,
    BMW_NSC,
    BMW_GENERAL_COR
}

export enum InternalReport {
    REJECTION_LETTER = 10,
    KPI_USAGE
}

export enum LabelLocation {
    LEFT,
    TOP
}

export interface Initialization {
    beginInitialize(): string;

    finishInitialize(initializationId: string): void;
}

export interface OnChangeListener<T> {
    performAction(val: T);
}

export class DropDownUpdater {
    dropDownUpdater: (list: DropDownSelection[]) => void;
    valueUpdater: (theValues: string[]) => void;
}

export interface StateUpdateCallback<T> {
    performAction(val?: T);
}

export class ExternalStateUpdateCallback<T> {
    private _callbacks: StateUpdateCallback<T>[] = [];

    register(callback: StateUpdateCallback<T>) {
        this._callbacks.push(callback);
    }

    invokeCallBack(val?: T) {
        for (const callback of this._callbacks) {
            callback.performAction(val);
        }
    }
}

export interface DropDownDataSource {
    fetchData(): Promise<DropDownSelection[]>
}

export interface SelectionFilter {
    onTextChange(text: string, dropdownList: DropDownSelection[], optionsSetter: (arg: DropDownSelection[]) => void): Promise<any>;
}

export class DropDownSelection {
    phraseId: string;
    description: string;
    id: string;

    constructor(id: string, description: string, phraseId: string) {
        this.phraseId = phraseId;
        this.description = description;
        this.id = id;
    }
}

export function dropDownOverloadedConstructor(id: string, description: string) {
    return new DropDownSelection(id, description, null);
}

export function dropDownOverloadedConstructorWithPhraseId(id: string, phraseID: string) {
    return new DropDownSelection(id, null, phraseID);
}


type RuleSet = {
    required?: boolean;
    hidden?: boolean;
    readOnly?: boolean;
    picture?: string|string[];
    defaultValue?: any,
    formatMessage?: string|string[],
    infoMessage?: string;
};

export interface IValidator {
    fetchRule(fieldName: string): RuleSet;
}

export class Validator {
    private _validator: IValidator;

    constructor(validator: IValidator) {
        this._validator = validator;
    }

    fetchRule(fieldName: string) {
        const DEFAULT_RESULT = {
            required: false,
            picture: '',
            readOnly: false,
            hidden: false,
            formatMessage: null,
            infoMessage: null
        };
        return this._validator ? this._validator.fetchRule(fieldName) : DEFAULT_RESULT;
    }

    isReadOnly(fieldName: string) {
        return this.fetchRule(fieldName).readOnly;
    }

    isHidden(fieldName: string) {
        return this.fetchRule(fieldName).hidden;
    }

    isMandatory(fieldName: string) {
        return this.fetchRule(fieldName).required;
    }
}

export class SubmitSearch {
    _searchCallback: () => Promise<any>;

    triggerSearch(arg: () => Promise<any>, form: FormInstance<any>) {
        this._searchCallback = arg;
        form.submit();
    }

    performSearch() {
        return this._searchCallback();
    }
}

export class FieldValueCache {
    private _cache: any;

    constructor(cache: any) {
        this._cache = cache;
    }

    getValue(fieldName: string, mapper?: (input: any) => any) {
        const cacheElement = this._cache[fieldName];
        if (mapper && cacheElement) {
            return mapper(cacheElement);
        }
        return cacheElement;
    }

    getValueOrDefault(fieldName: string, defaultValue: any, mapper?: (input: any) => any) {
        const cachedElement = this.getValue(fieldName, mapper)
        return (cachedElement == null || cachedElement === undefined) ? defaultValue: cachedElement;
    }

    cacheFound() {
        return this._cache?.cachFound;
    }

    getFormValues(mapper?: Map<string, (input: any) => any>) {
        const formValues = {};
        Object.keys(this._cache).forEach(fieldName => {
                let value = this._cache[fieldName];
                if (mapper) {
                    value = mapper[fieldName](value);
                }
                formValues[fieldName] = value;
            }
        );
        return formValues;
    }
}

export function readCache(tabName: string, isExpertSearch: boolean, reportType: string) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const key = cacheKey(tabName, isExpertSearch, reportType);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const cachedCriteria = getCachedCriteria(key);
    let cache = {}
    if (cachedCriteria) {
        cache = JSON.parse(cachedCriteria);
        cache['cacheFound'] = true;
    } else {
        cache['cacheFound'] = false;
    }

    const fieldValueCache = new FieldValueCache(cache);
    return fieldValueCache;
}

function getCachedCriteria(cname) {
    return localStorage.getItem(cname) || '';
}

function saveCachedCriteria(cname: string, cvalue: string) {
    localStorage.setItem(cname, cvalue);
}

export function deleteCachedValues(tabName: string, isExpertSearch: boolean, reportType: string) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const key = cacheKey(tabName, isExpertSearch, reportType);
    localStorage.removeItem(key);
}

function cacheKey(tabName: string, isExpertType: boolean, reportType: string) {
    return `${tabName}_${isExpertType}_${reportType}`;
}

export function cacheFieldValues(form: FormInstance<any>, tabName: string, isExpertSearch: boolean, reportType: string, mappers: Map<string, (input: any) => any>) {
    const newObj = {...form.getFieldsValue()};
    for (const key in newObj) {
        const fieldValue = newObj[key];
        if (fieldValue) {
            const mapper = mappers.get(key);
            if (mapper) {
                newObj[key] = mapper(fieldValue);
            }
        }
    }

    const key = cacheKey(tabName, isExpertSearch, reportType);
    saveCachedCriteria(key, JSON.stringify(newObj));
}

export function savePersonalizedReportView(reportName: string, columns:  (ColDef | ColGroupDef)[]) {
    const key = `personalized_report_view_${reportName}`;
    localStorage.setItem(key, JSON.stringify(columns));
}

export function getPersonalizedReportView(reportName: string): (ColDef | ColGroupDef)[] {
    const key = `personalized_report_view_${reportName}`;
    const cachedValue = localStorage.getItem(key);
    if (cachedValue) {
        const parsedValue = JSON.parse(cachedValue);
        if (parsedValue && Array.isArray(parsedValue)) {
            if (typeof parsedValue[0] === "string") {
                return parsedValue.map(fieldName=> {
                    return {
                       colId:fieldName
                    }
                })
            }
        }

        return parsedValue;
    }

    return null;
}

export class InfoPortalColumnDef {
    field: string;
    headerName: string;
    type?: 'rightAligned' | 'leftAligned';
    width?:number;
    hide?: boolean;
    sort?: 'asc' | 'desc';
    sortIndex?:number;
    valueFormatter?: (params: any) => any;
    tooltipValueGetter?: (params: any) => any;
    valueGetter?: (params: any) => any;
    cellRenderer?: any;
    exportFormatter?: (params:any) => any;
    comparator?: (a:any,b:any) => number;
    filter?:string;
    filterParams?:any;
}

export interface InfoPortalAgGridColumnDef {
    getDefaultColumnDefinitions(intl: IntlShape, user:PortalUser, distinctEntitlements?: string[]): InfoPortalColumnDef[];

    getLineItemColumnDefinitions(intl: IntlShape, user:PortalUser,distinctEntitlements?: string[]): InfoPortalColumnDef[];

    getShortViewColumns(): string[];
}

export class ReportViewConfig {
    private _config: InfoPortalAgGridColumnDef;
    private _personalViewColumns: (ColDef | ColGroupDef)[];
    private _reportType: string;

    constructor(reportType: string, config: InfoPortalAgGridColumnDef) {
        this._reportType = reportType;
        this._personalViewColumns = getPersonalizedReportView(reportType);
        this._config = config;
    }

    get personalViewColumns():  (ColDef | ColGroupDef)[] {
        return this._personalViewColumns;
    }


    set personalViewColumns(value:  (ColDef | ColGroupDef)[]) {
        this._personalViewColumns = value;
    }

    get config(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    get reportType(): string {
        return this._reportType;
    }

    get hasPersonalizedView(): boolean {
        return (this?._personalViewColumns?.length || 0) > 0;
    }
}

export class GridViewType {
     private _isPrivateView = false;
     private _isShortView = false;
     private _isLongView = true;
    enableLongView() {
        this._isLongView = true;
        this._isPrivateView = this._isShortView = false;
        console.debug('enable long view')
    }

    enableShortView() {
        this._isShortView = true;
        this._isPrivateView = this._isLongView = false;
        console.debug('enable short view')
    }

    enablePrivateView() {
        this._isPrivateView = true;
        this._isShortView = this._isLongView = false;
        console.debug('enable private view')
    }


    get isPrivateView(): boolean {
        return this._isPrivateView;
    }

    get isShortView(): boolean {
        return this._isShortView;
    }

    get isLongView(): boolean {
        return this._isLongView;
    }
}


export class InfoPortalGridService {
    private _config: ReportViewConfig;
    private _data: any[];
    private _gridAPI:GridApi;
    private _columnAPI:ColumnApi;

    private _lineItemsField;
    private lineItemMapper: (data: any) => any[];
    private distinctEntitlements: string[];

    constructor(config: ReportViewConfig, data: any[], distinctEntitlements: string[]) {
        this._lineItemsField = 'positions';
        this.lineItemMapper = data => (data && data[this._lineItemsField]) || [];
        this._config = config;
        this._data = this.unfreezeData(data, this._lineItemsField);
        this.distinctEntitlements = distinctEntitlements;
    }

    private getSelectedDataView(intl: IntlShape, user:PortalUser, columns: (ColDef | ColGroupDef)[]):InfoPortalColumnDef[] {
        const chosenView: any[] = [];
        const defaultColumnDefinitions = this._config.config.getDefaultColumnDefinitions(intl, user, this.distinctEntitlements);
        for (const defaultColumnDefinition of defaultColumnDefinitions) {
            const columnDefCopy = {...defaultColumnDefinition, hide: true,weight:1000};

            for (let i=0;i<columns.length;i++) {
                const column = columns[i];
                if (columnDefCopy.field === column['colId']) {
                    columnDefCopy.hide = false || (defaultColumnDefinition.hide === true);
                    columnDefCopy.sort = column['sort'];
                    columnDefCopy.sortIndex = column['sortIndex'];
                    columnDefCopy.width = column['width'];
                    columnDefCopy.weight = i;
                    break;
                }
            }

            chosenView.push(columnDefCopy);
        }

        return chosenView.sort((a,b)=>a.weight-b.weight).map(item=> {return {...item,weight:undefined}});
    }

    private getSelectedColumns(intl: IntlShape, user:PortalUser, viewType:GridViewType) {
        if (viewType.isShortView) {
            return this._config.config.getShortViewColumns().map(fieldName=> {
                return {
                    colId:fieldName
                }
            });
        }

        if (viewType.isPrivateView) {
            return this._config.personalViewColumns;
        }

        return this._config.config
            .getDefaultColumnDefinitions(intl, user, this.distinctEntitlements)
            .map(definition => {
                return {
                    colId: definition.field
                    }
                });
    }

    hasShortView() {
        return (this._config.config.getShortViewColumns()?.length || 0) > 0;
    }

    getColumnDefinitions(intl: IntlShape, user:PortalUser, viewType:GridViewType): InfoPortalColumnDef[] {
        return this.getSelectedDataView(intl, user, this.getSelectedColumns(intl,user, viewType));
    }

    getLineItemColumnDefinitions(intl: IntlShape, user:PortalUser): InfoPortalColumnDef[] {
        return this._config.config.getLineItemColumnDefinitions(intl, user,this.distinctEntitlements);
    }

    getRowData(): any[] {
        return this._data;
    }

    hasRowData(): boolean {
        return this._data && this._data.length > 0
    }

    getNumberOfRows():number {
        return this._data?.length || 0;
    }

    getLineItems(data: any) {
        const lineItemMapper = this.lineItemMapper(data);
        return lineItemMapper.map((item, index) => {
            let IdItem = undefined;
            if (!item.id) {
                IdItem = {...item, id: index};
            }
            return IdItem ? IdItem : item;
        });
    }

    getLineItemMapper() {
        return this.lineItemMapper;
    }

    getReportName(): string {
        return this._config.reportType;
    }

    hasPersonalizedView(): boolean {
        return this._config.hasPersonalizedView;
    }

    savePersonalizedView(personalizedColumns:  (ColDef | ColGroupDef)[]) {
        savePersonalizedReportView(this.getReportName(), personalizedColumns);
        this._config.personalViewColumns = personalizedColumns;
    }

    private unfreezeData(data: any[], lineItemKey: string) {
        const unfrozenData: any[] = [];
        for (const datum of data) {
            const unfrozenDatum = {...datum};
            unfrozenData.push(unfrozenDatum)
            const lineItems = unfrozenDatum[lineItemKey];
            if (lineItems) {
                const unfrozenLineItems: any[] = [];
                for (const lineItem of lineItems) {
                    unfrozenLineItems.push({...lineItem});
                }
                unfrozenDatum[lineItemKey] = unfrozenLineItems;
            }
        }

        return unfrozenData;
    }

    getRowMetaData(intl: IntlShape, user:PortalUser, viewType: GridViewType): any {
        const columnDefinitions =
            this.getColumnDefinitions(intl, user, viewType)
                .reduce((defintionMap,curr)=>{
                    defintionMap[curr.field] = curr;
                    return defintionMap;
                },{});
        const result = {};

        for (const row of this._data) {
            const fields = Object.keys(row);
            this.calculateMetaData(fields, result, row,columnDefinitions);
        }

        return result;
    }

    getLineRowMetaData(intl: IntlShape, user:PortalUser, viewType: GridViewType): any {
        const columnDefinitions =
            this.getLineItemColumnDefinitions(intl,user)
                .reduce((defintionMap,curr)=>{
                    defintionMap[curr.field] = curr;
                    return defintionMap;
                },{});

        const result = {};
        for (const row of this._data) {
            const lineItems = row[this._lineItemsField];
            if (Array.isArray(lineItems)) {
                for (const lineItem of lineItems) {
                    const fields = Object.keys(lineItem);
                    this.calculateMetaData(fields, result, row, columnDefinitions);
                }
            }
        }



        return result;
    }

    private calculateMetaData(fields: string[], result: {}, row, columnDefinitions: any) {
        for (const field of fields) {
            if (field === this._lineItemsField) {
                continue;
            }

            let resultMetaData = result[field];
            if (!resultMetaData) {
                resultMetaData = {
                    hasNulls: false,
                    totalLength: 0,
                    maxLength: 0,
                    minLength: 100_000,
                    averageLength:0,
                    count: 0,
                    align:'left'
                };
                result[field] = resultMetaData;
            }

            const columnDefinition:InfoPortalColumnDef = columnDefinitions[field];
            let value = row[field];
            if (columnDefinition?.valueFormatter) {
                value = columnDefinition.valueFormatter({data:row});
            }
            if (columnDefinition?.exportFormatter) {
                value = columnDefinition.exportFormatter({data:row});
            }
            if (columnDefinition?.type === 'rightAligned') {
                result[field].align = 'right';
            }

            if (value) {
                const valStr = `${value}`;
                const length = valStr.length;
                resultMetaData.count++;
                resultMetaData.totalLength += length;
                if (resultMetaData.maxLength < length) {
                    resultMetaData.maxLength = length;
                }
                if (resultMetaData.minLength > length) {
                    resultMetaData.minLength = length;
                }
                resultMetaData.averageLength = resultMetaData.totalLength/resultMetaData.count;
            } else {
                resultMetaData.hasNulls = true;
            }
        }
    }


    get gridAPI(): GridApi {
        return this._gridAPI;
    }

    set gridAPI(value: GridApi) {
        this._gridAPI = value;
    }


    get columnAPI(): ColumnApi {
        return this._columnAPI;
    }

    set columnAPI(value: ColumnApi) {
        this._columnAPI = value;
    }
}

export declare type InfoPortalSearchProperties = {
    initialization: Initialization,
    reportEntitlement: string,
    initialValuesCache?: FieldValueCache,
    rerenderDependentFields?: boolean,
    form: FormInstance<any>
} & IAppComponentProps;

export interface InfoPortalTabConfig {
    searchViews(): Map<string, BaseInfoPortalReport>;

    getName(): string;

    getInitialSelectedReport(): string;

    getReportRegion(): ReportRegion | InternalReport;

    getEntitlement(): string;

    getTabLabel?: ()=> string;
}

export function dayjsToString_yyyy_MM_dd(value: Dayjs | Dayjs[]) {
    if (Array.isArray(value)) {
        return value.map(item => item.format('YYYY-MM-DD'));
    }

    return value.format('YYYY-MM-DD');
}

export function stringToDayjs_yyyy_MM_dd(value: string | string[]) {
    if (Array.isArray(value)) {
        return value.map(item => dayjs(item, 'YYYY-MM-DD'));
    }

    return dayjs(value, 'YYYY-MM-DD');
}

export function sourceFormatToTargetFormatToString(date: string, sourceRegex:string, sourceFormat:string, destinationFormat:string) {
    if (date && sourceFormat && destinationFormat && sourceRegex) {
        const sourceMatcher:RegExp = new RegExp(sourceRegex);
        if (sourceMatcher.test(date)) {
            return dayjs(date, sourceFormat).format(destinationFormat)
        }
    }

    return date;
}

export function utcDateToFormatTo_yyyy_MM_dd_string(date: string,otherFormat?:string) {
    if (date) {
        const _yyyy_MM_dd_regex = /[\d]{4}[\-][\d]{2}[\-][\d]{2}T.+/;
        if (_yyyy_MM_dd_regex.test(date)) {
            const defaultFormat = date.substring(0, 10);
            if (otherFormat) {
                return dayjs(defaultFormat,'YYYY-MM-DD').format(otherFormat)
            }

            return defaultFormat;
        }
        const _yyyy_dot_MM_dot_dd_regex = /^[\d]{2}[.][\d]{2}[.][\d]{4}$/;
        if (_yyyy_dot_MM_dot_dd_regex.test(date) && otherFormat) {
            return dayjs(date,'DD.MM.YYYY').format(otherFormat);
        }
    }

    return date;
}

export function utcDateToFormatTo_HHmm_string(date: string) {
    const regex = /[\d]{4}[\-][\d]{2}[\-][\d]{2}T.+/;
    if (date && regex.test(date)) {
        const timeIndex = date.indexOf('T');
        return date.substring(timeIndex+1,timeIndex+6);
    }

    return date;
}



export function timestampComparator(): (a: any, b: any) => number {

    function comparator(a:any,b:any) {
        const aDate = utcDateToFormatTo_HHmm_string(a);
        const bDate = utcDateToFormatTo_HHmm_string(b);
        return aDate && bDate && typeof aDate === "string" && typeof bDate === "string" ? aDate.localeCompare(bDate):0;
    }

    return comparator;
}

export function numericComparator(): (a: any, b: any) => number {

    function comparator(a:number|string,b:number|string) {
        if (!a) {
            return -1;
        }
        if (!b) {
            return 1;
        }

        // @ts-ignore
        return typeof a === "string" && typeof b === "string"? parseFloat(a)-parseFloat(b):a-b;
    }

    return comparator;
}


export function ddDotMMDotYYYYComparator(): (a: any, b: any) => number {
    const ddMMYYYRegex = /^[\d]{2}[.][\d]{2}[.][\d]{4}$/
    function convertToYYYYMMdd(date:any) {
        if (typeof date === "string") {
            if (ddMMYYYRegex.test(date)) {
                const dateComponents = date.split('.');
                return `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`
            }
        }

        return date;
    }

    function comparator(a:any,b:any) {
        const aDate = convertToYYYYMMdd(a);
        const bDate = convertToYYYYMMdd(b);
        if (!aDate) {
            return -1;
        }
        if (!bDate) {
            return 1;
        }

        return typeof aDate === "string" && typeof bDate === "string" ? aDate.localeCompare(bDate):0;
    }

    return comparator;
}

export function stringToNumericComparator(): (a:any,b:any)=>number {
    return (a:any,b:any)=> a && b && typeof a === "string" && typeof b === "string" ? parseFloat(a)-parseFloat(b):0;
}

export function numberWithFixedDecimals(theNumber: string | number, noDecimals:number = 2) {
    if (typeof theNumber === "number") {
        if (theNumber === 0) {
            return theNumber.toFixed(noDecimals);
        }
        return theNumber.toFixed(noDecimals);
    } else if (typeof theNumber === "string") {
        return parseFloat(theNumber).toFixed(noDecimals);
    }

    return null;
}

export function ddMMyyyTo_yyyy_MM_dd_string(date: string) {
    const regex = /[\d]{2}[\.][\d]{2}[\.][\d]{4}/;
    if (date && regex.test(date)) {
        return `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`;
    }

    return date;
}

export function stringToDayjs(text: string) {
    return dayjs(text);
}

export interface InfoPortalReport {
    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element;

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element;

    hasExpertSearch(): boolean;

    performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[], intl?: IntlShape): Promise<InfoPortalGridService>;

    presearch?(form: FormInstance<any>, intl: IntlShape): { result: 'continue' | 'abort-search', description?: string,title?:string };

    getEntitlement(): string;

    getReportName(): string;

    getColumnsDefinition(): InfoPortalAgGridColumnDef;
}

export class BaseInfoPortalReport implements InfoPortalReport {

    private infoPortalReport: InfoPortalReport;

    constructor(wrappedInstance: InfoPortalReport) {
        this.infoPortalReport = wrappedInstance;
    }

    cacheObjectValueToFormFieldValueMapper(): Map<string, (input: any) => any> {
        const map = new Map<string, (input: any) => any>();
        map.set('keyDate', stringToDayjs_yyyy_MM_dd);

        map.set('documentDate', stringToDayjs_yyyy_MM_dd);

        map.set('deliveryNoteDate', stringToDayjs_yyyy_MM_dd);

        map.set('purchaseOrderDate', stringToDayjs_yyyy_MM_dd);

        map.set('paymentDate', stringToDayjs_yyyy_MM_dd);

        map.set('postingDeliveryDate', stringToDayjs_yyyy_MM_dd);
        map.set('changedOnDate', stringToDayjs_yyyy_MM_dd);
        map.set('distributionDate', stringToDayjs_yyyy_MM_dd);
        map.set('createdDate', stringToDayjs_yyyy_MM_dd);
        map.set('rejectionDate', stringToDayjs_yyyy_MM_dd);
        map.set('executionDate', stringToDayjs_yyyy_MM_dd);

        return map;
    }

    searchResetValues(params?: { currentUser: any; intl: IntlShape; distinctEntitlements: string[] }): any {
        return {
            partnerInvoiceOperator: 'CP',
            paymentAdviceNumberOperator: 'CP',
            bmwInvoiceNumberOperator: 'CP',
            purchaseOrderNumberOperator: 'CP',
            paymentAmountOperator: 'EQ',
            spabOrderNumberOperator: 'CP',
            purchaseOrderNumberOperators: 'CP',
            deliveryNoteNumberOperators: 'CP',
            grossAmountOperator: 'CP'
        }
    }

    formFieldValueToCacheObjectValueMapper(): Map<string, (input: any) => any> {
        const map = new Map<string, (input: any) => any>();
        map.set('keyDate', dayjsToString_yyyy_MM_dd);
        map.set('grScDate', dayjsToString_yyyy_MM_dd);
        map.set('documentDate', dayjsToString_yyyy_MM_dd);
        map.set('deliveryNoteDate', dayjsToString_yyyy_MM_dd);
        map.set('purchaseOrderDate', dayjsToString_yyyy_MM_dd);
        map.set('paymentDate', dayjsToString_yyyy_MM_dd);
        map.set('postingDeliveryDate', dayjsToString_yyyy_MM_dd);
        map.set('changedOnDate', dayjsToString_yyyy_MM_dd);
        map.set('distributionDate', dayjsToString_yyyy_MM_dd);
        map.set('createdDate', dayjsToString_yyyy_MM_dd);
        map.set('rejectionDate', dayjsToString_yyyy_MM_dd);
        map.set('executionDate', dayjsToString_yyyy_MM_dd);

        return map;
    }

    getEntitlement(): string {
        return this.infoPortalReport.getEntitlement();
    }

    getReportName(): string {
        return this.infoPortalReport.getReportName();
    }

    hasExpertSearch(): boolean {
        return this.infoPortalReport.hasExpertSearch();
    }

    performSearch(form: FormInstance, currentUser: any, distinctEntitlements: string[],intl:IntlShape): Promise<InfoPortalGridService> {
        return this.infoPortalReport.performSearch(form, currentUser, distinctEntitlements,intl);
    }

    presearch(form: FormInstance<any>, intl: IntlShape): { result: "continue" | "abort-search"; description?: string, title?:string } {
        if (this.infoPortalReport.presearch) {
            return this.infoPortalReport.presearch(form, intl);
        }

        return {result: 'continue'};
    }

    renderExpertSearch(arg: InfoPortalSearchProperties): JSX.Element {
        return this.infoPortalReport.renderExpertSearch(arg);
    }

    renderSimpleSearch(arg: InfoPortalSearchProperties): JSX.Element {
        return this.infoPortalReport.renderSimpleSearch(arg);
    }

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this.infoPortalReport.getColumnsDefinition();
    }
}

export function nonePresent(form: FormInstance<any>, fieldNames: string[]) {
    return fieldNames.every(fieldName => !form.getFieldValue(fieldName));
}

export const downloadDocumentByBlobIdAndApplicationType = (blobId: number, application: string) => {
    return new Promise<BlobResponse>((resolve, reject) => {
        client.query<retrieveDocumentByBlobIdAndApplicationType>({
            query: DOWNLOAD_DOC_BY_BLOB_ID_AND_APPLICATION_TYPE,
            variables: {blobId, application},
            fetchPolicy: "network-only"
        })
            .then((documents) => {
                const response = documents.data.retrieveDocumentByBlobIdAndApplicationType;
                resolve(
                    response
                );
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
}

export const downloadRejectionLetterDocumentUsingUrl = (companyCode:string, partnerNumber:string, bmwInvoiceNumber:string, partnerInvoiceNumber:string, url:string) => {
    return new Promise<BlobResponse>((resolve, reject) => {
        client.query<XSuiteDownload, XSuiteDownloadVariables>({
            query: DOWNLOAD_REJECTION_LETTER_BY_URL,
            variables: {fetchBlobRequest: {fetchType:'XSUITE_URL', url, partnerNumber, bmwInvoiceNumber,partnerInvoiceNumber,companyCode}},
            fetchPolicy: "network-only"
        })
            .then((documents) => {
                const response = documents.data.retrieveRejectionLetterByURL;
                resolve(
                    {__typename:"SurveyDocumentResponse",
                        success:"success",
                        value:{
                            __typename:"SurveyDocumentValue",
                                encodedFileData:response.encodedFileData,
                                fileName:response.fileName,
                                mimeType:response.fileType
                        }}
                );
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
}



