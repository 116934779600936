import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoredDocumentRecord, IStoreLineItem, MODE } from './IStoredDocumentRecord';
import { LineItemRecord } from './line-items/LineItems';
import { LineItemsInput } from '../../../__generated__/globalTypes';
import { AttachmentModel } from './attachments/Attachment';
import { GET_invoice_agreement_history_V2_getAgreementHistoryV2 } from './__generated__/GET_invoice_agreement_history_V2';
import { getPartnerVATInfoByPartnerNo_getPartnerVATInfoByPartnerNo_vatCodes } from './__generated__/getPartnerVATInfoByPartnerNo';

export const DESTROY_SESSION = 'DESTROY_SESSION';
interface DocumentState {
    document: IStoredDocumentRecord;
}

const MAX_FILES = 4;

const initialState: DocumentState = {
    document: {
        documentType: undefined,
        supplier: undefined,
        bmwGroupPaymentRequest: false,
        bmwIsRetailOutlet: false,
        companyCode: undefined,
        partnerId: undefined,
        bmwBusinessAreaId: undefined,
        bmwBusinessUnitId: undefined,
        companyPartnerId: undefined,
        lineItems: [],
        documentId: undefined,
        collapsedState: undefined,
        orderNumber: undefined,
        shipFromCountryCode: undefined,
        shipToCountryCode: undefined,
        shipFromRegionCode: undefined,
        shipToRegionCode: undefined,
        companyVatCodeId: undefined,
        supplierCountryCode: undefined,
        supplierRegionCode: undefined,
        documentMode: undefined,
        fiscalCountryCode: undefined,
        fiscalRegionCode: undefined,
        partnerVatCodeId: undefined,
        legalFormTypeId: undefined,
        fiscalRepresentativeChecked: false,
        inLiquidationChecked: false,
        agreementHistory: undefined,
        attachments: [],
        attachmentUploadsLeft: MAX_FILES,
        companyCountryCode: undefined,
        isTemplateMode: false,
        copyTemplate: false,
        templateCount: 0,
        deliveryDateInError: false,
        partnerNo: undefined,
        bmwCompanyCity: undefined,
        bmwCompanyCountryCode: undefined,
        bmwCompanyPostalCode: undefined,
        bmwCompanyStreet1: undefined,
        bmwCompanyStreet2: undefined,
        companyVatCode: undefined,
        companyVatCountryCode: undefined,
        partnerAddressCity: undefined,
        partnerAddressPostalCode: undefined,
        partnerAddressRegionCode: undefined,
        partnerAddressStreet1: undefined,
        partnerAddressStreet2: undefined,
        partnerName: undefined,
        partnerVatCode: undefined,
        partnerVatCountryCode: undefined,
        partnerAddressCountryCode: undefined,
        systemCode: undefined,
        partnerTaxId: undefined,
        bmwCompanyTaxId: undefined,
        companyName: undefined,
        loading: 0,
        partnerVatSelectOptions: [],
        isOrderNumberNotRequired: false
    },
};

function mapIStoreLineItemToLineItemsInput(index: number, iStoreLineItem: IStoreLineItem) {
    const lineItemRecord = Object.assign(new LineItemRecord(), iStoreLineItem);

    const record: LineItemsInput = {
        orderNumber: lineItemRecord.orderNumber,
        deliveryNo: lineItemRecord.deliveryOrServiceNumber,
        deliveryDate: lineItemRecord.deliveryDate,
        ecDocumentDetailId: null,
        ecDocumentId: null,
        bmwPartnerNumber: lineItemRecord.bmwPartNumber,
        grossAmount: lineItemRecord.grossAmount(),
        itemDescription: lineItemRecord.itemDescription,
        netAmount: lineItemRecord.netAmount(),
        quantity: lineItemRecord.quantity,
        taxAmount: lineItemRecord.taxAmount(),
        taxRate: lineItemRecord.taxRate,
        unitPrice: lineItemRecord.unitPrice,
        unitQuantity: lineItemRecord.unitPriceQuantity,
        unitOfMeasureId: lineItemRecord.unitOfMeasureId(),
        unitOfMeasureFreeText: lineItemRecord.unitOfMeasureFreeText,
        taxLawReferenceId: lineItemRecord.taxLawReferenceId(),
        exemptionReason: lineItemRecord.exceptionReason,
        toolSupplyingCountry: lineItemRecord.supplyingCountryId(),
        toolLocation: lineItemRecord.toolLocation,
        taxExemptionTypeId: lineItemRecord.exemptionTypeid(),
        srmReferenceNumber: lineItemRecord.srmRefNumber,
        inventoryNumber: lineItemRecord.inventoryNumber,
        lineNo: index,
    };

    return record;
}

function destroyStateSynchronous(state) {
    return new Promise(resolve => {
        state.document = initialState.document;
    });
}



const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        destroyState(state, action: PayloadAction<string>) {
            if (action.payload === DESTROY_SESSION) {
                destroyStateSynchronous(state);
                console.log(`Template mode after state destroy : ${state.document.isTemplateMode}`);
            }
        },
        updateDocumentType(state, action: PayloadAction<number>) {
            const selectedDocType = action.payload;
            state.document.documentType = selectedDocType;
        },
        updateSupplier(state, action: PayloadAction<number>) {
            const selectedSupplier = action.payload;
            state.document.partnerId = selectedSupplier;
        },
        updatePartnerNo(state, action: PayloadAction<string>) {
            state.document.partnerNo = action.payload;
        },
        isBmwGroupCompany(state, action: PayloadAction<boolean>) {
            const selectBmwGroupCompany = action.payload;
            state.document.bmwGroupPaymentRequest = selectBmwGroupCompany;
        },
        updateCompanyCode(state, action: PayloadAction<string>) {
            const selectCompanyCode = action.payload;
            state.document.companyCode = selectCompanyCode;
        },
        updateCompanyPartnerId(state, action: PayloadAction<number>) {
            const selectCompanyPartnerId = action.payload;
            state.document.companyPartnerId = selectCompanyPartnerId;
        },
        updateBMWRetailOutlet(state, action: PayloadAction<boolean>) {
            const selectBMWRetailOutlet = action.payload;
            state.document.bmwIsRetailOutlet = selectBMWRetailOutlet;
        },
        updateBMWBusinessUnitId(state, action: PayloadAction<number>) {
            const bmwBusinessUnitId = action.payload;
            state.document.bmwBusinessUnitId = bmwBusinessUnitId;
        },
        updateBMWBusinessAreaId(state, action: PayloadAction<number>) {
            const bmwBusinessAreaId = action.payload;
            state.document.bmwBusinessAreaId = bmwBusinessAreaId;
        },

        updateLineItems(state, action: PayloadAction<IStoreLineItem[]>) {
            const payload = action.payload;
            state.document.lineItems = payload.map((item, index) => mapIStoreLineItemToLineItemsInput(index + 1, item));
        },
        addLineItem(state, action: PayloadAction<IStoreLineItem>) {
            const payload = action.payload;
            if (!state.document.lineItems) {
                state.document.lineItems = [];
            }

            const index = state.document.lineItems.length;
            state.document.lineItems.push(mapIStoreLineItemToLineItemsInput(index + 1, payload));
        },
        removeLineItem(state, action: PayloadAction<number>) {
            const index = action.payload;
            if (!state.document.lineItems || state.document.lineItems.length < index) {
                state.document.lineItems = [];
            }

            const leftArray = state.document.lineItems.slice(0, index);
            const rightArray = state.document.lineItems.slice(index + 1);
            state.document.lineItems = [...leftArray, ...rightArray];
        },
        updateLineItem(state, action: PayloadAction<{ index: number; istoreLineItem: IStoreLineItem }>) {
            const payload = action.payload;

            if (!state.document.lineItems || state.document.lineItems.length < payload.index) {
                return;
            }

            state.document.lineItems[payload.index] = mapIStoreLineItemToLineItemsInput(
                payload.index + 1,
                payload.istoreLineItem
            );
        },

        updateDocumentId(state, action: PayloadAction<number>) {
            const documentId = action.payload;
            state.document.documentId = documentId;
        },
        updateOrderNumber(state, action: PayloadAction<string>) {
            const orderNumber = action.payload;
            state.document.orderNumber = orderNumber;
        },
        updateCollapsedState(state, action: PayloadAction<string | string[]>) {
            const collapsedState = action.payload;
            state.document.collapsedState = collapsedState;
        },
        updateShippingFromCountryCode(state, action: PayloadAction<string>) {
            const countryCode = action.payload;
            state.document.shipFromCountryCode = countryCode;
        },
        updateShippingFromRegionCode(state, action: PayloadAction<string>) {
            const regionCode = action.payload;
            state.document.shipFromRegionCode = regionCode;
        },
        updateShippingToCountryCode(state, action: PayloadAction<string>) {
            const countryCode = action.payload;
            state.document.shipToCountryCode = countryCode;
        },
        updateShippingToRegionCode(state, action: PayloadAction<string>) {
            const regionCode = action.payload;
            state.document.shipToRegionCode = regionCode;
        },
        updateCompanyVatCodeId(state, action: PayloadAction<number>) {
            const companyVatCodeId = action.payload;
            state.document.companyVatCodeId = companyVatCodeId;
        },
        updateSupplierCountryCode(state, action: PayloadAction<string>) {
            const countryCode = action.payload;
            state.document.supplierCountryCode = countryCode;
        },
        updateSupplierRegionCode(state, action: PayloadAction<string>) {
            const regionCode = action.payload;
            state.document.supplierRegionCode = regionCode;
        },
        setDocumentMode(state, action: PayloadAction<MODE>) {
            state.document.documentMode = action.payload;
        },
        updateFiscalCountryCode(state, action: PayloadAction<string>) {
            state.document.fiscalCountryCode = action.payload;
        },
        updateFiscalRegionCode(state, action: PayloadAction<string>) {
            state.document.fiscalRegionCode = action.payload;
        },
        updatePartnerVatCodeId(state, action: PayloadAction<number>) {
            state.document.partnerVatCodeId = action.payload;
        },
        updateLegalFormTypeId(state, action: PayloadAction<number>) {
            state.document.legalFormTypeId = action.payload;
        },
        hasFiscalRepresentativeChecked(state, action: PayloadAction<boolean>) {
            state.document.fiscalRepresentativeChecked = action.payload;
        },
        inLiquidationChecked(state, action: PayloadAction<boolean>) {
            state.document.inLiquidationChecked = action.payload;
        },
        updateAgreementHistory(state, action: PayloadAction<GET_invoice_agreement_history_V2_getAgreementHistoryV2>) {
            state.document.agreementHistory = {
                agreementHistoryId: action.payload?.agreementHistoryId,
                ...action.payload,
            };
        },
        addAttachment(state, action: PayloadAction<AttachmentModel>) {
            state.document.attachments.push(action.payload);
        },
        setAttachments(state, action: PayloadAction<AttachmentModel[]>) {
            state.document.attachments = action.payload;
        },
        clearStateAttachments(state, action: PayloadAction) {
            state.document.attachments = [];
        },
        updateAttAchmentUploadsLeft(state, action: PayloadAction<number>) {
            state.document.attachmentUploadsLeft = action.payload;
        },
        resetAttAchmentUploadsLeft(state, action: PayloadAction) {
            state.document.attachmentUploadsLeft = MAX_FILES;
        },
        updateCompanyCountryCode(state, action: PayloadAction<string>) {
            state.document.companyCountryCode = action.payload;
        },
        setTemplateMode(state, action: PayloadAction<boolean>) {
            console.log(`Set template mode : ${action.payload}`);
            state.document.isTemplateMode = action.payload;
        },
        setCopyTemplate(state, action: PayloadAction<boolean>) {
            state.document.copyTemplate = action.payload;
        },
        setTemplateCount(state, action: PayloadAction<number>) {
          state.document.templateCount = action.payload;
        },
        setDeliveryDateInError(state, action: PayloadAction<boolean>) {
            state.document.deliveryDateInError = action.payload;
        },
        setPartnerNo(state, action: PayloadAction<string>) {
            state.document.partnerNo = action.payload;
        },
        setBmwCompanyCity(state, action: PayloadAction<string>) {
            state.document.bmwCompanyCity = action.payload;
        },
        setCompanyCountryCode(state, action: PayloadAction<string>) {
            state.document.bmwCompanyCountryCode = action.payload;
        },
        setBmwCompanyPostalCode(state, action: PayloadAction<string>) {
            state.document.bmwCompanyPostalCode = action.payload;
        },
        setBmwCompanyStreet1(state, action: PayloadAction<string>) {
            state.document.bmwCompanyStreet1 = action.payload;
        },
        setBmwCompanyStreet2(state, action: PayloadAction<string>) {
            state.document.bmwCompanyStreet2 = action.payload;
        },
        setPartnerAddressCity(state, action: PayloadAction<string>) {
            state.document.partnerAddressCity = action.payload;
        },
        setPartnerAddressPostalCode(state, action: PayloadAction<string>) {
            state.document.partnerAddressPostalCode= action.payload;
        },
        setPartnerAddressRegionCode(state, action: PayloadAction<string>) {
            state.document.partnerAddressRegionCode = action.payload;
        },
        setPartnerAddressStreet1(state, action: PayloadAction<string>) {
            state.document.partnerAddressStreet1 = action.payload;
        },
        setPartnerAddressStreet2(state, action: PayloadAction<string>) {
            state.document.partnerAddressStreet2 = action.payload;
        },
        setPartnerAddressCountryCode(state, action: PayloadAction<string>) {
            state.document.partnerAddressCountryCode = action.payload;
        },
        setPartnerVatCode(state, action: PayloadAction<string>) {
            state.document.partnerVatCode = action.payload;
        },
        setPartnerVatCountryCode(state, action: PayloadAction<string>) {
            state.document.partnerVatCountryCode = action.payload;
        },
        setCompanyVatCode(state, action: PayloadAction<string>) {
            state.document.companyVatCode = action.payload;
        },
        setCompanyVatCountryCode(state, action: PayloadAction<string>) {
            state.document.companyVatCountryCode = action.payload;
        },
        setPartnerName(state, action: PayloadAction<string>) {
            state.document.partnerName = action.payload;
        },
        setSystemCode(state, action: PayloadAction<string>) {
            state.document.systemCode = action.payload;
        },
        setPartnerTaxId(state, action: PayloadAction<string>) {
            console.log(`set partnerTaxId: ${action.payload}`)
            state.document.partnerTaxId = action.payload;
        },
        setBmwCompanyTaxId(state, action: PayloadAction<string>) {
            state.document.bmwCompanyTaxId = action.payload;
        },
        setCompanyName(state, action: PayloadAction<string>) {
            state.document.companyName= action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            if (action.payload)
            {
                state.document.loading++;
            }
            else{
                state.document.loading--;
            }
            
        },
        setPartnerVatSelectOptions(state, action: PayloadAction<getPartnerVATInfoByPartnerNo_getPartnerVATInfoByPartnerNo_vatCodes[]>) {
            state.document.partnerVatSelectOptions= action.payload;
        },
        setIsOrderNumberNotRequired(state, action: PayloadAction<boolean>) {
            const isOrderNumberNotRequired = action.payload;
            state.document.isOrderNumberNotRequired = isOrderNumberNotRequired;
        },
    },
});

export const {
    updateDocumentType,
    updateSupplier,
    updatePartnerNo,
    isBmwGroupCompany,
    updateCompanyCode,
    updateCompanyPartnerId,
    updateBMWRetailOutlet,
    updateLineItems,
    addLineItem,
    removeLineItem,
    updateLineItem,
    updateBMWBusinessUnitId,
    updateBMWBusinessAreaId,
    updateDocumentId,
    updateOrderNumber,
    updateCollapsedState,
    updateShippingFromCountryCode,
    updateShippingToCountryCode,
    updateCompanyVatCodeId,
    setDocumentMode,
    updateSupplierCountryCode,
    updateFiscalCountryCode,
    updatePartnerVatCodeId,
    updateLegalFormTypeId,
    hasFiscalRepresentativeChecked,
    inLiquidationChecked,
    updateShippingFromRegionCode,
    updateFiscalRegionCode,
    updateSupplierRegionCode,
    updateShippingToRegionCode,
    destroyState,
    updateAgreementHistory,
    addAttachment,
    setAttachments,
    clearStateAttachments,
    updateAttAchmentUploadsLeft,
    resetAttAchmentUploadsLeft,
    updateCompanyCountryCode,
    setTemplateMode,
    setCopyTemplate,
    setTemplateCount,
    setDeliveryDateInError,
    setPartnerNo,
    setBmwCompanyCity,
    setBmwCompanyPostalCode,
    setBmwCompanyStreet1,
    setBmwCompanyStreet2,
    setCompanyCountryCode,
    setCompanyVatCode,
    setCompanyVatCountryCode,
    setPartnerAddressCity,
    setPartnerAddressPostalCode,
    setPartnerAddressRegionCode,
    setPartnerAddressStreet1,
    setPartnerAddressStreet2,
    setPartnerAddressCountryCode,
    setPartnerName,
    setPartnerVatCode,
    setPartnerVatCountryCode,
    setSystemCode,
    setBmwCompanyTaxId,
    setPartnerTaxId,
    setCompanyName,
    setLoading,
    setPartnerVatSelectOptions,
    setIsOrderNumberNotRequired
} = documentSlice.actions;
export default documentSlice.reducer;
