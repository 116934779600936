import { gql } from "@apollo/client";

export const USER_ENTITLEMENT_BY_USERID = gql`
    query getUserEntitlementResultByUserId($userId: String!, $authentication: String!, $authRedirectURL: String!){
        getUserEntitlementResultByUserId(userId: $userId, authentication: $authentication, authRedirectURL: $authRedirectURL){
            partners {
                partnerNo
                partnerName
                partnerOu
                partnerId
                partnerInDB
                entitlements
                companies { companyCode companyName companyId}
                category
            }
            distinctEntitlements
        }
    }
`;
export const ECAP_USER_ENTITLEMENT_BY_USERID = gql`
    query getEcapUserEntitlementResultByUserId($userId: String!, $authentication: String!){
        getEcapUserEntitlementResultByUserId(userId: $userId, authentication: $authentication){
            partners {
                partnerNo
                partnerName
                partnerOu
                partnerId
                partnerInDB
                entitlements
                companies { companyCode companyName companyId}
                category
            }
            distinctEntitlements
        }
    }
`;

export const EDP_USER_PARTNERS_AND_COMPANIES_BY_USERID_AND_ENTITLEMENT = gql`
    query EDPPartnerAndCompany($userId: String!, $entitlement: String!,$pageNumber: Float!, $pageSize: Float!){
        getUserEntitlementResultByUserIdAndEntitlement(userId: $userId, entitlement: $entitlement,pageNumber:$pageNumber, pageSize:$pageSize){
            partners {
                partnerNo
                partnerName
                partnerOu
                partnerId
                partnerInDB
                entitlements
                companies { companyCode companyName companyId}
                category
            }
            distinctEntitlements
        }
    }
`;

export const USER_ENTITLEMENT_BY_USERID_WITH_FILTER = gql`
    query getUserEntitlementResultByUserIdAndFilter($userId: String!, $entitlement: String!, $partnerName: String!, $authentication: String!){
        getUserEntitlementResultByUserIdAndFilter(userId: $userId,entitlement: $entitlement, partnerName: $partnerName,  authentication: $authentication){
            partners {
                partnerNo
                partnerName
                partnerOu
                partnerInDB
                partnerId
                entitlements
                companies { companyCode companyName  companyId}
                category
            }
            distinctEntitlements
        }
    }
`;


export const DOCUMENT_USER_ENTITLEMENT_RESULT_BY_USER_ID= gql`
    query getDocumentUserEntitlementResultByUserId($userId: String!, $entitlement: String!){
        getDocumentUserEntitlementResultByUserId(userId: $userId,entitlement: $entitlement){
            partners {
                partnerNo
                partnerName
                partnerOu
                partnerInDB
                partnerId
                entitlements
                companies { companyCode companyName  companyId}
                category
            }
            distinctEntitlements
        }
    }
`;

export const USER_ENTITLEMENT_FOR_SELF_WITH_FILTER = gql`
    query getSelfEntitlementResultByFilter($entitlement: String!, $partnerName: String!,$pageNumber: Float!, $pageSize: Float!){

        getSelfEntitlementResultByFilter(entitlement: $entitlement, partnerName: $partnerName, pageNumber: $pageNumber, pageSize: $pageSize){
            partners {
                partnerNo
                partnerName
                partnerOu
                partnerId
                partnerInDB
                entitlements
                companies { companyCode companyName companyId}
                category
            }
            distinctEntitlements
        }
    }
`;

export const USER_ENTITLEMENT_FOR_SELF_BY_COMPANY_CODE_AND_FILTER = gql`
    query getPartnersByCompanyCodesAndFilter($entitlement: String!, $partnerFilter: String!,$companyCodes: [String!]!,$pageNumber: Float!, $pageSize: Float!){

        getPartnersByCompanyCodesAndFilter(entitlement: $entitlement, partnerFilter: $partnerFilter,companyCodes: $companyCodes, pageNumber: $pageNumber, pageSize: $pageSize){
            partnerNo
            partnerName
            partnerId
            category
        }
    }
`;

export const USER_COMPANIES_BY_ENTITLEMENT = gql`
    query getCompaniesByEntitlement($entitlement: String!){
        allCompaniesForEntitlement(entitlement: $entitlement) {
            companyId,
            companyName,
            companyCode
        }
    }
`;