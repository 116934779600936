import React from "react";
import { PortalFormItemSelect } from "./FormUtils";
import { useQuery } from "@apollo/client";
import { selectOption, selectOptionVariables } from "../pages/users/__generated__/selectOption";
import { SELECT_OPTION_QUERY } from "../pages/users/queries";
import { IAppComponentProps } from "./index";

export const CompanyCode: React.FunctionComponent<IAppComponentProps> = (props) => {
  const { data } = useQuery<selectOption, selectOptionVariables>(SELECT_OPTION_QUERY, {
    fetchPolicy: "cache-first", skip:  !props.currentUser,
    variables: { select: "COMPANY", username: props.currentUser?.username }
  });

  return (
    <>
      <PortalFormItemSelect type={"single"}
                            label={props.intl.formatMessage({id: "management-companycode-label",
                            defaultMessage: "Company Code"
                            })}
                            name={"companyCode"}
                            placeHolder={props.intl.formatMessage({id: "management-companycode-placeholder",
                            defaultMessage: "Company Code"
                            })}
                            selectOption={data} />
    </>
  );
};
