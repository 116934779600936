export class HelpDocument {
    private documentLinks:string[];//TODO: will be deprecated when blob service is done...
    document:string;
    languages:string[];
    selectedLanguage:string;


    constructor(document: string, documentLinks: string[],languages: string[],selectedLanguage:string) {
        this.documentLinks = documentLinks;
        this.document = document;
        this.languages = languages;
        this.selectedLanguage = selectedLanguage;
        if (this.languages.length && !this.languages.includes(selectedLanguage)) {
           this.selectedLanguage = languages[0];
        }
    }

    getSelectedDocument() {
        for (let i = 0; i < this.languages.length; i++) {
            if (this.selectedLanguage === this.languages[i]) {
                return this.documentLinks[i];
            }
        }

        return null;
    }
}