import {configureStore} from "@reduxjs/toolkit";
import documentReducer from "../pages/e-cap/IStoredDocumentRecordSlice";
import partnerRecordReducer from "../pages/e-cap/IStoredPartnerRecordSlice";
import rulesReducer from "../pages/e-cap/IStoredRulesSlice";
import draftDocumentLoadingReducer from "../pages/e-cap/IStoredDraftDocumentLoadingSlice";
import templateDocumentLoadingReducer from "../pages/e-cap/IStoredTemplateDocumentLoadingSlice";
import surveyReducer from "../pages/survey/IStoredSurveySlice"
import globalStateReducer from './IEDPGlobalSlice';
import missingInvoicesReducer from "../pages/e-cap/IStoredMissingInvoicesSlice";
import documentOverviewReducer from "../pages/e-cap/IStoredDocumentOverviewSlice";

export const store = configureStore({
  reducer: {
    document: documentReducer,
    documentOverview: documentOverviewReducer,
    missingInvoices: missingInvoicesReducer,
    partnerRecord: partnerRecordReducer,
    rules: rulesReducer,
    draftDocumentLoading: draftDocumentLoadingReducer,
    templateDocumentLoading: templateDocumentLoadingReducer,
    survey: surveyReducer,
    globalStateReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['rules.ruleMap'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>