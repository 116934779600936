import {saveAs} from "save-as";
import {renderInvoiceCellValue} from "../../components/decorators/decorator";
import RowOption from "./DocumentOverviewGridIconRender";
import {IntlShape} from "react-intl/src/types";
import {PortalUser} from "../../components";
import {
  convertFromSpecifiedDateFormatToUserDefined,
  convertToUserSpecifiedNumericFormat
} from "../users/UserFormatPreferenceServices";


export const MYLISTDOCS_TABLE_HEADERS = (intl: IntlShape,user:PortalUser) => [
   {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-document-number",
      defaultMessage: "Document Number"
    }),
    filter: true,
    field: "documentNumber",
    colId: "documentNumber",
    tooltipField:'documentNumber',
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-document-number",
      defaultMessage: "Document Number"
    }),
    width: 210,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    headerCheckboxSelection: true,
    type: 'leftAligned',
    autoHeight: true,
    cellRenderer: RowOption
  },

  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-attachment-available",
      defaultMessage: "Attachment Available"
    }),
    field: 'attachment',
    colId: 'attachment',
    tooltipField:'attachment',
      // cellRenderer: function AttachmentYesOrNO(params) {
      //   return params.data.blobId ? "Yes" : "No";
      // },
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-attachment-available",
      defaultMessage: "Attachment"
    }),
    filter: true,
    width: 120

  },

  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-document-type",
      defaultMessage: "Document Type"
    }),
    filter: true,
    field: 'documentType' ,
    colId: 'documentType',
    tooltipField:'documentType',
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-document-type",
      defaultMessage: "Document Type"
    }), 
    width: 150
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-processCode",
      defaultMessage: "Process Code"
    }),
    field: 'processCode',
    colId: 'processCode',
    tooltipField:'processCode',
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-processCode",
      defaultMessage: "Process Code"
    }),
    filter: true,
    width: 120
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-Format",
      defaultMessage: "Format"
    }),
    field: 'format',
    colId: 'format',
    tooltipField:'format',
    headerTooltip:intl.formatMessage({
      id: "document-overview-grid-header-Format",
      defaultMessage: "Format"
    }),
    filter: true,
    width: 100
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-DocDate",
      defaultMessage: "Document Date"
    }),
    colId: 'documentDate',
    field: 'documentDate',
    tooltipField:'documentDate',
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-DocDate",
      defaultMessage: "Document Date"
    }),
    valueGetter: (params) => {
      if (params.data.documentDate) {
        return convertFromSpecifiedDateFormatToUserDefined(user, params.data.documentDate, "YYYY-MM-DD");
      }
      return "";
    },
    filter: true,
    width: 120
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-grossAmount",
      defaultMessage: "Gross Amount"
    }),
    colId: "grossAmount",
    field: "grossAmount",
    tooltipField: "grossAmount",
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-DocDate",
      defaultMessage: "Gross Amount"

    }),
    valueFormatter: params => convertToUserSpecifiedNumericFormat(user,params.data.grossAmount),
    filter: true,
    type: "rightAligned",
    width: 120,
    cellClass: "currencyFormat"
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-taxAmount",
      defaultMessage: "Tax Amount"
    }),
    colId: "taxAmount",
    field: "taxAmount",
    tooltipField: "taxAmount",
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-taxAmount",
      defaultMessage: "Tax Amount"
    }),
    valueFormatter: params => convertToUserSpecifiedNumericFormat(user,params.data.taxAmount),
    filter: true,
    type: "rightAligned",
    width: 120,
    cellClass: "currencyFormat"
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-currency",
      defaultMessage: "Currency"
    }),
    field: 'currency',
    colId: 'currency',
    tooltipField: 'currency',
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-currency",
      defaultMessage: "Currency"
    }),
    filter: true,
    width: 100
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-BMWCompany",
      defaultMessage: "BMW Company"
    }),
    field: 'bmwCompany',
    colId: 'bmwCompany',
    tooltipField:'BmwCompany',
    headerTooltip:intl.formatMessage({
      id: "document-overview-grid-header-BMWCompany",
      defaultMessage: "BMW Company"
    }),
    filter: true,
    width: 150
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-country",
      defaultMessage: "Country"
    }),
    field: 'country',
    colId: 'country',
    tooltipField:'country',
    headerTooltip:intl.formatMessage({
      id: "document-overview-grid-header-country",
      defaultMessage: "Country"
    }),
    filter: true,
    width: 90
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-partner-number",
      defaultMessage: "Partner Number"
    }),
    field: 'partnerNumber',
    colId: 'partnerNumber',
    filter: true,
    width: 150,
    tooltipField: 'partnerNumber',
    headerTooltip:intl.formatMessage({
      id: "document-overview-grid-header-partner-number",
      defaultMessage: "Partner Number"
    }),
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-partner-name",
      defaultMessage: "Partner Name"
    }),
    field: 'partnerName',
    colId: 'partnerName',
    filter: true,
    width: 160,
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-partner-name",
      defaultMessage: "Partner Name"
    }),
    cellRendererFramework: (params) => {
      return params?.value !== null ? renderInvoiceCellValue(params?.value) !== null ? renderInvoiceCellValue(params?.value):null:null;
    },
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-Status",
      defaultMessage: "Status"
    }),
    field: 'status',
    colId: 'status',
    tooltipField: 'status',
    headerTooltip:intl.formatMessage({
      id: "document-overview-grid-header-Status",
      defaultMessage: "Status"
    }),
    filter: true,
    width: 100
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-reception-date",
      defaultMessage: "Reception Date"
    }),
    colId: 'receptionDate',
    field: 'receptionDate',
    tooltipField: 'receptionDate',
    headerTooltip:intl.formatMessage({
      id: "document-overview-grid-header-reception-date",
      defaultMessage: "Reception Date"
    }),
    valueGetter: (params) => {
      if (params.data.receptionDate) {
        return convertFromSpecifiedDateFormatToUserDefined(user, params.data.receptionDate, "YYYY-MM-DD");
      }
      return "";
    },
    filter: true,
    width: 150
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-sdi-number",
      defaultMessage: "SDI Number"
    }),
    field: 'sdiNumber',
    colId: 'sdiNumber',
    tooltipField: 'sdiNumber',
    headerTooltip: intl.formatMessage({
      id: "document-overview-grid-header-sdi-number",
      defaultMessage: "SDI Number"
    }),
    filter: true,
    width: 150
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-vin-number",
      defaultMessage: "VIN NUmber"
    }),
    field: 'vinNumber',
    colId: 'vinNumber',
    tooltipField: 'vinNumber',
    headerTooltip:intl.formatMessage({
      id: "document-overview-grid-header-vin-number",
      defaultMessage: "VIN NUmber"
    }),
    filter: true,
    width: 150
  },
  {
    headerName: intl.formatMessage({
      id: "document-overview-grid-header-downloaded",
      defaultMessage: "Downloaded"
    }),
    Resizable: true,
    field: 'downloaded',
    colId: 'downloaded',
    tooltipField: 'downloaded',
    headerTooltip:intl.formatMessage({
      id: "document-overview-grid-header-downloaded",
      defaultMessage: "Downloaded"
    }),
    filter: true,
/*    cellRenderer: (params) => {
      return params.value === true ? "YES":"NO"
    },*/
    // cellRenderer: (params) => {
    //   return params.value === 0 ? "NO":"YES"
    // },
    width: 200
  }
];

export const base64toZipFile = (b64Data, filename) => {
  const sliceSize = 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: "application/zip" });
  saveAs(blob, filename);
};