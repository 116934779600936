import React, { useState} from "react";
import { PortalFormItemSelectEcapCodeUsingEntitlement } from "./FormUtils";
import { useQuery } from "@apollo/client";
import { selectOptionCoCodeEntitlement, selectOptionCoCodeEntitlementVariables } from "../pages/users/__generated__/selectOptionCoCodeEntitlement";
import {SELECT_OPTION_CO_CODE_ENTITLEMENT_QUERY} from "../pages/users/queries";
import { IAppComponentProps } from "./index";
import { getI18n } from "../utils/Utils";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useAppSelector  } from "../main/hooks"

export const BmwCompanyCode: React.FunctionComponent<IAppComponentProps> = (props) => {
  const { data } = useQuery<selectOptionCoCodeEntitlement, selectOptionCoCodeEntitlementVariables>(SELECT_OPTION_CO_CODE_ENTITLEMENT_QUERY, {
    fetchPolicy: "no-cache",
    variables: { uid: props.currentUser?.uid , entitlement: "overview@documents"}
  });

  const intl = useIntl(); 
  const docOverviewFiltersState = useAppSelector((state) => state.documentOverview);
  const location = useLocation();
  const isFromCaptureDocument = location.state && location.state.isFromCaptureDocument;
  
  const [companyCodeSelectedValue, setCompanyCodeSelectedValue] = useState(isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.bmwCompanyId: null);
  return (
    <>
      <PortalFormItemSelectEcapCodeUsingEntitlement type={"single"}
                                    label={getI18n("management-companycode-label-Ecap", "BMW Company", intl)}
                                    name={"bmwCompanyId"}
                                    placeHolder={getI18n("management-companycode-label-Ecap", "BMW Company", intl)}
                                    selectOptionCoCodeEntitlement={data} colSpan={5}
                                    defaultValue={isFromCaptureDocument ? docOverviewFiltersState.documentOverviewFilters.bmwCompanyId :null} 
                                    value={companyCodeSelectedValue}
                                    onChange={(value) => setCompanyCodeSelectedValue(value)} />
    </>
  );
};
