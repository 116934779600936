import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Collapse,
    ConfigProvider,
    Form,
    FormInstance,
    message,
    Modal,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Tooltip,
    Typography,
} from 'antd';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { FormattedMessage, useIntl, WrappedComponentProps } from 'react-intl';
import {
    CaretRightOutlined,
    CloseOutlined,
    CopyOutlined,
    EditOutlined,
    RollbackOutlined,
    RotateRightOutlined,
    SaveOutlined
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getDocumentType } from '../documents/__generated__/getDocumentType';
import { SELECT_PROVIDER_DOCTYPE_QUERY } from '../documents/queries';
import { getDocumentRecordData, updateDocumentStatusData } from './services/DocumentRecordService';
import CompanyComponent from './bmw-company/BMWCompany-component';
import enUS from 'antd/lib/locale/en_US';
import deDe from 'antd/lib/locale/de_DE';
import { getI18n } from '../../utils/Utils';
import SupplierDetails from './supplier/SupplierDetails';
import HeaderDetails from './header-details/HeaderDetails';
import LineItems, { LineItemRecord, LineItemsState } from './line-items/LineItems';
import ShippingInfoDetails from './shipping-info/ShippingInfoDetails';
import { getRuleDataByRuleType } from './services/RulesServices';
import LineItemTotals from './line-items/LineItemTotal';
import { AgreementHistoryInputType, DocumentRecordInput } from '../../../__generated__/globalTypes';
import { Attachment } from './attachments/Attachment';
import { useAppDispatch, useAppSelector } from '../../main/hooks';
import { getDocumentRecord } from './__generated__/getDocumentRecord';
import {
    DESTROY_SESSION,
    destroyState,
    hasFiscalRepresentativeChecked,
    inLiquidationChecked,
    setDocumentMode,
    updateAgreementHistory,
    updateBMWBusinessAreaId,
    updateBMWBusinessUnitId,
    updateBMWRetailOutlet,
    updateCollapsedState,
    updateCompanyCode,
    updateCompanyPartnerId,
    updateCompanyVatCodeId,
    updateDocumentId,
    updateDocumentType,
    updateFiscalCountryCode,
    updateFiscalRegionCode,
    updateLegalFormTypeId,
    updateLineItems,
    updatePartnerVatCodeId,
    updateShippingFromCountryCode,
    updateShippingFromRegionCode,
    updateShippingToCountryCode,
    updateShippingToRegionCode,
    updateSupplier,
    updateSupplierCountryCode,
    clearStateAttachments,
    updateAttAchmentUploadsLeft,
    resetAttAchmentUploadsLeft,
    isBmwGroupCompany,
    updateCompanyCountryCode,
    setCopyTemplate,
    updatePartnerNo,
    setPartnerAddressCity,
    setPartnerAddressCountryCode,
    setPartnerAddressPostalCode,
    setPartnerAddressRegionCode,
    setPartnerAddressStreet1,
    setPartnerAddressStreet2,
    setPartnerName,
    setPartnerVatCode,
    setPartnerVatCountryCode,
    setBmwCompanyCity,
    setBmwCompanyPostalCode,
    setBmwCompanyStreet1,
    setBmwCompanyStreet2,
    setCompanyCountryCode,
    setCompanyVatCode,
    setCompanyVatCountryCode,
    setPartnerTaxId,
    setBmwCompanyTaxId,
    setCompanyName,
    setLoading,
    setPartnerVatSelectOptions,
    setSystemCode, setIsOrderNumberNotRequired,
} from './IStoredDocumentRecordSlice';
import { MUTATE_ECAP_DOCUMENT_RECORD } from './mutations';
import SubmitDocumentModal from './submitDocumentModal';
import { mutateDocumentRecord } from './__generated__/mutateDocumentRecord';
import { isArray, noop } from 'lodash';
import { setRules } from './IStoredRulesSlice';
import { IStoreLineItem, MODE } from './IStoredDocumentRecord';
import {
    populateDocumentDetails,
    populateShippingInformationDetails,
    populateSupplierDetails,
} from './view-document-helper';
import EInvoicingAgreement from './eInvoicing-agreement/EInvoicingAgreement';
import {
    getPartnerAddressByPartnerId,
    getPartnerAddressByPartnerIdVariables,
} from './__generated__/getPartnerAddressByPartnerId';
import {
    GET_PARTNER_ADDRESS_DETAILS,
    GET_PARTNER_VAT_INFO_BY_PARTNER_NO,
    INVOICE_AGREEMENT_HISTORY_V2,
    INVOICE_AGREEMENT_INFO
} from "./queries";
import SupplierHeader from './supplier/SupplierHeader';
import BMWCompanyHeader from './bmw-company/BMWCompanyHeader';
import {
    getCountryListData, getCurrencyListData,
    getExemptionTypes,
    getTaxLawReferences,
    getUnitOfMeasureTypes,
} from './services/SelectServices';
import { buildDocument } from './capture-document-helper';
import dayjs from 'dayjs';
import { destroyPartnerState, updateSelectedPartner } from './IStoredPartnerRecordSlice';
import RenderIfEntitled, { RenderIf } from '../../components/EntitlementComponents';
import { getProviderDocumentType } from '../documents/__generated__/getProviderDocumentType';
import { SupportingDocumentService } from './services/SupportingDocumentService';
import client from '../../config/GraphQLApolloClient';
import Input from 'antd/lib/input/Input';
import { filteredEntitlementLookupForSelf } from '../../main/entitlementService';
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from '../../main/__generated__/getSelfEntitlementResultByFilter';
import { getPartnerVATInfoByPartnerNo, getPartnerVATInfoByPartnerNoVariables, getPartnerVATInfoByPartnerNo_getPartnerVATInfoByPartnerNo_vatCodes } from './__generated__/getPartnerVATInfoByPartnerNo';
import { GET_invoice_agreement_history_V2, GET_invoice_agreement_history_V2Variables } from './__generated__/GET_invoice_agreement_history_V2';
import {useNavigate} from "react-router-dom";

const errorStyle = {
    background: '#c11414',
};

const okStyle = {
    background: '##e8e8e8',
};

const MAX_TEMPLATES = 15;

type CaptureDocumentComponentChannel = {
    lineItemRefresh: () => void;
    lineItemTotalRefresh: () => void;
    lineItemValidation: (document:any)=>boolean;
    resetAttachmentsCallback: () => void;
    deleteAttachmentsCallback: () => void;
    numberOfAttachmentsCallback: () => number;
    saveAttachmentsCallback: (docId: number) => Promise<boolean[]>;
    saveBusinessUnitsCallback: (arg: number) => any;
};

export type IAppComponentProps = 
{ currentUser?: any; 
  distinctEntitlements?: string[];
  templateMode?: boolean;
} & WrappedComponentProps;

const CaptureDocument: FunctionComponent<IAppComponentProps> = (props) => {
    const [componentCommChannel, _] = useState<CaptureDocumentComponentChannel>({
        lineItemRefresh: null,
        lineItemTotalRefresh: null,
        lineItemValidation:null,
        resetAttachmentsCallback: () => {},
        saveAttachmentsCallback: null,
        numberOfAttachmentsCallback: null,
        deleteAttachmentsCallback: null,
        saveBusinessUnitsCallback: null,
    });

    const dispatch = useAppDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [lineItemState, setLineItemState] = useState<LineItemsState>(new LineItemsState());
    // const [loading, isLoading] = useState(false);
    const [flag, setFlag] = useState(false);
    const rerender = () => setFlag((prev) => !prev);
    const [selectOptions, setSelectOption] = useState<getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]>(undefined);
    const [vatSelectOption, setVatSelectOption] = useState<getPartnerVATInfoByPartnerNo_getPartnerVATInfoByPartnerNo_vatCodes[]>([]);

    const [getAddressDetails, {}] = useLazyQuery<getPartnerAddressByPartnerId, getPartnerAddressByPartnerIdVariables>(
        GET_PARTNER_ADDRESS_DETAILS,
        {
            fetchPolicy: 'network-only',
            onError: (error) => console.log(error),
        }
    );

    const rules = useAppSelector((state) => state.rules);
    const [profileStyle, setProfileStyle] = useState<any>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [documentRecord, setDocumentRecord] = useState<getDocumentRecord>(undefined);

    const [getAgreementHistory, {}] = useLazyQuery<
        GET_invoice_agreement_history_V2,
        GET_invoice_agreement_history_V2Variables
    >(INVOICE_AGREEMENT_HISTORY_V2, {
        fetchPolicy: 'network-only',
        onError: (error) => console.log(error),
    });

    //Get only BMW Document types (serviceProviderId = 1)
    const { loading: documentTypesLoading, data: documentTypesDropdownData } = useQuery<getProviderDocumentType>(
        SELECT_PROVIDER_DOCTYPE_QUERY,
        {
            fetchPolicy: 'cache-first',
            variables: { serviceProviderId: 1 },
            onError: (error) => console.error(error),
        }
    );
    const isDisabledRef = useRef(false);
    const [saveDocumentRecord, {}] = useMutation<mutateDocumentRecord>(MUTATE_ECAP_DOCUMENT_RECORD);

    const [documentType, setDocumentType] = useState<string>('');
    const { document } = useAppSelector((state) => state.document);
    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 15 },
    };
    const [getInvoiceAgreementInfoData, {}] = useLazyQuery(INVOICE_AGREEMENT_INFO, {
        fetchPolicy: "network-only",
        variables: {companyCode: document.companyCode,languageCode: localStorage.getItem("locale").toLocaleUpperCase(), moduleCode: "ECAP",category: "V"}
    });

    async function clearState() {
        await dispatch(destroyState(DESTROY_SESSION));
        console.log("State cleared : ");
        console.dir(document);
        dispatch(destroyPartnerState(DESTROY_SESSION));
        form.resetFields();
    }

    function addNewError(newPanel, newShortInfoErrors) {
        const item = newShortInfoErrors.find((item) => item === newPanel);
        if (item === undefined) {
            newShortInfoErrors.push(newPanel);
        }
        return newShortInfoErrors;
    }

    const populateCompanyAddress = (form: FormInstance, documentRecord: DocumentRecordInput) => {

        //VAT/TAX refactor
        form.setFieldsValue({ companyStreet1: documentRecord.document.bmwCompanyStreet1 });
        form.setFieldsValue({
            companyPostalCode: documentRecord.document.bmwCompanyPostalCode,
        });
        form.setFieldsValue({ companyCountry: documentRecord.document.bmwCompanyCountryCode });
        form.setFieldsValue({ companyCity: documentRecord.document.bmwCompanyCity });
        form.setFieldsValue({
            companyCountryCode: documentRecord.document.bmwCompanyCountryCode,
        });
        //TODO (Jacques) this might be a duplicate dispatch , please verify
        dispatch(updateCompanyCountryCode(documentRecord.document.bmwCompanyCountryCode));

        // Promise.resolve(getCompanyAddressDetails(companyId, 'P'))
        //     .then((response) => {
        //         form.setFieldsValue({ companyStreet1: response.getCompanyAddressDetailsByCompanyId.at(0).street1 });
        //         form.setFieldsValue({
        //             companyPostalCode: response.getCompanyAddressDetailsByCompanyId.at(0).postalCode,
        //         });
        //         form.setFieldsValue({ companyCountry: response.getCompanyAddressDetailsByCompanyId.at(0).country });
        //         form.setFieldsValue({ companyCity: response.getCompanyAddressDetailsByCompanyId.at(0).city });
        //         form.setFieldsValue({
        //             companyCountryCode: response.getCompanyAddressDetailsByCompanyId.at(0).countryCode,
        //         });
        //         dispatch(updateCompanyCountryCode(response.getCompanyAddressDetailsByCompanyId.at(0).countryCode));
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //     });
    };

    const getDocumentNumber = (documentNumber) => {
      const newDocumentNumber = '(copy)';
      return newDocumentNumber;
  };


    const copy = (generateNewDocNumber: boolean) => {
      const formDocument = form.getFieldValue('document');
      dispatch(updateDocumentId(undefined));
      //dispatch(updateLineItems(document.lineItems));
      dispatch(setDocumentMode(MODE.EDIT));
      if (generateNewDocNumber)
      {
        formDocument.documentNumber = getDocumentNumber(formDocument.documentNumber);
      }
      //Clear certain fields on copy
      formDocument.deliveryDate=null;
      formDocument.deliveryNumber=null;
      formDocument.exchangeRateDate=null;
      setDocumentRecord(undefined);
      if (!props.templateMode)
      {
        componentCommChannel?.resetAttachmentsCallback();
        dispatch(clearStateAttachments());
        dispatch(resetAttAchmentUploadsLeft());
      }
      
  };

    const removeDuplicated = (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) => Array.from(
        data
            ?.reduce(
                (acc, item) => (
                    item && item["partnerId"] && acc.set(item["partnerId"], item),
                        acc
                ),
                new Map()
            )
            ?.values());

    const onlyShowVendorPartners = (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) => Array.from(
        data
            ?.filter(item => item.category === 'V')
            ?.values());

  const findSelectedPartner = (partnerNo) => {
    Promise.resolve(filteredEntitlementLookupForSelf("create_dp@ecap", partnerNo, 1, 50))
      .then((response) => {
          console.debug(`Searching for ${partnerNo}`)
          console.debug('The entitlement response', response?.getSelfEntitlementResultByFilter?.partners)
          setSelectOption(response?.getSelfEntitlementResultByFilter?.partners);

          function fetchPartner(partners:getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) {
              if (partners && partnerNo) {
                const selectedPartner = partners.find(partner=>partner.partnerNo === partnerNo);
                console.debug(`The selected partner is`,selectedPartner)
                return selectedPartner;
              }

              return partners.at(0);
          }

          const partnerList = removeDuplicated(onlyShowVendorPartners(response?.getSelfEntitlementResultByFilter?.partners));
          console.debug("Partner Numbers");
          console.debug(partnerList);

        console.debug(`Number of partner records returned : ${partnerList.length}`);
        dispatch(updateSelectedPartner(fetchPartner(partnerList)));
      })
      .catch((err) => {
        console.error(err);
      }).finally(()=> {
        // isLoading(false);
        dispatch(setLoading(false));
    });
  };

    const getDocumentRecord = (documentId, forceRead: boolean, loadCompanyInfo:boolean) => {
        if (documentRecord === undefined || forceRead) {
            // isLoading(true);
            dispatch(setLoading(true));
            getDocumentRecordData(documentId).then((data) => {
                console.log('Load Document');
                console.dir(data);

                const documentRecord = data;
                setDocumentRecord(documentRecord);
                //set template field
                if (props.templateMode)
                {
                  const documentTemplate = {documentTemplate: {templateName: documentRecord.getDocumentRecord.documentTemplate?.templateName} }
                  form.setFieldsValue({ ...documentTemplate });
                }
                
                //
                dispatch(updateDocumentType(data.getDocumentRecord?.document.documentTypeId));
                dispatch(updateSupplier(data.getDocumentRecord?.document.partnerId));
                dispatch(updatePartnerNo(data.getDocumentRecord?.supplierDetails.partnerNo));
                dispatch(updateLegalFormTypeId(data.getDocumentRecord?.supplierDetails.legalFormTypeId));
                dispatch(updateBMWBusinessAreaId(data.getDocumentRecord?.document.bmwBusinessAreaId));
                dispatch(updateBMWBusinessUnitId(data.getDocumentRecord?.document.bmwBusinessUnitId));
                dispatch(updateBMWRetailOutlet(data.getDocumentRecord?.document.bmwIsRetailOutlet));
                dispatch(updateCompanyPartnerId(data.getDocumentRecord?.document.companyPartnerId));
                //Add new fields for VAT/TAX refactor
                dispatch(setPartnerAddressCity(data.getDocumentRecord?.supplierDetails.city));
                dispatch(setPartnerAddressCountryCode(data.getDocumentRecord?.supplierDetails.countryCode));
                dispatch(setPartnerAddressPostalCode(data.getDocumentRecord?.supplierDetails.postalCode));
                dispatch(setPartnerAddressRegionCode(data.getDocumentRecord?.supplierDetails.regionCode));
                dispatch(setPartnerAddressStreet1(data.getDocumentRecord?.supplierDetails.street1));
                dispatch(setPartnerAddressStreet2(data.getDocumentRecord?.supplierDetails.street2));
                dispatch(setPartnerName(data.getDocumentRecord?.supplierDetails.partnerName));
                dispatch(setPartnerVatCode(data.getDocumentRecord?.supplierDetails.partnerVatCode));
                dispatch(setCompanyName(data.getDocumentRecord?.supplierDetails.companyName));
                dispatch(updateCompanyCode(data.getDocumentRecord?.supplierDetails.companyCode));
                dispatch(setPartnerVatCountryCode(data.getDocumentRecord?.supplierDetails.partnerVatCountryCode));
                dispatch(setBmwCompanyCity(data.getDocumentRecord?.document.bmwCompanyCity));
                dispatch(setBmwCompanyPostalCode(data.getDocumentRecord?.document.bmwCompanyPostalCode));
                dispatch(setBmwCompanyStreet1(data.getDocumentRecord?.document.bmwCompanyStreet1));
                dispatch(setBmwCompanyStreet2(data.getDocumentRecord?.document.bmwCompanyStreet2));
                dispatch(setCompanyCountryCode(data.getDocumentRecord?.document.bmwCompanyCountryCode));
                dispatch(setCompanyVatCode(data.getDocumentRecord?.supplierDetails.companyVatCode));
                dispatch(setCompanyVatCountryCode(data.getDocumentRecord?.supplierDetails.companyVatCountryCode));
                dispatch(setPartnerTaxId(data.getDocumentRecord?.supplierDetails.partnerTaxId));
                dispatch(setBmwCompanyTaxId(data.getDocumentRecord?.document.bmwCompanyTaxId));
                dispatch(setSystemCode(data.getDocumentRecord?.supplierDetails.systemCode));

                dispatch(setIsOrderNumberNotRequired(data.getDocumentRecord?.document?.isOrderNumberNotRequired));

                if (loadCompanyInfo) {
                    findSelectedPartner(data.getDocumentRecord?.supplierDetails?.partnerNo);
                }
                
                dispatch(
                    updateFiscalCountryCode(
                        data.getDocumentRecord?.supplierDetails.fiscalRepresentativeAddressCountryCode
                    )
                );
                dispatch(
                    updateFiscalRegionCode(
                        data.getDocumentRecord?.supplierDetails.fiscalRepresentativeAddressRegionCode
                    )
                );
                dispatch(
                    hasFiscalRepresentativeChecked(data.getDocumentRecord?.supplierDetails.hasFiscalRepresentative)
                );
                dispatch(inLiquidationChecked(data.getDocumentRecord?.supplierDetails.inLiquidation));
                //VAT/TAX refactor
                dispatch(updatePartnerVatCodeId(data.getDocumentRecord?.document.partnerVatCodeId));
                dispatch(
                    updateShippingFromCountryCode(data.getDocumentRecord?.shippingInformation.shipFromCountryCode)
                );
                dispatch(updateShippingFromRegionCode(data.getDocumentRecord?.shippingInformation.shipFromRegionCode));
                dispatch(updateShippingToCountryCode(data.getDocumentRecord?.shippingInformation.shipToCountryCode));
                dispatch(updateShippingToRegionCode(data.getDocumentRecord?.shippingInformation.shipToRegionCode));
                dispatch(updateCompanyVatCodeId(data.getDocumentRecord?.document.companyVatCodeId));
                dispatch(isBmwGroupCompany(data.getDocumentRecord?.document.bmwGroupPaymentRequest));
                const fields = form.getFieldsValue();
                const { supplierDetails, shippingInformation } = fields;

                //VAT/TAX refactor
                fields.supplierStreet1 = data.getDocumentRecord?.supplierDetails.street1;
                fields.supplierCity = data.getDocumentRecord?.supplierDetails.city;
                fields.supplierCountry = data.getDocumentRecord?.supplierDetails.countryCode;
                fields.supplierPostalCode = data.getDocumentRecord?.supplierDetails.postalCode;
                fields.supplierRegionCode = data.getDocumentRecord?.supplierDetails.regionCode;
                //TODO (Jacques) this might be a duplicate dispatch, we need to check
                dispatch(updateSupplierCountryCode(data.getDocumentRecord?.supplierDetails.countryCode));
                fields.shippingInformation = {};
                fields.shippingInformation.shipToStreet = data.getDocumentRecord?.shippingInformation?.shipToStreet;
                fields.shippingInformation.shipToCity = data.getDocumentRecord?.shippingInformation?.shipToCity;
                fields.shippingInformation.shipToPostalCode = data.getDocumentRecord?.shippingInformation?.shipToPostalCode;
                form.setFieldsValue({ ...fields });


                // getAddressDetails({
                //     variables: {
                //         partnerId: data.getDocumentRecord?.document.partnerId,
                //         pageSize: 1,
                //         pageNumber: 1,
                //     },
                // }).then((response) => {
                //     const address = response.data?.getPartnerAddressByPartnerId[0];
                //     const fields = form.getFieldsValue();
                //     fields.supplierStreet1 = address?.street1;
                //     fields.supplierCity = address?.city;
                //     fields.supplierCountry = address?.country;
                //     fields.supplierPostalCode = address?.postalCode;
                //     fields.supplierRegionCode = address?.regionCode;
                //     //store countryCode in state to use for region rule
                //     dispatch(updateSupplierCountryCode(address?.countryCode));

                //     form.setFieldsValue({ ...fields });
                // });

                //get agreement history for partner company
                //TODO TAX/VAT Refactor, This call still uses companyPartnerId which are no longer available, we need to change the call to use different parameters
                getAgreementHistory({
                    variables: { partnerNo: data.getDocumentRecord?.supplierDetails.partnerNo, companyCode: data.getDocumentRecord?.supplierDetails.companyCode, systemCode: data.getDocumentRecord?.supplierDetails.systemCode },
                }).then((response) => {
                    dispatch(updateAgreementHistory(response.data?.getAgreementHistoryV2));
                });

                const newFormDocument = {
                    ...populateDocumentDetails(data.getDocumentRecord?.document),
                    documentTypeId: getI18n(
                        data?.getDocumentRecord?.document?.documentTypePhraseId,
                        data?.getDocumentRecord?.document?.documentType,
                        intl
                    ),
                };
                const newSupplierDetails = {
                    ...supplierDetails,
                    ...populateSupplierDetails(data.getDocumentRecord?.supplierDetails),
                };
                newSupplierDetails.supplierName = `${data.getDocumentRecord?.supplierDetails.partnerNo} - ${data.getDocumentRecord?.supplierDetails.partnerName}`;
                console.log("Supplier details");
                console.dir(newSupplierDetails);
                form.setFieldsValue({ bmwGroupCompany: data.getDocumentRecord?.document.bmwGroupPaymentRequest });

                //VAT/TAX refactor
                form.setFieldsValue({ taxId: data.getDocumentRecord?.supplierDetails.partnerTaxId });
                form.setFieldsValue({ companyTaxCode: data.getDocumentRecord?.document.bmwCompanyTaxId });
                //

                const newShippingInformation = {
                    ...shippingInformation,
                    ...populateShippingInformationDetails(data.getDocumentRecord?.shippingInformation),
                };

                Promise.all([
                    getCountryListData(),
                    getExemptionTypes(),
                    getUnitOfMeasureTypes(),
                    getTaxLawReferences(),
                    getCurrencyListData()
                ]).then((results) => {
                    function setSelected(
                        id: number | string,
                        idProperty: string,
                        descriptiveTextProperty: string,
                        array: any[]
                    ) {
                        if (id !== undefined || id !== null) {
                            for (let i = 0; i < array.length; i++) {
                                const itemValue = array[i];
                                if (itemValue[idProperty] === id) {
                                    return `${id}_${itemValue[descriptiveTextProperty]}`;
                                }
                            }
                        }

                        return null;
                    }

                    function getUnitOfMeasureValue(id: number) {
                        return setSelected(id, 'id', 'symbol', results[2]);
                    }

                    function getTaxLawReferenceValue(id: number) {
                        return setSelected(id, 'id', 'description', results[3]);
                    }

                    function getExemptionTypeValue(id: number) {
                        return setSelected(id, 'id', 'description', results[1]);
                    }

                    function getSupplyingCountryValue(id: string) {
                        return setSelected(id, 'id', 'country', results[0].getCountryList);
                    }

                    const selectedCurrency = results[4].getCurrencyList.find(item=>item.id === data.getDocumentRecord?.document?.currencyCodeId);

                    const lineItemRecords: LineItemRecord[] = [];
                    if (data.getDocumentRecord?.lineItems?.length > 0) {
                        const theLineItems =
                            data.getDocumentRecord.lineItems.slice().sort(
                                (a,b)=>a.lineNo-b.lineNo);

                        theLineItems.forEach((item, i) => {
                            const lineItem = new LineItemRecord();
                            lineItem.quantity = item.quantity;
                            lineItem.unitPrice = item.unitPrice;
                            lineItem.precision = selectedCurrency?.decimalPlaces || 2;
                            lineItem.bmwPartNumber = item.bmwPartnerNumber;
                            lineItem.unitPriceQuantity = item.unitQuantity;
                            lineItem.orderNumber = item.orderNumber;
                            lineItem.itemDescription = item.itemDescription;
                            lineItem.deliveryOrServiceNumber = item.deliveryNo;
                            lineItem.deliveryDate = item.deliveryDate;
                            lineItem.unitOfMeasureFreeText = item.unitOfMeasureFreeText;
                            lineItem.taxRate = item.taxRate;
                            lineItem.exceptionReason = item.exemptionReason;
                            lineItem.quantityUnit = getUnitOfMeasureValue(item.unitOfMeasureId);
                            lineItem.taxLawReference = getTaxLawReferenceValue(item.taxLawReferenceId);
                            lineItem.toolLocation = item.toolLocation;
                            lineItem.srmRefNumber = item.srmReferenceNumber;
                            lineItem.inventoryNumber = item.inventoryNumber;
                            lineItem.exceptionType = getExemptionTypeValue(item.taxExemptionTypeId);
                            lineItem.supplyingCountry = getSupplyingCountryValue(item.toolSupplyingCountry);
                            lineItemRecords.push(lineItem);
                        });
                    } else {
                        const lineItem = new LineItemRecord();
                        lineItemRecords.push(lineItem);
                    }
                    lineItemState.lineItems = lineItemRecords;

                    dispatch(
                        updateLineItems(
                            lineItemState.lineItems.map((lineItem) => {
                                const lineItemDTO: IStoreLineItem = { ...lineItem };
                                return lineItemDTO;
                            })
                        )
                    );

                    populateCompanyAddress(form, data?.getDocumentRecord);

                    form.setFieldsValue({ document: newFormDocument });
                    form.setFieldsValue({ supplierDetails: newSupplierDetails });
                    form.setFieldsValue({ shippingInformation: newShippingInformation });
                    form.setFieldsValue({
                        companyCodeDisplayFld: `${data.getDocumentRecord?.supplierDetails.companyCode} - ${data.getDocumentRecord?.supplierDetails.companyName}`,
                    });
                    console.log(`Company VAT code : ${data.getDocumentRecord?.supplierDetails.companyVatCode}`);
                    form.setFieldsValue({ companyVatCode: data.getDocumentRecord?.supplierDetails.companyVatCode });
                    form.setFieldsValue({ headerCurrency: data.getDocumentRecord?.document.currencyCodeId });
                    if (data.getDocumentRecord?.document?.bmwBusinessAreaId) {
                        const businessAreaId = data.getDocumentRecord?.document?.bmwBusinessAreaId;
                        form.setFieldsValue({ bmwBusinessArea: businessAreaId });
                        componentCommChannel.saveBusinessUnitsCallback(businessAreaId);
                    }
                    if (data.getDocumentRecord?.document?.bmwBusinessUnitId) {
                        form.setFieldsValue({
                            bmwBusinessUnit: data.getDocumentRecord?.document?.bmwBusinessUnitId,
                        });
                    }

                    if (data.getDocumentRecord?.document.bmwIsRetailOutlet) {
                        form.setFieldsValue({
                            bmwRetailOutlet: getI18n('ECAP-capture-BMW-retail-outlet-choice-yes', 'Yes', intl),
                        });
                    } else {
                        form.setFieldsValue({
                            bmwRetailOutlet: getI18n('ECAP-capture-BMW-retail-outlet-choice-no', 'No', intl),
                        });
                    }
                });
                if (document.copyTemplate)
                {
                  copy(false);
                  //Reset the copy template flag as we are now in normal document edit mode
                  dispatch(setCopyTemplate(false));
                }

                if (!loadCompanyInfo) {
                    // isLoading(false);
                    dispatch(setLoading(false));
                }
            });
        }
    };

    const loadRules = () => {
        if (rules === undefined || rules.rules.getFilteredRulesWithRuleType.length === 0) {
            getRuleDataByRuleType('ecap.capture').then((data) => {
                dispatch(setRules(data));
            });
        }
    };

    useEffect(() => {
      console.log(`Document mode : ${document.documentMode}`);
      console.log(`Template mode : ${props.templateMode}`);
        setProfileStyle(errorStyle);
        loadRules();
        if (document.documentId !== null && documentRecord?.getDocumentRecord.document?.id !== document.documentId) {
            const documentId = document.documentId;
            console.log(`Get document record : ${document.documentId}`);
            getDocumentRecord(documentId, false,true);
        }
        

        return () => {
            clearState();
        };
    }, []);

    const docTypeSelectionChange = (key, option) => {
        setDocumentType(option.key);
    };

    const { Panel } = Collapse;
    const { Option } = Select;

    const handleCancelOk = () => {
        clearState();
        if ((document.documentMode === MODE.VIEW || document.documentMode === MODE.EDIT) && !props.templateMode) {
            navigate('/e-cap/draft-documents');
        }
        else if ((document.documentMode === MODE.VIEW || document.documentMode === MODE.EDIT || document.documentMode === undefined ) && props.templateMode) {
            navigate('/ecap/manage-templates');
        } else if (document.documentMode === MODE.OVERVIEW) {
            navigate('/documents/documents-overview', { state: { isFromCaptureDocument: true } });
        } else {
            navigate('/e-cap-welcome');
        }
    };
    const cancel = () => {
        if (
            document.documentMode === MODE.EDIT ||
            document.documentMode === undefined ||
            document.documentMode === null
        ) {
            Modal.confirm({
                title: getI18n('e-cap-capture-document-cancel-confirm-modal-header', 'Cancel Document', intl),
                content: getI18n('e-cap-capture-document-cancel-confirm-modal-message', 'Data will be lost. Do you want to continue?', intl),
                okText: getI18n('e-cap-capture-document-cancel-confirm-modal-yes', 'YES', intl),
                cancelText: getI18n('e-cap-capture-document-cancel-confirm-modal-no', 'NO', intl),
                onOk: handleCancelOk,
            });
        } else {
            handleCancelOk();
        }
    };

    const reset = () => {
        Modal.confirm({
            title: getI18n('e-cap-capture-document-reset-confirm-modal-header', 'Reset Document', intl),
            content: getI18n('e-cap-capture-document-cancel-confirm-modal-message','Data will be lost. Do you want to continue?', intl),
            okText: getI18n('e-cap-capture-document-cancel-confirm-modal-yes', 'YES', intl),
            cancelText: getI18n('e-cap-capture-document-cancel-confirm-modal-no', 'NO', intl),
            onOk: () => {
                form.resetFields(), clearState();
                lineItemState.lineItems = [new LineItemRecord()];
                if (!props.templateMode)
                {
                    componentCommChannel?.resetAttachmentsCallback();
                }
                componentCommChannel?.lineItemRefresh();
                componentCommChannel?.lineItemTotalRefresh();
                const newLineItemState: IStoreLineItem[] = lineItemState.lineItems.map((item) => ({ ...item }));
                dispatch(updateLineItems(newLineItemState));
            },
        });
    };

    const saveAttachments = (docId: number): Promise<boolean[]> => {
        const newService = new SupportingDocumentService(client);
        const promises: Promise<boolean>[] = [];
        //console.log('Save attachments');
        //console.dir(document.attachments);
        document.attachments.forEach((attachment) => {
            const blobResponse = attachment.blobResponse;
            if (blobResponse && !attachment.supportingDocument) {
                const promise = newService
                    .associateWithBlob({
                        documentId: docId,
                        blobId: blobResponse.blobId,
                        insertUser: blobResponse.insertUser,
                    })
                    .then((result) => {
                        attachment.supportingDocument = result;
                        attachment.supportingDocument.fileName = blobResponse.fileName;
                        attachment.supportingDocument.blobPath = blobResponse.downloadLink;
                        console.log(`Successfully attached supporting doc`, result);
                        return true;
                    })
                    .catch((error) => {
                        console.error(error);
                        console.log('Could not attach document ', docId);
                        return false;
                    });
                promises.push(promise);
            } else {
                promises.push(Promise.resolve(true));
            }
        });

        return Promise.all(promises);
    };

    const save = (values) => {
        console.debug('The document being saved', document);
        console.log('Form data');
        console.dir(values);
        //check all fields for validity, highlight the tabs where fields are in error
        const formDocument = form.getFieldValue('document');
        const formDocumentTemplate = form.getFieldValue('documentTemplate');
        if (props.templateMode 
            && (formDocumentTemplate === undefined 
                || formDocumentTemplate?.templateName === undefined 
                || formDocumentTemplate?.templateName === null
                || formDocumentTemplate?.templateName === ""))
        {
          message.error(
            getI18n(
                'ecap-form-save-failure-template-name',
                'Unable to save, Please enter a template name',
                intl
            )
            );
        }
        else if (props.templateMode && document.documentType === undefined) 
        {
          message.error(
            getI18n(
                'ecap-form-save-failure-document-type',
                'Unable to save, Please select document type',
                intl
            )
            );
        }
        else if (props.templateMode && document.templateCount > MAX_TEMPLATES)  {
            message.error(getI18n("ecap-template-document-max-number-of-documents","A maximum of 15 templates is allowed",intl));
        }
        else if (!document.partnerNo) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
            newShortInfoErrors = addNewError('supplierDetailsPanelKey', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-form-save-failure-supplerDetails',
                    'Unable to save, Please select supplier details ',
                    intl
                )
            );
        } else if (document.companyCode === undefined) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
            newShortInfoErrors = addNewError('companyPanel', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-form-save-failure-companydetails',
                    'Unable to save, Please select a Company Code first ',
                    intl
                )
            );
        } else if (!props.templateMode && (formDocument === undefined || formDocument.documentNumber === undefined || formDocument.documentNumber === null
            || formDocument.documentDate === undefined || formDocument.documentDate === null)) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-form-save-failure-headerdetails',
                    'Unable to save, Please enter a Document Number and Document Date ',
                    intl
                )
            );
        } 
        else if (formDocument.documentDate && formDocument.deliveryDate && formDocument.deliveryDate.startOf('d').isAfter(formDocument.documentDate.endOf('d'))) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-service-date-validation',
                    'Delivery / Service date cannot be after invoice date ',
                    intl
                )
            );
        }
        else {
            // isLoading(true);
            dispatch(setLoading(true));
            const newDocumentRecord = buildDocument(values, form, documentRecord, document, props.templateMode);
            saveDocumentRecord({ variables: { documentRecord: newDocumentRecord } })
                .then((response) => {
                    // isLoading(false);
                    dispatch(setLoading(false));
                    if (props.templateMode === undefined || !props.templateMode )
                    {
                      notification.success({
                        message: getI18n('ecap-document-success', 'Success', intl),
                        description: getI18n('ecap-document-updateSuccess', 'Draft saved successfully', intl),
                    });
                    }
                    else
                    {
                      notification.success({
                        message: getI18n('ecap-document-success', 'Success', intl),
                        description: getI18n('ecap-document-template-updateSuccess', 'Template saved successfully', intl),
                    });
                    }
                    
                    const newDocId = response?.data?.mutateDocumentRecord?.document?.id;
                    console.log(`New document Id : ${newDocId}`);
                    if (newDocId) {
                        dispatch(updateDocumentId(newDocId));                        
                        //const result = componentCommChannel.saveAttachmentsCallback(newDocId);
                        const result = saveAttachments(newDocId);
                        setDocumentRecord(undefined);
                        getDocumentRecord(newDocId,true,false);
                    }
                })
                .catch((err) => {
                    // isLoading(false);
                    dispatch(setLoading(false));
                    console.error(`Failure on component: ${err}`);
                    notification.error({
                        message: getI18n('ecap-document-error', 'Error', intl),
                        description: getI18n('ecap-document-updateFailure', 'Document update failed to save', intl),
                    });
                });
        }
    };

    const submitDocument = (agreementId :number) => {
        //make sure form validation runs
        //if no issues exists then update document status to submitted (status id 2)
        if (isDisabledRef.current) return; // If button is disabled, do nothing
        isDisabledRef.current = true; // Immediately disable the button
        setTimeout(() => isDisabledRef.current = false, 3000); // Enable the button after 3 seconds
        setIsModalVisible(false);
        //isLoading(true);
        dispatch(setLoading(true));
        const newDocumentRecord = buildDocument(form.getFieldsValue(), form, documentRecord, document, props.templateMode);

        const agreementHistory: AgreementHistoryInputType = {
            agreementAcceptedDate: dayjs.utc(),
            companyPartnerId: newDocumentRecord.document.companyPartnerId,
            agreementId,
        };
        newDocumentRecord.agreementHistory = agreementHistory;
        saveDocumentRecord({ variables: { documentRecord: newDocumentRecord } })
            .then((response) => {
                const newDocId = response?.data?.mutateDocumentRecord?.document?.id;
                const result = saveAttachments(newDocId).then((attachmentResult) => {
                    console.log('Attachment result', attachmentResult);
                    //Update status to 2  (submitted)
                    Promise.resolve(updateDocumentStatusData(response.data.mutateDocumentRecord.document.id, 2))
                        .then((data) => {
                            // isLoading(false);
                            dispatch(setLoading(false));
                            notification.success({
                                message: getI18n('ecap-document-success', 'Success', intl),
                                description: getI18n(
                                    'ecap-document-updateSuccess',
                                    'Document submitted successfully',
                                    intl
                                ),
                            });

                            //redirect to the draft page
                            clearState();
                            navigate('/e-cap-welcome');
                        })
                        .catch((err) => {
                            // isLoading(false);
                            dispatch(setLoading(false));
                            console.error(`Failure on component: ${err}`);
                            notification.error({
                                message: getI18n('ecap-document-error', 'Error', intl),
                                description: getI18n(
                                    'ecap-document-updateFailure',
                                    'Document update failed to save',
                                    intl
                                ),
                            });
                        });
                    return false;
                });
            })
            .catch((error) => {
                // isLoading(false);
                dispatch(setLoading(false));
                console.error(`Failure on component: ${error}`);
                notification.error({
                    message: getI18n('ecap-document-error', 'Error', intl),
                    description: getI18n('ecap-document-updateFailure', 'Document update failed to save', intl),
                });
            });
    };




    const cancelModal = () => {
        setIsModalVisible(false);
        // isLoading(true);
        dispatch(setLoading(true));
        const newDocumentRecord = buildDocument(form.getFieldsValue(), form, documentRecord, document, props.templateMode);

        saveDocumentRecord({ variables: { documentRecord: newDocumentRecord } }).then((_) => {
            // isLoading(false);
            dispatch(setLoading(false));
            notification.info({
                message: getI18n('ecap-document-success', 'Success', intl),
                description: getI18n(
                    'ecap-document-submit-modal-cancel-message',
                    'Your invoice has been saved as a draft and can only be submitted after the eInvoicing Agreement is accepted. Further details to our eInvoicing Agreement can be found under FAQ',
                    intl
                ),
            });
        });
    };
    const handleOk = (agreementId : number) => {
        setIsModalVisible(false);
        submitDocument(agreementId);
    };
    const submit = () => {
        //check if partnerId and companyPartnerId was selected
        console.log(`Selected partner id : ${document.partnerId}`);
        console.log(`Selected Company partner id : ${document.partnerId}`);

        const formDocument = form.getFieldValue('document');
        const taxId = form.getFieldValue('taxId');

        function failedAttachmentValidation() {
            const hasTaxGTZero = !document?.lineItems?.every((item) => item.taxRate === 0) || false;

            const companyCodeIsLY = document.companyCode === 'LY';
            const nonGermanGTThanZero = document.supplierCountryCode !== 'DE' && hasTaxGTZero;
            const hungarySupplierVat = document.supplierCountryCode === 'HU' && document.partnerVatCountryCode === 'HU';

            console.debug(
                'Are attachments mandatory',
                `company code is LY ${companyCodeIsLY} & non german GT zero ${nonGermanGTThanZero} & hungary supplier vat ${hungarySupplierVat} & number of attachments ${componentCommChannel.numberOfAttachmentsCallback()}`
            );

            const areAttachmentsMandatory = companyCodeIsLY || nonGermanGTThanZero || hungarySupplierVat;

            return areAttachmentsMandatory && document.attachments.length === 0;
        }

        function failedGrossValidation() {
            if (document?.lineItems.length > 1) {
                const grossTotal =
                    document?.lineItems?.map((item) => item.grossAmount).reduce((prev, curr) => prev + curr) || 0;
                return grossTotal < 0;
            } else if (document?.lineItems.length === 1) {
                return document?.lineItems[0].grossAmount < 0;
            }
        }

        function failedHeaderDetailsValidation() {
            return !formDocument.documentNumber || !formDocument.documentDate;
        }

        function failedHeaderDetailsDeliveryDateValidation() {
            return document.deliveryDateInError;
        }

        function failedLineItemValidationNotInView() {
            return componentCommChannel.lineItemValidation(document);
        }

        console.info(`Gross Amount validation result: [${failedGrossValidation()}]`);
        console.info(`Header details validation result: [${failedHeaderDetailsValidation()}]`);
        console.info(`Attachments: [${failedAttachmentValidation()}]`);

        if (!document.documentType) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n('ecap-document-type-submit-error', 'Unable to submit, Please select a Document Type', intl)
            );
        } else if (document.partnerId === undefined || document.companyPartnerId === undefined) {
            let newShortInfoErrors = [];
            if (document.partnerId === undefined) {
                newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
                newShortInfoErrors = addNewError('supplierDetailsPanelKey', newShortInfoErrors);
            }
            //TODO VAT/TAX Refactor , i need to relook what fields to use now to ensure partner and company code were completed
            if (document.companyPartnerId === undefined) {
                newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
                newShortInfoErrors = addNewError('companyPanel', newShortInfoErrors);
            }

            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-form-submit-failure-missing-partner',
                    'Unable to submit, Please selected a Supplier and BMW Company Code to continue',
                    intl
                )
            );
        } else if (failedHeaderDetailsValidation()) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-form-save-failure-headerdetails',
                    'Unable to save, Please enter a Document Number and Document Date ',
                    intl
                )
            );
         }
        else if (failedHeaderDetailsDeliveryDateValidation()) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-form-save-failure-headerdetails-delivery-date',
                    'Unable to save, Please see detail failure on Delivery field ',
                    intl
                )
            );
        } else if (formDocument.documentNumber.startsWith('(copy)')) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-form-save-failure-headerdetails-documentNumber',
                    'Invalid format: A document should not be preceded with the text (copy)',
                    intl
                )
            );
        } else if (formDocument.documentNumber.startsWith('(template)')) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n(
                    'ecap-form-save-failure-headerdetails-documentNumber-template',
                    'Invalid format: A document should not be preceded with the text (template)',
                    intl
                )
            );
        }
        else if (failedGrossValidation()) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('lineItems', newShortInfoErrors);
            newShortInfoErrors = addNewError('documentsTotals', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(getI18n('ecap-form-line-item-failure', 'Document total cannot be less than zero', intl));
        } else if (failedAttachmentValidation()) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('attachments', newShortInfoErrors);
            dispatch(updateCollapsedState(newShortInfoErrors));
            message.error(
                getI18n('ecap-form-submit-attachment-failure', 'Unable to submit, Attachments are mandatory', intl)
            );
        
        }
        else if ((document.partnerVatCode === undefined) && (taxId === undefined || taxId === "" || taxId === null)) {
            let newShortInfoErrors = [];
            newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
            newShortInfoErrors = addNewError('supplierDetailsPanelKey', newShortInfoErrors);
            message.error(
                getI18n('ecap-form-submit-vat-tax-missing', 'Unable to submit as some fields were not completed correctly and/or an update of masterdata is necessary (https://b2b.bmw.com)', intl)
            );
        
        }
        else if (failedLineItemValidationNotInView()) {
            dispatch(updateCollapsedState(['lineItems']));
            message.error(intl.formatMessage({id:'line-item-generic-message'}));
        }
        else {
            if (
                document.agreementHistory.agreementHistoryId === undefined ||
                document.agreementHistory.agreementHistoryId === null
            ) {
                setIsModalVisible(true);
            } else {
                getInvoiceAgreementInfoData().then( responseData => {
                    const agreementInfo = responseData.data?.getAgreementInfo;
                    if(agreementInfo.length > 0) {
                        submitDocument(agreementInfo[0].agreementId);
                    }
                })

            }
        }
    };

    const documentTypeChanged = (value) => {
        if (value !== '1') {
            form.setFieldsValue({ bmwGroupCompany: false });
        }

        if (value === '4') {
            form.setFieldValue(["document","deliveryDate"], null);
        }

        dispatch(updateDocumentType(Number(value)));
    };

    const submitFormFailed = (errors) => {
        message.error(
            getI18n('ecap-form-submit-failure', 'Unable to submit as Some fields was not completed correctly', intl)
        );
        const errorfields = errors.errorFields;
        console.log('Fields in Error');
        console.dir(errorfields);
        //Build panel list to expand
        let newShortInfoErrors = [];
        if (isArray(errorfields)) {
            errorfields.map((el) => {
                const name: String = el.name.toString();
                console.log(`name : ${name}`);
                if (name.includes('shipFrom')) {
                    newShortInfoErrors = addNewError('shippingInformation', newShortInfoErrors);
                    newShortInfoErrors = addNewError(4.4, newShortInfoErrors);
                } else if (name.includes('shipTo')) {
                    newShortInfoErrors = addNewError('shippingInformation', newShortInfoErrors);
                    newShortInfoErrors = addNewError(4.1, newShortInfoErrors);
                } else if (name.includes('supplierDetails')) {
                    newShortInfoErrors = addNewError('supplierDetailsPanelKey', newShortInfoErrors);
                    newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
                    if (
                        name.includes('fiscal') ||
                        name.includes('managingDirector') ||
                        name.includes('companyRegistrationNumber') ||
                        name.includes('placeOfRegistration') ||
                        name.includes('inLiquidation') ||
                        name.includes('hasFiscalRepresentative') ||
                        name.includes('chairmanOfSupervisoryBoard') ||
                        name.includes('companyRegistryData') ||
                        name.includes('legalFormType') ||
                        name.includes('liquidationRemark')
                    ) {
                        newShortInfoErrors = addNewError(5.6, newShortInfoErrors);
                    }
                    if (
                        name.includes('bankIban') ||
                        name.includes('bankAccountNumber') ||
                        name.includes('bankSwiftBicNumber') ||
                        name.includes('bankBranchCode') ||
                        name.includes('bankIban')
                    ) {
                        newShortInfoErrors = addNewError(5.5, newShortInfoErrors);
                    }
                    if (
                        name.includes('contactName') ||
                        name.includes('contactTelephone') ||
                        name.includes('contactDepartment') ||
                        name.includes('contactEmail')
                    ) {
                        newShortInfoErrors = addNewError(4.2, newShortInfoErrors);
                    }
                } else if (name.includes('line_item')) {
                    newShortInfoErrors = addNewError('lineItems', newShortInfoErrors);
                } else if (
                    name.includes('orderNumber') ||
                    name.includes('documentDate') ||
                    name.includes('documentNumber') ||
                    name.includes('deliveryNumber') ||
                    name.includes('deliveryDate') ||
                    name.includes('headerCurrency')
                ) {
                    newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
                } else if (
                    name.includes('supplierName') ||
                    name.includes('partnerVatCodeId') ||
                    name.includes('taxId') ||
                    name.includes('bmwGroupCompany') ||
                    name.includes('supplierStreet1') ||
                    name.includes('supplierCountry') ||
                    name.includes('supplierCountryCode') ||
                    name.includes('supplierPostalCode') ||
                    name.includes('supplierCity') ||
                    name.includes('contactName')
                ) {
                    newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
                } else if (name.includes('exchangeRateDate') || name.includes('exchangeRate')) {
                    newShortInfoErrors = addNewError('documentsTotals', newShortInfoErrors);
                }
                else if (name.includes('bmwContactEmail')) {
                    newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
                    newShortInfoErrors = addNewError('companyPanel', newShortInfoErrors);
                }
                else if (name.includes('originalInvoiceNumber') || name.includes('agreementReference')) {
                    newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
                    newShortInfoErrors = addNewError('headerNoteKey', newShortInfoErrors);
                }else if (name.includes('bmwContactName') || name.includes('bmwContactDepartment')) {
                    newShortInfoErrors = addNewError('profileInformation', newShortInfoErrors);
                    newShortInfoErrors = addNewError('1.2', newShortInfoErrors);
                    newShortInfoErrors = addNewError('companyPanel', newShortInfoErrors);
                }
                else if (name === 'document,footerText') {
                    newShortInfoErrors = addNewError('headerDetails', newShortInfoErrors);
                    newShortInfoErrors = addNewError('headerNoteKey', newShortInfoErrors);
                }

            });
        }
        dispatch(updateCollapsedState(newShortInfoErrors));
    };

    const collapseTitles = [
        {
            key: 'profileInformation',
            titleId: 'e-cap-capture-document-profile-information-panel-title',
            title: 'Profile Information',
            style: { profileStyle },
            contents: (
                <div>
                    <SupplierDetails currentUser={props.currentUser} intl={intl} form={form} />
                    <CompanyComponent documentRecord={documentRecord} intl={intl} form={form} />
                </div>
            ),
        },
        {
            key: 'headerDetails',
            titleId: 'e-cap-capture-document-header-details-panel-title',
            title: 'Header Details',
            contents: (
                <HeaderDetails
                    distinctEntitlements={props.distinctEntitlements}
                    intl={intl}
                    currentUser={props.currentUser}
                    onCurrencyChange={rerender}
                    onDeliveryNumberChange={() => componentCommChannel.lineItemRefresh()}
                    onOrderNumberChange={() => componentCommChannel.lineItemRefresh()}
                    form={form} templateMode={props.templateMode}
                />
            ),
        },
        {
            key: 'lineItems',
            titleId: 'e-cap-capture-document-lines-items-panel-title',
            title: 'Line Items',
            contents: (
                <LineItems
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                    intl={intl}
                    form={form}
                    onItemChange={() => componentCommChannel.lineItemTotalRefresh()}
                    refreshCallback={(arg: () => void) => (componentCommChannel.lineItemRefresh = arg)}
                    validationCallback={(arg: (document:any) => boolean) => (componentCommChannel.lineItemValidation = arg)}
                    state={lineItemState}
                />
            ),
        },
        {
            key: 'documentsTotals',
            titleId: 'e-cap-capture-document-documents-total-panel-title',
            title: 'Document Totals',
            contents: (
                <LineItemTotals
                    form={form}
                    intl={intl}
                    currentUser={props.currentUser}
                    refreshCallback={(arg: () => void) => (componentCommChannel.lineItemTotalRefresh = arg)}
                    lineItemState={lineItemState}
                />
            ),
        },
        {
            key: 'shippingInformation',
            titleId: 'e-cap-capture-document-shipping-information-panel-title',
            title: 'Shipping Information',
            contents: (
                <ShippingInfoDetails
                    form={form}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                    intl={intl}
                >
                    components
                </ShippingInfoDetails>
            ),
        },
        !props.templateMode ?
        {
            key: 'attachments',
            titleId: 'e-cap-capture-document-attachments-panel-title',
            title: 'Attachments',
            contents: (
                <Attachment
                    form={form}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                    intl={intl}
                    clearCallback={(arg: () => void) => (componentCommChannel.resetAttachmentsCallback = arg)}
                    saveAttachmentsCallback={(arg: (docId: number) => Promise<boolean[]>) =>
                        (componentCommChannel.saveAttachmentsCallback = arg)
                    }
                    numberOfAttachmentsCallback={(arg: () => number) =>
                        (componentCommChannel.numberOfAttachmentsCallback = arg)
                    }
                    deleteAttachmentCallback={(arg: () => void) =>
                        (componentCommChannel.deleteAttachmentsCallback = arg)
                    }
                />
            ),
        } : {contents: undefined}
        ,
        !props.templateMode ?
        {
            key: 'invoiceAgreement',
            titleId: 'e-cap-capture-document-agreement-panel-title',
            title: 'eInvoicing Agreement',
            contents: (
                <EInvoicingAgreement
                    form={form}
                    currentUser={props.currentUser}
                    distinctEntitlements={props.distinctEntitlements}
                    intl={intl}
                />
            ),
        } : {contents: undefined}
        ,
    ];

    const captureTitleMassage = !props.templateMode
        ? getI18n('e-cap-capture-document-title-massage', '', intl)
        : getI18n('e-cap-capture-template-title-massage', '', intl);

    const title =
        document.documentMode === MODE.EDIT && !props.templateMode
            ? getI18n('e-cap-edit-document-title', 'Edit Document', intl)
            : document.documentMode === MODE.OVERVIEW && !props.templateMode
            ? getI18n('e-cap-view-document-title', 'View Document', intl)
            : document.documentMode === MODE.VIEW && !props.templateMode
            ? getI18n('e-cap-view-draft-document-title', 'View Draft Document', intl)
            : document.documentMode === MODE.VIEW && props.templateMode === true
            ? getI18n('e-cap-view-template-title', 'View Template', intl)
            : document.documentMode === MODE.EDIT && props.templateMode === true
            ? getI18n('e-cap-edit-template-title', 'Edit Template', intl)
            : !props.templateMode
            ? getI18n('e-cap-capture-document-title', 'Capture Document', intl)
            : getI18n('e-cap-capture-template-title', 'Capture Template', intl);


    const [getPartnerTaxAndVatDetails, {}] = useLazyQuery<getPartnerVATInfoByPartnerNo, getPartnerVATInfoByPartnerNoVariables>(GET_PARTNER_VAT_INFO_BY_PARTNER_NO, {
        fetchPolicy: "network-only", onError: (error) => console.log(error)
        });




    const fillPartnerVatDetails = (partnerNo: string, companyCode: string) => {
        console.log(`Search VAT details Partner No: ${partnerNo} , Company Code: ${companyCode}`);
        // isLoading(true);
        dispatch(setLoading(true));

        if (partnerNo !== undefined && partnerNo !== null && companyCode !== undefined && companyCode !== null)
        {
            getPartnerTaxAndVatDetails({ variables: { partnerNo, companyCode} }).then(response => {
                console.log("Partner VAT details");
                console.dir(response.data?.getPartnerVATInfoByPartnerNo?.vatCodes);
                setVatSelectOption(response.data?.getPartnerVATInfoByPartnerNo?.vatCodes);
                dispatch(setPartnerVatSelectOptions(response.data?.getPartnerVATInfoByPartnerNo?.vatCodes));

                dispatch(setPartnerTaxId(response.data?.getPartnerVATInfoByPartnerNo?.taxId ));

                form.setFieldsValue({ taxId: response.data?.getPartnerVATInfoByPartnerNo?.taxId });
    
                const allFormFields = form.getFieldsValue();
                const { document } = allFormFields;
                if ((response.data?.getPartnerVATInfoByPartnerNo?.vatCodes === undefined || response.data?.getPartnerVATInfoByPartnerNo?.vatCodes?.length === 0) && 
                (response.data?.getPartnerVATInfoByPartnerNo?.taxId === undefined || response.data?.getPartnerVATInfoByPartnerNo?.taxId === null || response.data?.getPartnerVATInfoByPartnerNo?.taxId === ""))
                {
                    // message.error(getI18n(
                    //     'ecap-form-partner-select-vat-missing',
                    //     'No Supplier VAT Info and TAX Id found for Partner and Company selected',
                    //     intl
                    // ));
                    const newDocument = {
                        ...document,
                        "partnerVatCodeId": undefined
                        };
                    form.setFieldsValue({ document: newDocument });
                    
                }
                if (response.data?.getPartnerVATInfoByPartnerNo?.vatCodes?.length === 1) {
                    const { supplierDetails } = allFormFields;
                    supplierDetails.partnerVatCode = response.data?.getPartnerVATInfoByPartnerNo?.vatCodes[0].vatCode;
                    const newDocument = {
                        ...document,
                        "partnerVatCodeId": `${response.data?.getPartnerVATInfoByPartnerNo?.vatCodes[0].vatCode}`
                    };
                    form.setFieldsValue({ document: newDocument });
                    form.setFieldsValue({ supplierDetails });
                    dispatch(updatePartnerVatCodeId(response.data?.getPartnerVATInfoByPartnerNo?.vatCodes[0].partnerVatId));
                    dispatch(setPartnerVatCode(response.data?.getPartnerVATInfoByPartnerNo?.vatCodes[0].vatCode));
                    dispatch(setPartnerVatCountryCode(response.data?.getPartnerVATInfoByPartnerNo?.vatCodes[0].country));

                }
                // isLoading(false);
                dispatch(setLoading(false));
            });
        }
        

    }

    return (
        <>
            <ConfigProvider locale={localStorage.getItem('locale') === 'en' ? enUS : deDe}>
                <Spin spinning={document.loading > 0} size={'large'}>
                    <Card title={title} key="1" className="pl-1" size={'small'}>
                        <Typography.Title level={5} className={'mb10'}>
                            {captureTitleMassage}
                        </Typography.Title>

                        <Form
                            size={'middle'}
                            {...formItemLayout}
                            form={form}
                            onFinish={submit}
                            onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : '')}
                            onFinishFailed={submitFormFailed}
                            disabled={document.documentMode === MODE.VIEW || document.documentMode === MODE.OVERVIEW}
                        >
                            <Row gutter={24} justify="start">
                                <Col span={24}>
                                    <Space>
                                        <Tooltip
                                            title={
                                                getI18n
                                                    ("e-cap-capture-document-cancel-icon-tool-tip",
                                                    "Cancel Processing", intl)
                                            }
                                            key={'keyCancelInvoiceIcon'}
                                        >
                                            <CloseOutlined
                                                style={{ fontSize: '25px', color: '#444444', cursor: 'pointer' }}
                                                onClick={cancel}
                                            />
                                        </Tooltip>
                                        {document.documentMode !== MODE.VIEW &&
                                            document.documentMode !== MODE.OVERVIEW && (
                                                <Tooltip
                                                    title={
                                                        getI18n
                                                            ("e-cap-capture-document-reset-icon-tool-tip",
                                                             "Reset All Values", intl)
                                                    }
                                                    key={'keyResetInvoiceIcon'}
                                                >
                                                    <RollbackOutlined
                                                        style={{ fontSize: '25px', color: '#444444' }}
                                                        onClick={reset}
                                                    />
                                                </Tooltip>
                                            )}
                                        {document.documentMode === MODE.VIEW && !props.templateMode && (
                                            <RenderIfEntitled
                                                entitlementToCheck="edit_dd@ecap"
                                                distinctEntitlements={props.distinctEntitlements}
                                                intl={props.intl}
                                            >
                                                <Tooltip
                                                    title={
                                                        getI18n("e-cap-edit-edit-icon-tool-tip",
                                                        "Edit Draft Document", intl)
                                                    }
                                                    key={'keyEditDraftIcon'}
                                                >
                                                    <EditOutlined
                                                        style={{ fontSize: '25px', color: '#444444' }}
                                                        onClick={() => dispatch(setDocumentMode(MODE.EDIT))}
                                                    />
                                                </Tooltip>
                                            </RenderIfEntitled>
                                        )}
                                        {document.documentMode === MODE.VIEW && props.templateMode && (
                                            <RenderIfEntitled
                                                entitlementToCheck="edit_tp@ecap"
                                                distinctEntitlements={props.distinctEntitlements}
                                                intl={props.intl}
                                            >
                                                <Tooltip
                                                    title={
                                                        getI18n(
                                                            "e-cap-edit-template-icon-tool-tip",
                                                            "Edit Template Document", intl)
                                                    }
                                                    key={'keyEditDraftIcon'}
                                                >
                                                    <EditOutlined
                                                        style={{ fontSize: '25px', color: '#444444' }}
                                                        onClick={() => dispatch(setDocumentMode(MODE.EDIT))}
                                                    />
                                                </Tooltip>
                                            </RenderIfEntitled>
                                        )}

                                        {document.documentMode !== MODE.VIEW &&
                                            document.documentMode !== MODE.OVERVIEW &&
                                            !props.templateMode && (
                                                <RenderIfEntitled
                                                    entitlementToCheck="save_dp@ecap"
                                                    distinctEntitlements={props.distinctEntitlements}
                                                    intl={props.intl}
                                                >
                                                    <Tooltip
                                                        title={
                                                            getI18n
                                                                ("e-cap-capture-document-save-icon-tool-tip",
                                                                "Save draft document", intl)
                                                        }
                                                        key={'keySaveInvoiceIcon'}
                                                    >
                                                        <SaveOutlined
                                                            style={{ fontSize: '25px', color: '#444444' }}
                                                            onClick={() => {
                                                                save(form.getFieldsValue());
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </RenderIfEntitled>


                                            )}
                                        {document.documentMode !== MODE.VIEW &&
                                            document.documentMode !== MODE.OVERVIEW &&
                                            props.templateMode && (
                                                <RenderIfEntitled
                                                    entitlementToCheck="edit_tp@ecap"
                                                    distinctEntitlements={props.distinctEntitlements}
                                                    intl={props.intl}
                                                >
                                                    <Tooltip
                                                        title={
                                                            getI18n
                                                                ("e-cap-capture-template-save-icon-tool-tip",
                                                                "Save template document", intl)
                                                        }
                                                        key={'keySaveInvoiceIcon'}
                                                    >
                                                        <SaveOutlined
                                                            style={{ fontSize: '25px', color: '#444444' }}
                                                            onClick={() => {
                                                                save(form.getFieldsValue());
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </RenderIfEntitled>
                                            )}

                                        {document.documentMode !== MODE.VIEW &&
                                            document.documentMode !== MODE.OVERVIEW &&
                                            !props.templateMode && (
                                                <RenderIfEntitled
                                                    entitlementToCheck="submit_dp@ecap"
                                                    distinctEntitlements={props.distinctEntitlements}
                                                    intl={props.intl}
                                                >
                                                    <Tooltip
                                                        title={
                                                            getI18n
                                                                ("e-cap-capture-document-submit-icon-tool-tip",
                                                                "Submit Document", intl)
                                                        }
                                                        key={'keySubmitInvoiceIcon'}
                                                    >
                                                        <Button type={'link'} htmlType="submit" className={'ml-10'}>
                                                            <RotateRightOutlined
                                                                style={{ fontSize: '25px', color: '#444444' }}
                                                                className={'ml-5'}
                                                                disabled={isDisabledRef.current}
                                                            />                                                       
                                                        </Button>
                                                    </Tooltip>
                                                </RenderIfEntitled>
                                            )}
                                        {(document.documentMode === MODE.VIEW ||
                                            document.documentMode === MODE.OVERVIEW) &&
                                            !props.templateMode && (
                                                <RenderIfEntitled
                                                    entitlementToCheck="copy_dp@ecap"
                                                    distinctEntitlements={props.distinctEntitlements}
                                                    intl={props.intl}
                                                >
                                                    <Tooltip
                                                        title={
                                                            getI18n
                                                                ("e-cap-capture-document-copy-icon-tool-tip",
                                                                 "Copy Document", intl)
                                                        }
                                                        key={'keyCopyInvoiceIcon'}
                                                    >
                                                        <CopyOutlined
                                                            style={{ fontSize: '25px', color: '#444444' }}
                                                            onClick={() => {
                                                                copy(true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </RenderIfEntitled>
                                            )}

                                        {(document.documentMode === MODE.VIEW ||
                                            document.documentMode === MODE.OVERVIEW) &&
                                            props.templateMode && (
                                                <RenderIfEntitled
                                                    entitlementToCheck="copy_tp@ecap"
                                                    distinctEntitlements={props.distinctEntitlements}
                                                    intl={props.intl}
                                                >
                                                    <Tooltip
                                                        title={
                                                            getI18n
                                                                ("e-cap-capture-template-copy-icon-tool-tip",
                                                                "Copy Template", intl)
                                                        }
                                                        key={'keyCopyInvoiceIcon'}
                                                    >
                                                        <CopyOutlined
                                                            style={{ fontSize: '25px', color: '#444444' }}
                                                            onClick={() => {
                                                                copy(true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </RenderIfEntitled>
                                            )}
                                    </Space>
                                </Col>
                            </Row>

                            <Row gutter={24} justify="start">
                                <Col span={24}>
                                    <Card id={'documentType'} style={{ background: '#e8e8e8', marginLeft: -1 }}>
                                        {props.templateMode !== undefined && props.templateMode && (
                                            <Row gutter={24}>
                                                <Col span={10}>
                                                    <Form.Item
                                                        name={['documentTemplate', 'templateName']}
                                                        required={true}
                                                        label={
                                                            <b>
                                                                {getI18n('capture-document-template-name','Template Name', intl)}
                                                            </b>
                                                        }
                                                    >
                                                        <Input
                                                            placeholder={getI18n('capture-document-template-name-placeholder', 'Template Name', intl)}
                                                            allowClear={true}
                                                            disabled={document.documentMode === MODE.VIEW || document.documentMode === MODE.OVERVIEW}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )}

                                        <Row gutter={24}>
                                            <Col span={10}>
                                                <Form.Item
                                                    name={['document', 'documentTypeId']}
                                                    required={true}
                                                    label={
                                                        <b>
                                                            {getI18n('welcome-document-type', 'Document Type', intl)}
                                                        </b>
                                                    }
                                                >
                                                    <Select
                                                        className=""
                                                        loading={documentTypesLoading}
                                                        showSearch={true}
                                                        placeholder={getI18n('welcome-document-type', 'Document Type', intl)}
                                                        onChange={documentTypeChanged}
                                                        onSelect={docTypeSelectionChange}
                                                    >
                                                        {documentTypesDropdownData &&
                                                            documentTypesDropdownData.getProviderDocumentType &&
                                                            documentTypesDropdownData.getProviderDocumentType.map(
                                                                (item) => (
                                                                    <Option
                                                                        key={`${item.id}_${item.documentType}`}
                                                                        value={item.id}
                                                                        data-testid={item.documentType}
                                                                    >
                                                                        {getI18n(
                                                                            item.phraseId,
                                                                            item.documentType,
                                                                            intl
                                                                        )}
                                                                    </Option>
                                                                )
                                                            )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <SupplierHeader
                                            currentUser={props.currentUser}
                                            intl={intl}
                                            form={form}
                                            distinctEntitlements={props.distinctEntitlements}
                                            templateMode={props.templateMode}
                                            vatSelectOption={vatSelectOption}
                                        />

                                        <BMWCompanyHeader
                                            documentRecord={documentRecord}
                                            intl={intl}
                                            currentUser={props.currentUser}
                                            form={form}
                                            businessUnitSetter={(arg: (arg: number) => any) =>
                                                (componentCommChannel.saveBusinessUnitsCallback = arg)
                                            }
                                            fillPartnerVatDetails={(partnerNo: string, companyCode: string) =>
                                                (fillPartnerVatDetails(partnerNo,companyCode))
                                            }

                                        />
                                    </Card>
                                </Col>
                            </Row>
                            {document.documentType &&
                                collapseTitles.map((item) =>
                                    item.contents ? (
                                        <Row key={`row ${item.key}`} gutter={24} className="mt10">
                                            <Col span={24}>
                                                <Collapse
                                                    activeKey={document.collapsedState}
                                                    onChange={(newActiveKeys) => {
                                                        // otherwise panels couldn't be open/closed by click
                                                        dispatch(updateCollapsedState(newActiveKeys));
                                                    }}
                                                    bordered={false}
                                                    expandIcon={({ isActive }) => (
                                                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                                    )}
                                                    expandIconPosition={'left'}
                                                    style={{ background: '#e8e8e8' }}
                                                >
                                                    <Panel
                                                        header={getI18n(item.titleId, item.title, intl)}
                                                        key={item.key}
                                                        forceRender={true}
                                                    >
                                                        <Card>{item.contents}</Card>
                                                    </Panel>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    ) : null // Render null for items with no contents
                                )}

                        </Form>
                    </Card>
                </Spin>
                <SubmitDocumentModal
                     companyCode={document.companyCode}
                     category={"V"}
                     isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    handleCancel={cancelModal}
                    key={'submitDocumentModalKey'}
                />
            </ConfigProvider>
        </>
    );
};

export default CaptureDocument;
