import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoreDocumentOverviewFilters } from './IStoredDocumentOverview';

interface DocumentOverviewState {
    documentOverviewFilters: IStoreDocumentOverviewFilters;
}

const initialState: DocumentOverviewState = {

    documentOverviewFilters: {       
        receptionDateFrom: undefined,
        receptionDateTo: undefined,
        partnerNo: undefined,
        partnerNoList:undefined,
        partnerName: undefined,
        documentTypeId: undefined,
        documentDateFrom: undefined,
        documentDateTo: undefined,
        documentNumbers: undefined,
        attachment: undefined,
        bmwCompanyId: undefined,
        bmwCompanyIdList:undefined,
        processCodeId: undefined,
        formatId: undefined,
        vinNumber: undefined
    }
};

const documentOverviewSlice = createSlice({
    name: 'documentOverviewFilters',
    initialState,
    reducers: {
    updateDocumentOverviewFilters(state, action: PayloadAction<IStoreDocumentOverviewFilters>) {
    state.documentOverviewFilters= action.payload
}
}},)

export const {
    updateDocumentOverviewFilters
} = documentOverviewSlice.actions;
export default documentOverviewSlice.reducer ;