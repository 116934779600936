import { saveAs } from "save-as";
import TemplateDocumentRowOptions from "./TemplateDocumentGridIconRenderer";
import { IntlShape } from "react-intl/src/types";
import { renderInvoiceCellValue } from "../../../../components/decorators/decorator";
import { getI18n } from "../../../../utils/Utils";
import {PortalUser} from "../../../../components";
import {convertFromSpecifiedDateFormatToUserDefined} from "../../../users/UserFormatPreferenceServices";


const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";

function renderDateCellValue(cellValue) {
  if (cellValue !== null && cellValue !== undefined && cellValue.trim().length > 0) {
      return (
        cellValue.substring(0,10)
      );
  }}

export const MYDOCS_TABLE_HEADERS_TEMPLATES = (intl: IntlShape, distinctEntitlements: string[],user:PortalUser) => [
  {
    headerName: getI18n("ecap-template-documents-grid-header-Options", "Options",intl),
    cellRenderer: TemplateDocumentRowOptions,
    width: 150
  },
  {
    headerName: getI18n("ecap-template-document-grid-header-template-name", "Template Name",intl),
    field: "templateName",
    colId: "templateName",
    tooltipField:'templateName',
    headerTooltip: getI18n("ecap-template-document-grid-header-template-name-tooltip", "Template Name",intl),
    filter: true,
    width: 210,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    headerCheckboxSelection: true,
    autoHeight: true,
  },
  {
    headerName: getI18n("ecap-template-document-grid-header-bmw-company", "BMW Company",intl),
    field: 'bmwCompany',
    colId: 'bmwCompany',
    tooltipField:'bmwCompany',
    headerTooltip: getI18n("ecap-template-document-grid-header-bmw-company-tooltip", "BMW Company",intl),
    filter: true,
    width: 100
  },
  {
    headerName: getI18n("ecap-template-document-grid-header-partner-number", "Partner Number",intl),
    field: 'partnerNumber',
    colId: 'partnerNumber',
    filter: true,
    width: 150,
    tooltipField: 'partnerNumber',
    headerTooltip: getI18n("ecap-template-document-grid-header-partner-number-tooltip", "Partner Number",intl),
  },
  {
    headerName: getI18n("ecap-template-document-grid-header-partner-name", "Partner Name",intl),
    field: 'partnerName',
    colId: 'partnerName',
    filter: true,
    width: 160,
    headerTooltip: getI18n("ecap-template-document-grid-header-partner-name-tooltip", "Partner Name",intl),
    cellRendererFramework: (params) => {
      return renderInvoiceCellValue(params.value) !== null ? renderInvoiceCellValue(params.value):null;
    },
  },
  {
    headerName: getI18n("ecap-template-document-grid-header-document-type", "Document Type",intl),
    field: 'documentType',
    colId: 'documentType',
    tooltipField:'documentType',
    headerTooltip: getI18n("ecap-template-document-grid-header-document-type-tooltip", "Document Type",intl),
    filter: true,
    width: 150
  },
  {
    headerName: getI18n("ecap-template-document-grid-header-created-date", "Created On",intl),
    field: 'templateInsertTime',
    colId: 'templateInsertTime',
    tooltipField: 'createdOn',
    headerTooltip: getI18n("ecap-template-document-grid-header-created-date-tooltip", "Created On",intl),
    filter: true,
    width: 150,
    valueGetter: (params) => {
      if (params.data.templateInsertTime) {
        return convertFromSpecifiedDateFormatToUserDefined(user, params.data.templateInsertTime, "YYYY-MM-DDTHH:mm:SS.sssZ");
      }
      return "";
    },
  },
  {
    headerName: getI18n("ecap-template-document-grid-header-updated-date", "Updated On",intl),
    field: 'templateUpdateTime',
    colId: 'templateUpdateTime',
    tooltipField: 'templateUpdateTime',
    headerTooltip: getI18n("ecap-template-document-grid-header-updated-date-tooltip", "Updated On",intl),
    filter: true,
    width: 150,
    valueGetter: (params) => {
      if (params.data.templateUpdateTime) {
        return convertFromSpecifiedDateFormatToUserDefined(user, params.data.templateUpdateTime, "YYYY-MM-DDTHH:mm:SS.sssZ");
      }
      return "";
    },
  },
  {
    headerName: getI18n("ecap-template-document-grid-header-tp-user", "TP User",intl),
    field: 'tpUser',
    colId: 'tpUser',
    tooltipField: 'tpUser',
    headerTooltip: getI18n("ecap-template-document-grid-header-tp-user-tooltip", "TP User",intl),
    filter: true,
    width: 150
  },
];
