import client from "../../../config/GraphQLApolloClient";
import {
    dropDownOverloadedConstructor,
    dropDownOverloadedConstructorWithPhraseId,
    DropDownSelection,
    InternalReport,
    ReportRegion
} from "../InfoPortalInterfaces";
import {RETRIEVE_DROPDOWN} from "../graphql/queries";
import {GetDropDownValues, GetDropDownValuesVariables} from "../graphql/__generated__/GetDropDownValues";
import {IntlShape} from "react-intl/src/types";
import {entitlementExists} from "../../../main/entitlementService";
import {
    getProviderDocumentType,
    getProviderDocumentTypeVariables,
} from '../../documents/__generated__/getProviderDocumentType';
import { SELECT_PROVIDER_DOCTYPE_QUERY } from '../../documents/queries';

function retrieveInfoPortalDropDowns(userId: number, distinctEntitlements: string[], dataType: string) {
    const language = localStorage.getItem("locale") || 'en';
    const entitlements = distinctEntitlements?.join(',') || '';
    return client.query<GetDropDownValues, GetDropDownValuesVariables>({
        query: RETRIEVE_DROPDOWN,
        variables: {dropDownQuery: {dataType, language, userId, entitlements}},
        fetchPolicy: "cache-first"
    }).then(response => response.data.infoPortalSelect.map(item => dropDownOverloadedConstructor(item.id, item.text)));
}

export function getDeliveryStatuses(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'delivery-status');
}

export function getReportTypes(region: ReportRegion | InternalReport, distinctEntitlements: string[], intl: IntlShape): Promise<DropDownSelection[]> {
    const arrayResponse: DropDownSelection[] = [];
    if (region === ReportRegion.BMW_MEXICO) {
        if (entitlementExists("display_ly@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("1", "report-type-ly1-label"));
        }
        if (entitlementExists("display_ly@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("2", "report-type-ly2-label"));
        }
        if (entitlementExists("display_ly@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("3", "report-type-ly4-label"));
        }
        if (entitlementExists("display_ly@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("4", "report-type-ly5-label"));
        }
        if (entitlementExists("display_ly@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("5", "report-type-ly6-label"));
        }
        if (entitlementExists("display_ly@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("6", "report-type-ly7-label"));
        }
    } else if (region === ReportRegion.BMW_GENERAL) {
        if (entitlementExists("display_sr@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("3", "report-type-sr3-label"));
        }
        if (entitlementExists("display_sr@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("1", "report-type-sr1-label"));
        }
        if (entitlementExists("display_sr@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("2", "report-type-sr2-label"));
        }
        if (entitlementExists("display_sr@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("4", "report-type-sr4-label"));
        }
        if (entitlementExists("display_sr@reports", distinctEntitlements)) {
            arrayResponse.push(dropDownOverloadedConstructorWithPhraseId("5", "report-type-sr5-label"));
        }
        if (entitlementExists("display_sr@reports", distinctEntitlements)) {
            arrayResponse.push(dropDownOverloadedConstructorWithPhraseId("6", "report-type-sr3-light-label"));
        }
    }
    else if (region === ReportRegion.BMW_NSC) {
        if (entitlementExists("display_nr@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("1", "report-type-sr1-label"));
        }
        if (entitlementExists("display_nr@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("3", "report-type-nr3-label"));
        }
        if (entitlementExists("display_nr@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("4", "report-type-sr4-label"));
        }
        if (entitlementExists("display_nr@reports", distinctEntitlements)) {
            arrayResponse.push(dropDownOverloadedConstructorWithPhraseId("5", "report-type-sr5-label"));
        }
    }
    else if (region === ReportRegion.BMW_CHINA) {
        if (entitlementExists("display_cz@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("1", "report-type-cz1-label"));
        }
        if (entitlementExists("display_cz@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("2", "report-type-cz2-label"));
        }
        if (entitlementExists("display_cz@reports", distinctEntitlements)) {
            arrayResponse.push(
                dropDownOverloadedConstructorWithPhraseId("3", "report-type-cz3-label"));
        }
    }

    return Promise.resolve(arrayResponse);
}

export function getInvoiceStatuses(userId: number, distinctEntitlements: string[], reportName: string) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, `invoice-status/report-id/${reportName}`);
}

export function getPlantInfo(userId: number, distinctEntitlements: string[], reportName: string) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, `plant/report-id/${reportName.substring(0, 2)}`);
}

export function getInvoiceDirection(userId: number, distinctEntitlements: string[]): Promise<DropDownSelection[]> {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'invoice-direction');
}

export function getInvoiceProcess(userId: number, distinctEntitlements: string[]): Promise<DropDownSelection[]> {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'invoice-process');
}

export function getStatus(userId: number, distinctEntitlements: string[]): Promise<DropDownSelection[]> {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'status');
}

export function getInvoicee(userId: number, distinctEntitlements: string[]): Promise<DropDownSelection[]> {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'invoicee');
}

export function getPurchaseOrderTypes(userId: number, distinctEntitlements: string[]): Promise<DropDownSelection[]> {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'po-types');
}

export function getGRstatus(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'gr-status');
}

export function getUnitOfMeasure(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'unit-of-measure');
}

export function getStatusCode(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'status-code');
}

export function getProcurement(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'procurement');
}

export function getType(userId: number, distinctEntitlements: string[], intl:IntlShape) {
    const response:DropDownSelection[] = [
        dropDownOverloadedConstructor('CDN',`CDN - ${intl.formatMessage({id:'reval-type-option-one'})}`),
        dropDownOverloadedConstructor('INV',`INV - ${intl.formatMessage({id:'reval-type-option-two'})}`)
    ];
    return Promise.resolve(response);
}

export function getPartnerType(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'partner-type');
}

export function getPaymentTerm(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'payment-term');
}

export function getOperators(userId: number, distinctEntitlements: string[],isNumeric:boolean) {
    function modifyDescription(dropDown: DropDownSelection) {
        const splitTxt = dropDown.description?.split('-');
        if (splitTxt && splitTxt.length > 1) {
            return dropDownOverloadedConstructor(dropDown.id, splitTxt[1]?.trim())
        }

        return dropDown;
    }
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, isNumeric?'operator-decimal':'operator-small').then(result => result.map(modifyDescription));
}

export function getOverdue(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, 'overdue-status');
}

export function getSettlementTypes(userId: number, distinctEntitlements: string[]) {
    return retrieveInfoPortalDropDowns(userId, distinctEntitlements, "settlement-type");

}

export function retrieveDocumentTypes() {
    return client.query<getProviderDocumentType, getProviderDocumentTypeVariables>({
        query: SELECT_PROVIDER_DOCTYPE_QUERY,
        variables: {serviceProviderId: 1},
        fetchPolicy: 'cache-first'
    });
}