import { IAppComponentProps } from '../../../components';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Checkbox, Col, DatePicker, Form, Modal, Row, Select, Space, Tooltip } from 'antd';
import { PortalFormItem } from '../../../components/FormUtils';
import { keys } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { BmwCompanyCode } from '../../../components/BmwCompanyCode';
import {
    allPartnersByPartnerNo,
    allPartnersByPartnerNo_allPartnersByPartnerNo,
    allPartnersByPartnerNoVariables,
} from '../../users/__generated__/allPartnersByPartnerNo';
//import { getPartnerByPartnerId_getPartnerByPartnerId,getPartnerByPartnerId} from "../e-cap/__generated__/getPartnerByPartnerId";
import { useLazyQuery } from '@apollo/client';
import { ALL_PARTNER_BY_PARTNER_NO } from '../../users/queries';
import { getI18n, isExternalUser } from '../../../utils/Utils';
import { getUserEntitlementResultByUserIdAndFilter_getUserEntitlementResultByUserIdAndFilter } from '../../../main/__generated__/getUserEntitlementResultByUserIdAndFilter';
import { updateSelectedPartner } from '../IStoredPartnerRecordSlice';
import { useAppDispatch } from '../../../main/hooks';
import { filteredEntitlementLookupForSelf } from '../../../main/entitlementService';
import { getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners } from '../../../main/__generated__/getSelfEntitlementResultByFilter';
import { getUserEntitlementResultByUserId } from '../../../main/__generated__/getUserEntitlementResultByUserId';
import { ECAP_USER_ENTITLEMENT_BY_USERID, USER_ENTITLEMENT_BY_USERID } from '../../../main/queries';
import { updateSupplier } from '../IStoredDocumentRecordSlice';
import { getFieldDef } from 'graphql/execution/execute';
import { getEcapUserEntitlementResultByUserId } from '../../../main/__generated__/getEcapUserEntitlementResultByUserId';
import {
    getDateFormatOrDefault
} from "../../users/UserFormatPreferenceServices";
import dayjs from "dayjs";

type DraftDocumentSearchFormProps = {
    count: number;
    onExport: any;
    onSearch: any;
} & IAppComponentProps;

const draft_Entitlement = 'display_dd@ecap';

const DraftDocumentSearchForm: React.FunctionComponent<DraftDocumentSearchFormProps> = (props) => {
    const [checked, setChecked] = React.useState(false);

    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [selectOptions, setSelectOption] = useState<
        getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]
    >([]);
    const { Option } = Select;
    const [filteredEntitlementDetail, setFilteredEntitlementDetail] =
        useState<getUserEntitlementResultByUserIdAndFilter_getUserEntitlementResultByUserIdAndFilter>();
    const [loading, isLoading] = useState<boolean>(false);
    const [partnerId, setPartnerId] = useState<number>(undefined);
    const intl = useIntl();

    // const [searchPartner, {}] = useLazyQuery<allPartnersByPartnerNo, allPartnersByPartnerNoVariables>(ALL_PARTNER_BY_PARTNER_NO, {
    //   fetchPolicy: "network-only", onError: (error) => console.log(error)
    // });

    const removeDuplicated = (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]) =>
        Array.from(
            data
                .reduce((acc, item) => (item && item['partnerId'] && acc.set(item['partnerId'], item), acc), new Map())
                .values()
        );

    const searchPartner = (partnerName) => {
        Promise.resolve(filteredEntitlementLookupForSelf(draft_Entitlement, partnerName, 1, 50))
            .then((response) => {
                const searchOption = removeDuplicated(response.getSelfEntitlementResultByFilter.partners);
                setSelectOption(searchOption);
                isLoading(false);
            })
            .catch((err) => {
                console.error(err);
                isLoading(false);
            });
    };

    const findSelectedPartner = (partnerId) => {
        return selectOptions.find((item) => {
            if (item.partnerId === partnerId) {
                return item;
            }
        });
    };
    const handleSearch = (value: string) => {
        isLoading(true);
        searchPartner(value);
    };
    const [getUserEntitlement, {}] = useLazyQuery<getEcapUserEntitlementResultByUserId>(
        ECAP_USER_ENTITLEMENT_BY_USERID,
        {
            variables: { userId: props.currentUser.uid, authentication: localStorage.getItem('id_token') },
            fetchPolicy: 'cache-first',
        }
    );

    const checkPartnerEntitlement = () => {
        if (isExternalUser(props.distinctEntitlements)) {
            isLoading(true);
            getUserEntitlement().then((response) => {
                const userEntitlement = response.data?.getEcapUserEntitlementResultByUserId;
                if (userEntitlement?.partners?.length === 1) {
                    form.setFieldsValue({
                        partnerId: `${userEntitlement.partners[0]?.partnerNo} - ${userEntitlement.partners[0]?.partnerName}`,
                    });
                    setPartnerId(userEntitlement.partners[0]?.partnerId);
                }
                if (userEntitlement?.partners?.length > 1) {
                    setSelectOption(userEntitlement?.partners);
                }
                isLoading(false);
            });
        }
    };

    useEffect(() => {
        checkPartnerEntitlement();
    }, []);

    const onSearch = () => {
        if (partnerId) {
            const values = { ...form.getFieldsValue(), partnerId };
            props.onSearch(values);
        } else {
            props.onSearch(form.getFieldsValue());
        }
    };

    const onClear = () => {
        Modal.confirm({
            title: intl.formatMessage({
                id: 'e-cap-draft',
                defaultMessage: 'Reset Input Data',
            }),
            content: intl.formatMessage({
                id: 'e-cap-message',
                defaultMessage: 'Input Data will be lost. Do you want to continue?',
            }),
            okText: intl.formatMessage({
                id: 'e-cap-capture-document-cancel-confirm-modal-yes',
                defaultMessage: 'YES',
            }),
            cancelText: intl.formatMessage({
                id: 'e-cap-capture-document-cancel-confirm-modal-no',
                defaultMessage: 'NO',
            }),
            onOk: () => form.resetFields(),
        });
    };

    const onExport = () => {
        props.onExport();
    };

    const handleChange = (value) => {
        isLoading(true);
        dispatch(updateSelectedPartner(findSelectedPartner(value)));
        setChecked(!checked);
        console.log(!checked);
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Form
                size={'middle'}
                form={form}
                style={{ width: '2100px' }}
                layout={'vertical'}
                onFinish={onSearch}
                onFinishFailed={onFinishFailed}
                initialValues={{ documentDateFrom: dayjs(), documentDateTo: dayjs() }}
            >
                <Card title={getI18n('management-searchmask-title', 'Search Mask', intl)} size={'default'}>
                    <Row gutter={24} style={{ alignContent: 'inherit', marginTop: '50' }}>
                        <Col span={4}>
                            <label>{getI18n('welcome-From Document Date', 'From Document Date', intl)}</label>
                            <Form.Item
                                name={'documentDateFrom'}
                                key={'documentDateFrom'}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'e-cap-capture-document-select-reception-date-from',
                                            defaultMessage: 'Please Select Reception Date From',
                                        }),
                                    },
                                ]}
                            >
                                <DatePicker
                                    defaultValue={dayjs()}
                                    format={getDateFormatOrDefault(props.currentUser)}
                                    placeholder={getI18n('welcome-From Document Date', 'From Document Date', intl)}
                                    style={{ width: 200 }}
                                    allowClear={true}
                                    data-testid={'documentDateFrom'}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    disabledDate={(current) => current.isAfter(dayjs().subtract(0, 'day'))}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <label>{getI18n('welcome-To Document Date', 'To Document Date', intl)}</label>
                            <Form.Item
                                name={'documentDateTo'}
                                key={'documentDateTo'}
                                rules={[{ required: true, message: 'Please Select Document Date To' }]}
                            >
                                <DatePicker
                                    defaultValue={dayjs()}
                                    format={getDateFormatOrDefault(props.currentUser)}
                                    placeholder={getI18n('welcome-To Document Date', 'To Document Date', intl)}
                                    style={{ width: 200 }}
                                    allowClear={true}
                                    data-testid={'documentDateTo'}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    disabledDate={(current) => current.isAfter(dayjs().subtract(0, 'day'))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item //rules={[{ required: true , message: 'Please enter Partner Number/ Partner Name' }]}
                                label={getI18n('partner-overview', 'Partner', intl)}
                                name={'partnerId'}
                            >
                                <Select
                                    style={{ width: 400 }}
                                    // className="mb30 mt6"
                                    size={'middle'}
                                    showSearch={true}
                                    placeholder={getI18n(
                                        'document-overview-search-message',
                                        'Enter partner number or partner name',
                                        intl
                                    )}
                                    onSearch={handleSearch}
                                    onChange={handleChange}
                                    defaultActiveFirstOption={true}
                                    filterOption={false}
                                    notFoundContent={null}
                                    loading={loading}
                                >
                                    {selectOptions &&
                                        selectOptions.map((item) => (
                                            <Select.Option
                                                key={`${item.partnerId}_${item.partnerNo}`}
                                                value={item.partnerId}
                                            >
                                                {item.partnerNo} - {item.partnerName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={4}>
                        <Col span={10}>
                            <PortalFormItem
                                label={getI18n('welcome-To-document-number', 'Document Numbers', intl)}
                                name={'documentNumbers'}
                                placeHolder={getI18n(
                                    'welcome-To-document-number-placeholder',
                                    'Enter Document Numbers, separate then with commas',
                                    intl
                                )}
                            />
                        </Col>
                        <Col span={5}>
                            <BmwCompanyCode
                                currentUser={props.currentUser}
                                intl={props.intl}
                                distinctEntitlements={props.distinctEntitlements}
                            />
                        </Col>
                        <Col span={5} className="mb15 mt05">
                            <Form.Item name={'myDocuments'} valuePropName="checked">
                                <Checkbox className="mb25 mt35">
                                    {getI18n('welcome-To-My Draft Documents', 'My Draft Documents  ', intl)}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card size="small">
                    <Row gutter={24}>
                        <Col xs={18} sm={4} md={2}>
                            <Button
                                type={'primary'}
                                htmlType="submit"
                                size="large"
                                className="w-100 mt10"
                                data-testid={'btn-search'}
                            >
                                {getI18n('welcome-search-btton', 'SEARCH', intl)}
                            </Button>
                        </Col>
                        <Col xs={18} sm={4} md={2}>
                            <Button
                                type="primary"
                                onClick={onClear}
                                size="large"
                                className="w-100 mt10"
                                data-testid={'btn-clear'}
                            >
                                {getI18n('welcome-Clear-button', 'CLEAR', intl)}
                            </Button>
                        </Col>
                        <Col xs={18} sm={4} md={2}>
                            <Button
                                type="primary"
                                id="export-list-button"
                                onClick={onExport}
                                size="large"
                                className="w-100 mt10"
                                data-testid={'btn-export'}
                            >
                                {getI18n('welcome-export-list', 'EXPORT LIST', intl)}
                            </Button>
                        </Col>

                        <Col xs={24} sm={6} md={4} className="mb20 mt20">
                            <label>{getI18n('welcome-No_documents', ' Number Of Documents ', intl)}</label>
                            <Space>
                                <Badge
                                    className="site-badge-count-109"
                                    count={props.count}
                                    style={{ backgroundColor: '#52c41a' }}
                                    title={'Number of Documents'}
                                    overflowCount={999999}
                                />
                            </Space>
                        </Col>

                        <Col span={2} xs={22} sm={6} md={4} />

                        <Col span={2} xs={22} sm={6} md={4} />
                    </Row>
                </Card>
            </Form>
        </>
    );
};
export default DraftDocumentSearchForm;

// export function RowOptions(props) {
//   return (<div>

//     <Tooltip title="Delete">
//         <DeleteOutlined style={{marginLeft: "5px", cursor: "pointer"}}
//                         onClick={() => console.log("Delete")} colSpan={3}/>

//     </Tooltip>

//     <Tooltip title="Copy">
//         <CopyOutlined
//             style={{marginLeft: "5px", cursor: "pointer"}}
//             onClick={() => console.log("Copy")}/>
//     </Tooltip>

//     <Tooltip title="Edit">
//     <EditOutlined
//         style={{marginLeft: "5px", cursor: "pointer"}}
//         onClick={() => console.log("Edit")}/>
// </Tooltip>
// <Tooltip title="View">
//    <EyeOutlined
//         style={{marginLeft: "5px", cursor: "pointer"}}
//         onClick={() => console.log("View")}/>
// </Tooltip></div>);
//     }

// function dispatch(arg0: any) {
//   throw new Error("Function not implemented.");
// }
// function findSelectedPartner(value: any): import("../../../main/__generated__/getSelfEntitlementResultByFilter").getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners {
//   throw new Error("Function not implemented.");
// }
