import client from "../../../config/GraphQLApolloClient";
import { GET_COMPANY_INFO_BY_PARTNER_NO, ALL_VAT_CODES_BY_COMPANY_ID, GET_COMPANY_ADDRESS_DETAILS, GET_PARTNER_ADDRESS_DETAILS, GET_PARTNER_VAT_INFO_BY_PARTNER_NO, GET_COMPANY_INFO_BY_PARTNER_NO_CATEGORY } from "../queries";
import { getPartnerVATInfoByPartnerNo } from "../__generated__/getPartnerVATInfoByPartnerNo";
import { getPartnerAddressByPartnerId } from "../__generated__/getPartnerAddressByPartnerId"
import { getCompanyAddressDetailsByCompanyId } from "../__generated__/getCompanyAddressDetailsByCompanyId";
import { allVatCodesByCompanyId } from "../__generated__/allVatCodesByCompanyId";
import { getCompanyInfoByCompanyPartnerNo } from "../__generated__/getCompanyInfoByCompanyPartnerNo";
import { getCompanyInfoByCompanyPartnerNoCategory } from "../__generated__/getCompanyInfoByCompanyPartnerNoCategory";
 
// TODO, not sure if we need to consider category code here as well
export const getPartnerVatInfoData = (partnerNo: string, companyCode: string) => {
  // get the partner VAT information
  return new Promise<getPartnerVATInfoByPartnerNo>((resolve, reject) => {
    client.query<getPartnerVATInfoByPartnerNo>({
      query: GET_PARTNER_VAT_INFO_BY_PARTNER_NO,
      variables: { partnerNo, companyCode },
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: getPartnerVATInfoByPartnerNo = { getPartnerVATInfoByPartnerNo: response.data?.getPartnerVATInfoByPartnerNo };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const allCompanyInfo = (companyCode: string, partnerNo: String) => {
  // get the Company info for company Partner No
  return new Promise<getCompanyInfoByCompanyPartnerNo>((resolve, reject) => {
    client.query<getCompanyInfoByCompanyPartnerNo>({
      query: GET_COMPANY_INFO_BY_PARTNER_NO,
      variables: { companyCode,  partnerNo},
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: getCompanyInfoByCompanyPartnerNo = { getCompanyInfoByCompanyPartnerNo: response.data?.getCompanyInfoByCompanyPartnerNo };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const allCompanyInfoPerCategory = (companyCode: string, partnerNo: String, category: String) => {
  // get the Company info for company Partner No and Category
  return new Promise<getCompanyInfoByCompanyPartnerNoCategory>((resolve, reject) => {
    client.query<getCompanyInfoByCompanyPartnerNoCategory>({
      query: GET_COMPANY_INFO_BY_PARTNER_NO_CATEGORY,
      variables: { companyCode,  partnerNo, category},
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: getCompanyInfoByCompanyPartnerNoCategory = { getCompanyInfoByCompanyPartnerNoCategory: response.data?.getCompanyInfoByCompanyPartnerNoCategory };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const allVatInfoByCompanyId = (companyId: number) => {
  // get the Company VAT Codes by Company Id
  return new Promise<allVatCodesByCompanyId>((resolve, reject) => {
    client.query<allVatCodesByCompanyId>({
      query: ALL_VAT_CODES_BY_COMPANY_ID,
      variables: { companyId },
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: allVatCodesByCompanyId = { allVatCodesByCompanyId: response.data?.allVatCodesByCompanyId };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });

  });
};

export const getPartnerAddressDetails = (partnerId: number, pageNumber: number, pageSize: number) => {
  // get the Company info for company Partner id
  return new Promise<getPartnerAddressByPartnerId>((resolve, reject) => {
    client.query<getPartnerAddressByPartnerId>({
      query: GET_PARTNER_ADDRESS_DETAILS,
      variables: { partnerId,  pageNumber, pageSize},
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: getPartnerAddressByPartnerId = { getPartnerAddressByPartnerId: response.data?.getPartnerAddressByPartnerId };
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });
  });
};

export const getCompanyAddressDetails = (companyId: number, addressType: string ) => {
  // get the Company Address info for Company id
  return new Promise<getCompanyAddressDetailsByCompanyId>((resolve, reject) => {
    client.query<getCompanyAddressDetailsByCompanyId>({
      query: GET_COMPANY_ADDRESS_DETAILS,
      variables: { companyId, addressType },
      fetchPolicy: "network-only"
    }).then((response) => {
      const finalResponse: getCompanyAddressDetailsByCompanyId = { getCompanyAddressDetailsByCompanyId: response.data.getCompanyAddressDetailsByCompanyId };
      //console.log("response from company address");
      //console.dir(finalResponse.getCompanyAddressDetailsByCompanyId);
      resolve(finalResponse);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });
  });
};