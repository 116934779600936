import React, { useEffect, useState } from 'react';
import {
    ddDotMMDotYYYYComparator,
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import { Card, FormInstance, Typography } from 'antd';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import { IntlShape } from 'react-intl/src/types';
import { retrieveLY5Report } from '../../../services/ReportSearch';
import styles from '../../styling/infoportal.module.css';
import {
    CompanySelection,
    descriptionFilter,
    OverdueStatusSelection,
    PartnerSelection,
    StatusSelection,
} from '../../InfoPortalDropdowns';
import { PurchaseOrderNumber } from '../../InputWithOperator';
import { DistributionFromToSelection } from '../../FromToDateSelection';
import { PortalUser } from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault, numericComparatorUsingUserPreferences
} from "../../../../users/UserFormatPreferenceServices";
import { entitlementExists } from '../../../../../main/entitlementService';

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company') {
                result.required = true;
            }
            if (fieldName === 'partner') {
                result.required = true;
            }
            if (fieldName === 'status') {
                result.required = true;
            }
            return result;
        },
    };
    return new Validator(validator);
}

function SupplierClaimsSimpleSearch({currentUser, intl, distinctEntitlements, reportEntitlement,initialization, form, initialValuesCache,rerenderDependentFields}: InfoPortalSearchProperties) {


    const [vertical, setVertical] = useState<boolean>(false);
    const [showOverdueStatus,setShowOverdueStatus] = useState<boolean>(true);
    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const { Text } = Typography;

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    };

    const statusSelectionChange: OnChangeListener<string[]> = {
        performAction(val: string[]) {
            const result = val[0] !== "C";
            setShowOverdueStatus(result);
        }
    };

    useEffect(()=> {
        const result = (form.getFieldValue("status") || initialValuesCache?.getValue("status"))!== "C";
        setShowOverdueStatus(result);

    },[]);

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({companyIds, newVal});
        }
    });

    return <Card size={"small"} style={{backgroundColor: "#f1f3f5"}}>
        <div className={styles.selfBilledSimpleSearchContainer} style={{marginLeft: "10px"}}>
            <div className={styles.singleColumnGrid}>
                <CompanySelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser}
                    initialValue={initialValuesCache?.getValue("company")}
                    intl={intl}
                    validator={validator}
                    filterOptions={descriptionFilter}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    onSelectionChange={companySelectionChange}
                    companyEntitlement={reportEntitlement}/>
                <PartnerSelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    currentUser={currentUser} intl={intl}
                    validator={validator}
                    initialValue={initialValuesCache?.getValue("partner")}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    filterOptions={descriptionFilter}
                    companyIds={initialValuesCache?.getValue("company")}
                    externalStateUpdater={partnerSelectionUpdate}
                    partnerEntitlement={reportEntitlement}/>
                <PurchaseOrderNumber
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("purchaseOrderNumberOperator", "CP")}
                    initialization={initialization} form={form} />

                <DistributionFromToSelection
                    validator={validator}
                    labelLocation={vertical ? LabelLocation.TOP : LabelLocation.LEFT}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    displayColon={!vertical}
                    initialValue={initialValuesCache?.getValue("distributionDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />
            </div>

            <div className={styles.singleColumnGrid}>

                <StatusSelection
                    initialValue={initialValuesCache?.getValue("status")}
                    displayColon={true}
                    selectionMode={DropDownSelectionMode.SINGLE}
                    onSelectionChange={statusSelectionChange}
                    currentUser={currentUser}
                    intl={intl}
                    validator={validator}
                    reportName={"ly5"}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />

                <OverdueStatusSelection
                    initialValue={initialValuesCache?.getValue("overdueStatus")}
                    displayColon={true}
                    disabled={!showOverdueStatus}
                    customDescription={(item)=>`${item.id} - ${item.description}`}
                    selectionMode={DropDownSelectionMode.SINGLE}
                    currentUser={currentUser}
                    intl={intl}
                    validator={validator}
                    reportName={"ly5"}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />

            </div>
        </div>

        <div style={{marginLeft: "10px"}}>

            <div style={{marginTop:"30px"}}>
                <ul>
                    <li><Text>{intl.formatMessage({id:'ly5-extra-info-1'})}</Text></li>
                    <li><Text>{intl.formatMessage({id:'ly5-extra-info-2'})}</Text></li>
                    <li><Text>{intl.formatMessage({id:'ly5-extra-info-3'})}</Text></li>
                </ul>
            </div>
            <div>&nbsp;</div>
        </div>

    </Card>;
}

export class LY5AgGridColumnDef implements InfoPortalAgGridColumnDef {
    getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [];
    }

    getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser,distinctEntitlements:string[]): InfoPortalColumnDef[] {
        return [
            {field: "compcode", headerName: intl.formatMessage({"id": "grid-heading-company"})},
            {field: "partnerno", headerName: intl.formatMessage({"id": "grid-heading-partnerNumber"})},
            { field: "claimstatus", headerName: intl.formatMessage({ "id": "grid-heading-status" }) },
            {field: "ponumber", headerName: `${intl.formatMessage({"id": "grid-heading-purchaseOrderNumber"})} *`},
            {field: "overduestatus", headerName: intl.formatMessage({"id": "overdue-status"})},
            {field: "poposition", headerName: intl.formatMessage({ "id": "grid-heading-purchaseOrderItem"})},
            {field: "materialno", headerName: `${intl.formatMessage({"id": "grid-heading-materialNumber"})}`},
            {field: "description", headerName: `${intl.formatMessage({"id": "grid-heading-materialText"})}`},
            {field: "supplclaimno", headerName: `${intl.formatMessage({"id": "grid-heading-supplierClaimNumberDeliveryNote"})}`},
            {
                field: "givolume",
                headerName: `${intl.formatMessage({"id": "grid-heading-volumeOfGoodsIssue"})}`,
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.givolume),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {
                field: "matshareamnt",
                headerName: `${intl.formatMessage({"id": "grid-heading-volumeOfGoodsNetAmount"})}`,
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.matshareamnt),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {
                field: "handsurchamnt",
                headerName: `${intl.formatMessage({"id": "grid-heading-amountOfHandlingSurcharge"})}`,
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.handsurchamnt),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {
                field: "totalamnt",
                headerName: `${intl.formatMessage({"id": "grid-heading-totalAmountForLineItem"})}`,
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.totalamnt),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            {field: "currency", headerName: `${intl.formatMessage({"id": "grid-heading-currency"})} *`},
            {field: "extinvoice", headerName: intl.formatMessage({"id": "grid-heading-partnerInvoiceNumber"})},
            {field: "bmwdocno", headerName: intl.formatMessage({"id": "grid-heading-bmwInvoiceNumber"})},
            {
                field: "invpostdate",
                headerName: intl.formatMessage({"id": "grid-heading-documentDate"}),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.invpostdate,getDateFormatOrDefault(user)),
                comparator: dateComparatorUsingUserPreferences(user)
            },
        ]
    }

    getShortViewColumns(): string[] {
        return [
            'compcode',
            'partnerno',
            'claimstatus',
            'ponumber',
            'overduestatus',
            'poposition',
            'materialno',
            'description',
            'supplclaimno',
            'givolume',
            'matshareamnt',
            'handsurchamnt',
            'totalamnt',
            'currency',
        ]
    }
}

export class LY5Report implements InfoPortalReport {
    private _config = new LY5AgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    performSearch(form:FormInstance,currentUser:any, distinctEntitlements:string[]):Promise<InfoPortalGridService> {
        return retrieveLY5Report(form).then(result=>
            new InfoPortalGridService(
                new ReportViewConfig('LY5',this._config), result, distinctEntitlements));
    }

    hasExpertSearch(): boolean {
        return false;
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache
                       }: InfoPortalSearchProperties): JSX.Element {
        return null;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <SupplierClaimsSimpleSearch initialization={initialization}
                                     currentUser={currentUser}
                                     intl={intl}
                                     form={form}
                                     distinctEntitlements={distinctEntitlements}
                                     rerenderDependentFields={rerenderDependentFields}
                                     initialValuesCache={initialValuesCache} reportEntitlement={this.getEntitlement()}/>;
    }

    getEntitlement(): string {
        return "display_ly@reports";
    }

    getReportName(): string {
        return "LY5";
    }
}