import React, { FunctionComponent, useEffect, useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { Col, ConfigProvider, Form,  message,  Row, Spin, Tabs, Typography } from 'antd';
import './../Welcome.css';
import './SurveyOverview.css';
import _ from 'lodash';
import { IAppComponentProps } from '../../components';
import styles from '../../styles/scss/portal.scss';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { GridApi } from 'ag-grid-community';
import {  getSurveyOverviewData, SURVEY_OVERVIEW_TABLE_HEADERS } from './SurveyUtils';
import { useIntl } from 'react-intl';
import {  setViewMode } from '../../utils';
import enUS from 'antd/lib/locale/en_US';
import { getAgGridLocalization } from '../info-portal/components/grid/GridUtil';
import {  SurveyOverviewFilter } from '__generated__/globalTypes';
import { getI18n, isExternalUser } from '../../utils/Utils';
import SurveyOverviewSearchForm from "./SurveyOverviewSearchForm";
import { SELECTED_COMPANY_CODE, SELECTED_GROUP_ID, SELECTED_SURVEYS } from "../../constants";
import { useAppDispatch, useAppSelector } from '../../main/hooks';
import { MODE } from './IStoredSurvey';
import { destroyState, resetSurveyDataLoading, setSurveyDataLoading, setSurveyMode } from './IStoredSurveySlice';
import { useNavigate } from 'react-router';


export type SelectedSurveyGroupID = {
    surveyID: number;
    groupID: string;
    partnerType: string;
    categoryCode: string;
    includedSurveyIds: string;
    surveyStatus: string;
    surveyStatusId: number;
    agreementTypeId: number;
    partnerNo: string;
    companyCode: string;
    systemCode: string;
    rowNo: number;
};

export const DESTROY_SESSION = 'DESTROY_SESSION';

const SurveyOverview: FunctionComponent<IAppComponentProps> = (props) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [gridApi, setGridApi] = useState<GridApi>(undefined);
    const { Paragraph } = Typography;

    const [surveyOverviewData, setSurveyOverviewData] = useState(undefined);

    const [selectedSurveyIds, setSelectedSurveyIds] = useState([]);
    const { TabPane } = Tabs;

    const surveyState = useAppSelector((state) => state.survey);

    
    const headerParagraphLine1 = intl.formatMessage({
        id: 'survey-overview-headerText-line1',
        defaultMessage: 'In the overview you will find the surveys of your company for the eInvoice Onboarding. ',
    });
    const headerParagraphLine2 = intl.formatMessage({
        id: 'survey-overview-headerText-line2',
        defaultMessage: "Select one or more entries and start editing by clicking on 'Start Survey'.",
    });
    const headerParagraphLine3 = intl.formatMessage({
        id: 'survey-overview-headerText-line3',
        defaultMessage:
            'If you are not the person responsible for eInvoice onboarding, please report the email address and telephone number of the correct recipient to einvoicing-platform@bmwgroup.com.',
    });
    const headerTitle = intl.formatMessage({
        id: 'survey-overview-title',
        defaultMessage: 'Survey Overview',
    });

    const selectRow = () => {
        const surveyIds: SelectedSurveyGroupID[] = [];
        const selectedRows = gridApi!.getSelectedRows();
        selectedRows.forEach((row) => {
            surveyOverviewData.forEach((item) => {
                    if (item.rowNo === row.rowNo)
                    {
                        surveyIds.push({
                            surveyID: item.surveyId,
                            groupID: item.groupId,
                            partnerType: item.categoryDescription,
                            includedSurveyIds: item.includedSurveyIds,
                            surveyStatus: item.surveyStatus,
                            surveyStatusId: item.surveyStatusId,
                            agreementTypeId: item.agreementTypeId,
                            partnerNo: item.partnerNo,
                            companyCode: item.companyCode,
                            systemCode: item.systemCode,
                            rowNo: item.rowNo,
                            categoryCode: item.categoryCode
                        });
                    }
                })
            
        });
        setSelectedSurveyIds(surveyIds);
        localStorage.setItem(SELECTED_SURVEYS, JSON.stringify(surveyIds));
        localStorage.setItem(SELECTED_COMPANY_CODE, surveyIds[0]?.companyCode);
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    };

    const onRefresh = async () => {
        //const selectedRowFromSurveyManagement = JSON.parse(window.localStorage.getItem('selectedSurveyManagement'));
        let surveyOverviewCriteria: SurveyOverviewFilter =  surveyState.survey.surveyOverviewCriteria;
        console.log(`selectedSurveyManagement : ${surveyState.survey.selectedSurveyManagement}`);
        console.log(`surveyMode : ${surveyState.survey.surveyMode}`);
        if (surveyState.survey.selectedSurveyManagement !== undefined && surveyState.survey.surveyMode === MODE.OVERVIEW)
        {
            const selectedRowFromSurveyManagement = JSON.parse(surveyState.survey.selectedSurveyManagement);
            surveyOverviewCriteria = {surveyId: Number.parseInt(selectedRowFromSurveyManagement[0].surveyId)};
        }
        //
        //setSurveyLoading(true);
        dispatch(setSurveyDataLoading(true));
        console.log("surveyOverviewCriteria");
        console.dir(surveyOverviewCriteria);
        if(surveyOverviewCriteria === undefined)
        {
            surveyOverviewCriteria = {};
        }
        Promise.resolve(getSurveyOverviewData(surveyOverviewCriteria)).then((data) => {
            //setSurveyLoading(false);
            // console.log("Survey List");
            // console.dir(data);
            setSurveyOverviewData(data);
        })
        .catch(() => {
            dispatch(resetSurveyDataLoading(true));
        })        
        .finally(() => {
            //setSurveyLoading(false);
            dispatch(setSurveyDataLoading(false));
        });
    };


    const sizes: React.CSSProperties = {
        height: '100%',
        width: '98%',
    };

    const processDoubleClick = (value) => {
        //check survey status and based on status decide what function to start
        const groupID  = value.data.groupId;
        const surveyStatusID  = value.data.surveyStatusId;
        localStorage.setItem(SELECTED_GROUP_ID, groupID);
        if ((groupID === null || groupID === undefined) && surveyStatusID === 9)
        {
            message.error(getI18n("survey-overview-double-click-cannot-edit-or-view","Cannot edit/view a Survey not created via the Survey tool", intl), 10); 
        }
        else if (surveyStatusID === 5 || surveyStatusID === 9) //Completed/Live
        {
            dispatch(setSurveyMode(MODE.VIEW));
            navigate("/survey/survey-master-data");
        }
        else if (surveyStatusID === 4) //In Progress Surveys
        {
            dispatch(setSurveyMode(MODE.EDIT));
            navigate("/survey/survey-master-data");
        }
        else
        {
            message.error(getI18n("survey-overview-double-click-cannot-start","Survey cannot be started via a double Click, Please select relevant Surveys and use the Request Survey Icon", intl), 10);
        }
    }

    useEffect(() => {
        //const fromSurveyManagement = window.localStorage.getItem('SurveyManagementView') === 'selected';
        const surveyOverviewCriteria: SurveyOverviewFilter =  surveyState.survey.surveyOverviewCriteria;
        console.log("surveyOverviewCriteria");
        console.dir(surveyOverviewCriteria);
        const fromSurveyManagement =  surveyState.survey.surveyMode === MODE.OVERVIEW
        
        if (surveyOverviewData === undefined && (isExternalUser(props.distinctEntitlements) || fromSurveyManagement || surveyOverviewCriteria !== undefined)) {
            onRefresh();
        }
        else if (surveyOverviewData === undefined)
        {
            setViewMode(false);
            setSurveyOverviewData(null);
        }

        // return () : void => {
        //     console.log("Destroy survey state");
        //     dispatch(destroyState(DESTROY_SESSION));
        // }
    }, [surveyOverviewData]);
    

    return (
        <>
            <Spin spinning={surveyState.survey.surveyDataLoading > 0} size={'large'}>
                <ConfigProvider locale={enUS}>
                    <PageContainer
                        title={false}
                        content={''}
                        extraContent={''}
                        className={styles.users}
                        style={{ background: 'white' }}
                    >
                    <div style={{ ...sizes, minHeight: '200px' }} className="pl15 ag-theme-balham">
                        <Tabs defaultActiveKey={'1'}>
                            <TabPane tab={headerTitle} key="1" id="survey-List">
                                <Typography>
                                    <Paragraph>
                                        <Row>
                                            {headerParagraphLine1}
                                            {headerParagraphLine2}
                                            {headerParagraphLine3}
                                        </Row>
                                    </Paragraph>
                                </Typography>
                                <SurveyOverviewSearchForm
                                    intl={intl}
                                    onRefresh={onRefresh}
                                    selectedSurveyIds={selectedSurveyIds}
                                    currentUser={props.currentUser}
                                    distinctEntitlements={props.distinctEntitlements}
                                    setSurveyOverviewData={setSurveyOverviewData}
                                />

                                    <div style={{ flex: 'auto', height: '500px' }}>
                                        <AgGridReact
                                          defaultColDef={{
                                                enableValue: true,
                                                sortable: true,
                                                resizable: true,
                                            }}

                                          rowData={surveyOverviewData}
                                          columnDefs={SURVEY_OVERVIEW_TABLE_HEADERS(intl, props.currentUser)}
                                          localeText={getAgGridLocalization(intl)}
                                          sideBar={false}
                                          rowClass="agGridRows"
                                          suppressMenuHide={true}
                                          debug={false}
                                          pagination={true}
                                          rowSelection={"multiple"}
                                          paginationPageSize={15}
                                          suppressRowClickSelection={false}
                                          onGridReady={onGridReady}
                                          onRowSelected={selectRow}
                                          onRowDoubleClicked={processDoubleClick}
                                        />
                                    </div>

                        </TabPane>
                        </Tabs>
                </div>
                    </PageContainer>
                </ConfigProvider>
            </Spin>
        </>
    );
};
export default SurveyOverview;
