import React, { useEffect, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { useAppDispatch } from "../../../main/hooks";
import { destroyState, setDocumentMode, updateDocumentId } from "../IStoredDocumentRecordSlice";
import { FormInstance, message, Modal, Tooltip } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  LineOutlined
} from "@ant-design/icons";
import { MODE } from "../IStoredDocumentRecord";
import { gql, useMutation } from "@apollo/client";
import { mutateDocumentRecord } from "../__generated__/mutateDocumentRecord";
import { MUTATE_ECAP_DOCUMENT_RECORD } from "../mutations";
import { ECAP_deleteDocument, ECAP_deleteDocumentVariables } from "./__generated__/ECAP_deleteDocument";
import { getDraftDocument } from "./DraftDocumentService";
import { IAppComponentProps } from "../../../components";
import { createReducer } from "@reduxjs/toolkit";
import { isDraftDocumentLoading, setTotalDraftDocument } from "../IStoredDraftDocumentLoadingSlice";
import { FormattedMessage, useIntl } from "react-intl";
import RenderIfEntitled from "../../../components/EntitlementComponents";
import { getJwtPromise } from "../../../main/OauthUtils";
import { entitlementLookup } from "../../../main/entitlementService";
import { ECAP_copyDocument, ECAP_copyDocumentVariables } from "./__generated__/ECAP_copyDocument";
import { getI18n } from "../../../utils/Utils";
import {useNavigate} from "react-router-dom";

export const DELETE_ECAP_DOCUMENT_RECORD = gql`
    mutation ECAP_deleteDocument($documentId: Float!) {
        deleteDocumentRecord(documentId: $documentId)
    }`;

export const COPY_ECAP_DOCUMENT_RECORD = gql`
    mutation ECAP_copyDocument($documentId: Float!) {
        copyDocumentRecord(documentId: $documentId)
    }`;

type RowOptionsProperties = {} & IAppComponentProps & ICellRendererParams;

const DraftDocumentRowOptions: React.FunctionComponent<RowOptionsProperties> = (props) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const [deleteDocumentRecord, {}] = useMutation<ECAP_deleteDocument, ECAP_deleteDocumentVariables>(DELETE_ECAP_DOCUMENT_RECORD);
  const [copyDocumentRecord, {}] = useMutation<ECAP_copyDocument, ECAP_copyDocumentVariables>(COPY_ECAP_DOCUMENT_RECORD);
  const [userDistinctEntitlements, setUserDistinctEntitlements] = useState<string[]>(undefined);

  const viewDocument = () => {
    dispatch(updateDocumentId(props.data.id));
    dispatch(setDocumentMode(MODE.VIEW));
    navigate("/ecap/document-processing");
  };
  const editDocument = () => {
    dispatch(updateDocumentId(props.data.id));
    dispatch(setDocumentMode(MODE.EDIT));
    navigate("/ecap/document-processing");
  };

  const deleteDocument = () => {
    dispatch(isDraftDocumentLoading(true));
    deleteDocumentRecord({ variables: { documentId: props.data.id } }).then(_ => {
      const draftListInput = JSON.parse(localStorage.getItem("draftListInput"));
      Promise.resolve(getDraftDocument(props.currentUser, draftListInput, intl))
        .then((data) => {
          props.api?.setRowData(data.DraftDocumentList);
          dispatch(setTotalDraftDocument(data?.DraftDocumentList.length));
          dispatch(isDraftDocumentLoading(false));
        })
        .catch((err) => {
          dispatch(isDraftDocumentLoading(false));
          console.error(err);
        });
    }).catch(error => {
      console.error(error);
      dispatch(isDraftDocumentLoading(false));
    });
  };


  const confirmDelete = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Delete draft document?",
      okText: "Yes",
      cancelText: "No",
      onOk: deleteDocument
    });
  };

  const copyDocument = () => {
    //call backend service to copy document
    dispatch(isDraftDocumentLoading(true));
    copyDocumentRecord({ variables: { documentId: props.data.id } }).then((data) => {
      console.log("After copy");
      console.dir(data);
      const draftListInput = JSON.parse(localStorage.getItem("draftListInput"));
      Promise.resolve(getDraftDocument(props.currentUser, draftListInput, intl))
        .then((data) => {
          props.api?.setRowData(data.DraftDocumentList);
          dispatch(setTotalDraftDocument(data?.DraftDocumentList.length));
          dispatch(isDraftDocumentLoading(false));
          message.info(getI18n("draft-document-copy-succeeded","Document copied successfully",intl));
        })
        .catch((err) => {
          dispatch(isDraftDocumentLoading(false));
          console.error(err);
          message.error(getI18n("draft-document-loading-failed","Loading of draft list failed",intl));
        });
    }).catch(error => {
      console.error(error);
      dispatch(isDraftDocumentLoading(false));
      message.error(getI18n("draft-document-copy-failed","Document copy failed",intl));
    });
  }

  const getUserDistinctEntitlements = () => {
    // console.log("Get entitlements");
    const userName = getJwtPromise()?.sub;
    entitlementLookup(userName)
      .then((data) => {
        const foundEntitlements = data?.getUserEntitlementResultByUserId?.distinctEntitlements;
        if (foundEntitlements === null || foundEntitlements === undefined || foundEntitlements.length === 0) {
          console.error("NO USER ENTITLEMENTS FOUND");
          setUserDistinctEntitlements([]);
        } else {
          setUserDistinctEntitlements(foundEntitlements);
        }
      });

  };

  useEffect(() => {
    if (userDistinctEntitlements === undefined) {
      getUserDistinctEntitlements();
    }
  }, [userDistinctEntitlements]);

  return (<div>

    <RenderIfEntitled entitlementToCheck="delete_dd@ecap" distinctEntitlements={userDistinctEntitlements} intl={intl}>
      <Tooltip title={getI18n("draft-document-row-options-delete" , "Delete", intl)}>
        <DeleteOutlined style={{ marginLeft: "5px", cursor: "pointer" }}
                        onClick={confirmDelete} colSpan={3} />

      </Tooltip>
    </RenderIfEntitled>

    <RenderIfEntitled entitlementToCheck="copy_dd@ecap" distinctEntitlements={userDistinctEntitlements} intl={intl}>
      <Tooltip title={getI18n("draft-document-row-options-copy","Copy", intl)}>
        <CopyOutlined
          style={{ marginLeft: "5px", cursor: "pointer" }}
          onClick={copyDocument} />
      </Tooltip>
    </RenderIfEntitled>

    <RenderIfEntitled entitlementToCheck="edit_dd@ecap" distinctEntitlements={userDistinctEntitlements} intl={intl}>
      <Tooltip title={getI18n("draft-document-row-options-edit", "Edit", intl)}>
        <EditOutlined
          style={{ marginLeft: "5px", cursor: "pointer" }}
          onClick={editDocument} />
      </Tooltip>
    </RenderIfEntitled>
    <Tooltip title={getI18n("draft-document-row-options-view", "View", intl)}>
      <EyeOutlined
        style={{ marginLeft: "5px", cursor: "pointer" }}
        onClick={viewDocument} />
    </Tooltip></div>);

};

export default DraftDocumentRowOptions;