import React, {FunctionComponent, useState} from "react";
import {IAppComponentProps} from "../../components";
import {Button, ConfigProvider, Select, Spin, Tabs, TabsProps, Tooltip} from "antd";
import enUS from "antd/lib/locale/en_US";
import deDe from "antd/lib/locale/de_DE";
import {getECapHelpFiles, getInfoPortalHelpFiles, getSurveyOnboardingHelpFiles} from "./HelpFileService";
import {HelpDocument} from "./HelpDocument";
import {DownloadOutlined} from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import {PageHeader} from "@ant-design/pro-layout";

type HelpScreenTab = {
    helpFiles: HelpDocument[]
} & IAppComponentProps;

function DisplayHelpScreen({helpFiles, intl}: HelpScreenTab){
    const [localHelpFiles,setHelpFiles] = useState<HelpDocument[]>(helpFiles);

    const {Option} = Select;

    const onSelectChange = (lang:string, help:HelpDocument)=> {
        help.selectedLanguage = lang;
        setHelpFiles([...localHelpFiles]);
    }

    return <div style={{margin: "15px 0px 0px 30px"}}>

        <h4> {intl.formatMessage({id: 'help-document-download-instructions'})}</h4>

        <div>
            <div style={{display: 'inline-block', width:'350px'}}>
                &nbsp;
            </div>

            <div style={{display: 'inline-block', width:'220px',color:'rgb(68,68,68)'}}>
                <h5 style={{padding:'0px', margin:'0px'}}>{intl.formatMessage({id:'help-prompt-to-select-language'})}</h5>
            </div>
        </div>

        {
            localHelpFiles.map((helpFile, index) => <div key={`help-file-${index}`}>
                <div style={{display: 'inline-block', width:'350px'}}>
                    <h5 style={{color:'rgb(68,68,68)',padding:'0px',margin:'10px 0px'}}>{`${intl.formatMessage({id: helpFile.document})}`}</h5>
                </div>
                <div style={{display: 'inline-block',width:'100px'}}>
                    <Select defaultValue={helpFile.selectedLanguage}
                            onChange={newVal => onSelectChange(newVal,helpFile)}>
                        {
                            helpFile.languages.map(lang => <Option key={`help-language-${lang}`}
                                                                   value={lang}>{lang.toUpperCase()}</Option>)
                        }

                    </Select>
                </div>

                <div style={{display: 'inline-block',width:'100px'}}>
                    <Tooltip title={`${intl.formatMessage({'id': 'info-portal-download', 'defaultMessage': "Download"})} ${helpFile.getSelectedDocument()}`}>
                        <Link
                            style={{color:'rgb(68,68,68)'}}
                            href={`./${helpFile.getSelectedDocument()}`}
                            download={helpFile.getSelectedDocument()}
                            target="_blank">
                            <Button icon={<DownloadOutlined/>} size={"middle"}>
                                {intl.formatMessage({id: "info-portal-download"}).toUpperCase()}
                            </Button>
                        </Link>
                    </Tooltip>
                </div>

            </div>)
        }

    </div>
}

const HelpFaq: FunctionComponent<IAppComponentProps> = (props) => {
    const ecapDocuments = getECapHelpFiles();
    const infoPortalDocuments = getInfoPortalHelpFiles();
    const surveyTutorialDocuments = getSurveyOnboardingHelpFiles();

    const isLoading = () => false;

    const items: TabsProps['items'] = [];

    items.push({
        key: '1',
        label: props.intl.formatMessage({id: 'welcome-onboarding-panel-title'}),
        children: DisplayHelpScreen({...props, helpFiles: surveyTutorialDocuments}),
    });

    items.push({
        key: '2',
        label: props.intl.formatMessage({id: 'welcome-document-panel-title'}),
        children: DisplayHelpScreen({...props, helpFiles: ecapDocuments}),
    });

    items.push({
        key: '3',
        label: props.intl.formatMessage({id: 'welcome-reports-panel-title'}),
        children: DisplayHelpScreen({...props, helpFiles: infoPortalDocuments}),
    });


    return (
        <>
            <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>
                <Spin size={"large"} spinning={isLoading()}>
                    <div style={{fontFamily: "BMW Group Condensed"}}>
                        <PageHeader
                            className="site-page-header"
                            style={{textTransform: "capitalize"}}
                            title={props.intl.formatMessage({id: 'mainlayout-help'})}
                        />
                        <Tabs defaultActiveKey="1" items={items}/>
                    </div>
                </Spin>
            </ConfigProvider>
        </>
    );
};
export default HelpFaq;