import {ExternalStateUpdateCallback, LabelLocation, OnChangeListener, Validator} from "../InfoPortalInterfaces";
import {DatePicker, FormInstance} from "antd";
import {IAppComponentProps} from "../../../components";
import styles from "./styling/infoportal.module.css";
import {Form_Item} from "../../e-cap/componets/FormItem-component";
import React, {useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";

type DateSelectionProperties = {
    form: FormInstance<any>,
    externalStateUpdater?: ExternalStateUpdateCallback<any>,
    onDateChange?: OnChangeListener<Dayjs>,
    validator?:Validator,
    initialValue?:Dayjs,
    dateFormatter?:string,
    labelLocation?: LabelLocation,
    displayColon?:boolean

} & IAppComponentProps;

type BaseDateSelectionProperties = {
    fieldName:string,
    heading:string,
    props: DateSelectionProperties

} & IAppComponentProps;

function BaseDateSelection({fieldName, heading,props: {
    form,
    externalStateUpdater,
    validator,
    labelLocation,
    dateFormatter,
    displayColon,
    onDateChange,
    initialValue
}}:BaseDateSelectionProperties) {
    const [_, rerender] = useState<boolean>(false);
    const [selectedDate,setSelectedDate] = useState<Dayjs>(null);
    const forceRerender = () => rerender(current => !current);

    const handleSelectedDate = (dayjs: Dayjs) => {
        setSelectedDate(dayjs);
        onDateChange?.performAction(dayjs);
    };

    const setInitialValues = ()=> {
        if (initialValue) {
            if (!form.getFieldValue(fieldName)) {
                const initialValues = {};
                initialValues[fieldName] = initialValue;
                form.setFieldsValue(initialValues);
            }
        }
    };

    useEffect(() => {
        externalStateUpdater?.register({
            performAction() {
                forceRerender();
            }
        });
        setInitialValues();
    }, [])

    const vertical = labelLocation !== LabelLocation.LEFT;

    const getDateFormat = () => dateFormatter || "YYYY-MM-DD";

    return   <div>

      {/*{vertical && <p className={`${validator?.isMandatory(fieldName) ? styles.pseudoMandatory : 'not-mandatory'} info-portal-single-selection-heading`}>{heading}{displayColon?':':''}</p>}
        {!vertical && <span style={{display:"inline-block",width:"27%"}}className={`${validator?.isMandatory(fieldName) ? styles.pseudoMandatory : 'not-mandatory'} info-portal-single-selection-heading`}>{heading}{displayColon?':':''}</span>}*/}
      {/*  <div className={vertical? '':styles.infoPortalDatePicker}>*/}
      <Form_Item label={heading} name={fieldName} itemRules={validator.fetchRule(fieldName)}>
          <DatePicker
            data-testid={`${fieldName}-info-portal-date-picker`}
            format={getDateFormat()}
            style={{ width: "100%" }}
            placeholder=""
            onChange={handleSelectedDate}
            disabledDate={(current) => current.isAfter(dayjs())}
            allowClear={true}
            inputReadOnly={true}
          />
      </Form_Item>
    </div>
  /*
      </div>*/

}

export function OpenItemsSelectionDate (props:DateSelectionProperties) {
    return <BaseDateSelection fieldName={'keyDate'}
                              heading={props.intl.formatMessage({
                                  'id': 'open-items-key-date-heading',
                                  defaultMessage: 'Open items at key date'
                              })}
                              props={props}
                              intl={props.intl} />
}