import React, { useEffect, useRef } from 'react';
import {
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    InfoPortalAgGridColumnDef,
    InfoPortalColumnDef,
    InfoPortalGridService,
    InfoPortalReport,
    InfoPortalSearchProperties,
    IValidator,
    LabelLocation,
    nonePresent,
    OnChangeListener,
    ReportViewConfig,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator,
} from '../../../InfoPortalInterfaces';
import { Card, FormInstance } from 'antd';
import { RuleSet } from '../../../../e-cap/util/RuleResultCache';
import styles from '../../styling/infoportal.module.css';
import { CompanySelection, CurrencySelection, descriptionFilter, PartnerSelection } from '../../InfoPortalDropdowns';
import { BMWInvoiceNumber, PartnerInvoiceNumber, PaymentAdviceNumber, PaymentAmount } from '../../InputWithOperator';
import { DocumentDateFromToSelection, PaymentFromToSelection } from '../../FromToDateSelection';
import { retrieveNR4Report } from '../../../services/ReportSearch';
import { IntlShape } from 'react-intl/src/types';
import { PortalUser } from '../../../../../components';
import {
    convertToUserSpecifiedNumericFormat,
    dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences,
} from '../../../../users/UserFormatPreferenceServices';

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: '',
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null,
            };
            if (fieldName === 'company' || fieldName === 'partner') {
                result.required = true;
            }
            return result;
        },
    };

    return new Validator(validator);
}

function PaymentsSimpleSearch({
                                  currentUser,
                                  intl,
                                  reportEntitlement,
                                  distinctEntitlements,
                                  initialization,
                                  form,
                                  initialValuesCache,
                                  rerenderDependentFields
                              }: InfoPortalSearchProperties) {
    const vertical = useRef<boolean>(false);

    const validator = validatator(form);
    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(companyIds: string[]) {
            const newVal = form.getFieldValue("partner") || [];
            partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
        }
    };

    useEffect(() => {
        if (rerenderDependentFields) {
            const companyIds = form.getFieldValue("company");
            const newVal = form.getFieldValue("partner");
            partnerSelectionUpdate.invokeCallBack({ companyIds, newVal });
        }
    });

    return <Card size={"small"} style={{ backgroundColor: "#f1f3f5" }}>
        <div className={styles.selfBilledSimpleSearchContainer}>
            <div className={styles.singleColumnGrid}>
                <CompanySelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    currentUser={currentUser}
                    initialValue={initialValuesCache?.getValue("company")}
                    intl={intl}
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    filterOptions={descriptionFilter}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    onSelectionChange={companySelectionChange}
                    companyEntitlement={reportEntitlement}/>
                <PartnerSelection
                    selectionMode={DropDownSelectionMode.MULTIPLE}
                    currentUser={currentUser} intl={intl}
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    filterOptions={descriptionFilter}
                    initialValue={initialValuesCache?.getValue("partner")}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form}
                    companyIds={initialValuesCache?.getValue("company")}
                    externalStateUpdater={partnerSelectionUpdate}
                    partnerEntitlement={reportEntitlement}/>
                <PaymentAmount
                    currentUser={currentUser}
                    isNumeric={true}
                    numDecimals={2}
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialInputValue={initialValuesCache?.getValue("paymentAmount")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("paymentAmountOperator", "EQ")}
                    intl={intl}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    form={form} />
                <CurrencySelection
                    initialValue={initialValuesCache?.getValue("currency")}
                    currentUser={currentUser}
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />
                <PaymentAdviceNumber
                    currentUser={currentUser}
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialInputValue={initialValuesCache?.getValue("paymentAdviceNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("paymentAdviceNumberOperator", "CP")}
                    intl={intl}
                    distinctEntitlements={distinctEntitlements}
                    initialization={initialization}
                    form={form} />
                <PaymentFromToSelection
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    initialValue={initialValuesCache?.getValue("paymentDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />
            </div>

            <div className={styles.singleColumnGrid}>
                <PartnerInvoiceNumber
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialInputValue={initialValuesCache?.getValue("partnerInvoiceNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("partnerInvoiceOperator", "CP")}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />
                <BMWInvoiceNumber
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    initialInputValue={initialValuesCache?.getValue("bmwInvoiceNumber")}
                    initialSelectedOperator={initialValuesCache?.getValueOrDefault("bmwInvoiceNumberOperator", "CP")}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />
                <DocumentDateFromToSelection
                    validator={validator}
                    labelLocation={vertical.current ? LabelLocation.TOP : LabelLocation.LEFT}
                    displayColon={!vertical}
                    dateFormatter={getDateFormatOrDefault(currentUser)}
                    initialValue={initialValuesCache?.getValue("documentDate", stringToDayjs_yyyy_MM_dd)}
                    currentUser={currentUser} intl={intl} distinctEntitlements={distinctEntitlements}
                    initialization={initialization} form={form} />
            </div>
        </div>
    </Card>;
}

export class NR4AgGridColumnDef implements InfoPortalAgGridColumnDef {
    getDefaultColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [
            { field: "compcode", headerName: intl.formatMessage({ "id": "grid-heading-company" }) },
            { field: "partnerno", headerName: intl.formatMessage({ "id": "grid-heading-partnerNumber" }) },
            { field: "paymadviceno", headerName: intl.formatMessage({ "id": "grid-heading-paymentAdviceNumber" }) },
            {
                field: "paymdate",
                headerName: intl.formatMessage({ "id": "grid-heading-paymentDate" }),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.paymdate,getDateFormatOrDefault(user)),
                comparator: dateComparatorUsingUserPreferences(user)
            },
            {
                field: "totpaymamnt",
                headerName: intl.formatMessage({ "id": "grid-heading-paymentAmount" }),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.totpaymamnt),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            { field: "currency", headerName: intl.formatMessage({ "id": "grid-heading-currency" }) },
            { field: "bankaccount", headerName: intl.formatMessage({ "id": "grid-heading-bankAccount" }) },
            { field: "bankcode", headerName: intl.formatMessage({ "id": "grid-heading-bankCode" }) },
            { field: "swift", headerName: intl.formatMessage({ "id": "grid-heading-swiftCode" }) },
            { field: "iban", headerName: intl.formatMessage({ "id": "grid-heading-iban" }) },
            { field: "contactap", headerName: intl.formatMessage({ "id": "grid-heading-contactAccountsPayable" }) }
        ];
    }

    getShortViewColumns(): string[] {
        return [];
    }

    getLineItemColumnDefinitions(intl: IntlShape,user:PortalUser): InfoPortalColumnDef[] {
        return [
            { field: "bmwdocno", headerName: intl.formatMessage({ "id": "grid-heading-bmwInvoiceNumber" }) },
            {
                field: "documentdate",
                headerName: intl.formatMessage({ "id": "grid-heading-documentDate" }),
                valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.documentdate,getDateFormatOrDefault(user)),
                comparator: dateComparatorUsingUserPreferences(user)
            },
            { field: "extinvoice", headerName: intl.formatMessage({ "id": "grid-heading-partnerInvoiceNumber" }) },
            {
                field: "invoiceamount",
                headerName: intl.formatMessage({ "id": "grid-heading-invoiceAmount" }),
                type: "rightAligned",
                valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.invoiceamount),
                comparator:numericComparatorUsingUserPreferences(user)
            },
            { field: "currency", headerName: intl.formatMessage({ "id": "grid-heading-currency" }) }
        ];
    }
}

export class NR4Report implements InfoPortalReport {
    private _config = new NR4AgGridColumnDef();

    getColumnsDefinition(): InfoPortalAgGridColumnDef {
        return this._config;
    }

    getEntitlement(): string {
        return "display_nr@reports";
    }

    getReportName(): string {
        return "NR4";
    }

    hasExpertSearch(): boolean {
        return false;
    }

    presearch(form: FormInstance<any>, intl: IntlShape): { result: "continue" | "abort-search"; description?: string, title?:string } {
        if (nonePresent(form, ["partnerInvoiceNumber", "bmwInvoiceNumber", "paymentAdviceNumber", "paymentAmount", "paymentDate"])) {
            return {
                result: "abort-search",
                description: intl.formatMessage({ id: "sr4-please-populate-fields-prompt" })
            };
        }

        return { result: "continue" };
    }

    performSearch(form: FormInstance<any>, currentUser: any, distinctEntitlements: string[]): Promise<InfoPortalGridService> {
        return retrieveNR4Report(form).then(result =>
            new InfoPortalGridService(
                new ReportViewConfig("NR4", this._config), result,distinctEntitlements));
    }

    renderExpertSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return undefined;
    }

    renderSimpleSearch({
                           currentUser,
                           intl,
                           distinctEntitlements,
                           initialization,
                           form,
                           initialValuesCache,
                           rerenderDependentFields
                       }: InfoPortalSearchProperties): JSX.Element {
        return <PaymentsSimpleSearch initialization={initialization}
                                     currentUser={currentUser}
                                     intl={intl}
                                     form={form}
                                     distinctEntitlements={distinctEntitlements}
                                     rerenderDependentFields={rerenderDependentFields}
                                     initialValuesCache={initialValuesCache}
                                     reportEntitlement={this.getEntitlement()}/>;
    }

}