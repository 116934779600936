import React, {Component, FunctionComponent, useState} from "react";
import {Col, Form, Input, Row, Typography} from "antd";
import styles from "../styling/survey.module.css";
import {useAppSelector} from "../../../main/hooks";
import {MODE} from "../IStoredSurvey";
import {Rule} from "rc-field-form/lib/interface";
import { getI18n } from "../../../utils/Utils";
import { useIntl } from "react-intl";

// interface DetailCommentHeaderParameters {
//     detail?: string;
//   }

// function Detail_Comment_Header(props: DetailCommentHeaderParameters): JSX.Element {
//     return (
//       <Row>
//         <Col span={8}>
//           <Text strong={true}>{props.detail ? props.detail : "Details"}</Text>
//         </Col>
//         <Col span={16}>
//           <Text strong={true}>Comment:</Text>
//         </Col>
//       </Row>
//     )
//   }

export function getInitialFormValues(values: any, environmentKey: string) {
    const results = {};
    values.forEach((item) => {
        const postFix = item[environmentKey] === "TEST" ? "___test___" : "";
        const keys = Object.keys(item);
        keys.forEach((key) => {
            results[`${postFix}${key}`] = item[key];
        })
    });
    return results;
}

export function cleanFormValues(values: any, environment: string) {
    if (environment === "PRODUCTION") {
        return values;//no change needed
    }
    const keys = Object.keys(values);
    const cleanValues = {}
    keys.forEach((key, index) => {
            cleanValues[key.replace('___test___', '')] = values[key]
        }
    );
    return cleanValues;
}

export interface FormItemParameters {
    label: string;
    name: string;
    required: boolean;
    requiredMessage?: string;
    maxLength?: number;
    tooltip?: string;
    type?: string;
    instruction?: String;
    isProduction: boolean;
    children?: any;
    warningOnly?: boolean;
    validationMessage?: string;
    rules?: Rule[];
}


export function Form_Row(props: FormItemParameters): JSX.Element {
    const requiredMessage = props.requiredMessage ? props.requiredMessage : `Please Provide Required Field`;
    // const thisName = props.name.replace("as2", `${props.isProduction ? 'as2' : 'as2Test'}`);
    const thisName = `${props.isProduction ? '' : '___test___'}${props.name}`;
    const valueProp = props.type === ('checkbox') ? 'checked' : 'value';
    const surveyState = useAppSelector((state) => state.survey);
    const rules = props.rules ? props.rules : [{required: props.required, message: requiredMessage}]

    const isViewMode = () => {
        return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }

    const isOverViewMode = () => {
        return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }

    const isReadOnly = () => {
        return isViewMode() || isOverViewMode();
    }

    const inputItem = props.children === undefined ?
        <Input disabled={isReadOnly()} readOnly={isReadOnly()} className="mr10"
               type={props.type ? props.type : "text"}/> : props.children;
    return (
        <tr>
            <td>
                <Form.Item
                    label={props.label}
                    tooltip={props.tooltip}
                    name={thisName}
                    rules={rules}
                    valuePropName={valueProp}
                >
                    {inputItem}
                </Form.Item>
            </td>
            <td>
                <Form.Item
                    label=""
                    name={`${thisName}Comment`}
                    rules={[{required: false}]}
                    wrapperCol={{span: 20}}
                >
                    <Input className="mr10" type="text" readOnly={isReadOnly()}/>
                </Form.Item>
            </td>
            <td style={{verticalAlign: "middle"}}>
                {props.instruction ? props.instruction : ""}
            </td>
        </tr>
    );
}

export function FormItem_VDA_Row(props: FormItemParameters): JSX.Element {
    const intl = useIntl();
    const requiredMessage = props.requiredMessage ? props.requiredMessage : `Please Provide Required Field`;
    const maxLength = {max: props.maxLength, message: getI18n("survey-connectivityInfo-smtp-max-length-instruction", "Only 100 characters are allowed for this field", intl)};
    const thisName = `${props.isProduction ? "" : "___test___"}${props.name}`;
    const valueProp = props.type === ("checkbox") ? "checked" : "value";

    const surveyState = useAppSelector((state) => state.survey);

    const isViewMode = () => {
        return surveyState.survey.surveyMode === MODE.VIEW ? true : false
    }

    const isOverViewMode = () => {
        return surveyState.survey.surveyMode === MODE.OVERVIEW ? true : false
    }

    const isReadOnly = () => {
        return isViewMode() || isOverViewMode();
    }

    const inputItem = props.children === undefined ?
        <Input disabled={isReadOnly()} readOnly={isReadOnly()} className="mr10"
               type={props.type ? props.type : "text"}/> : props.children;

    return (
        <tr>
            <td>
                <span className={props.required ? styles.formItemVDAMandatory : ''}>{props.label}</span>
            </td>
            <td>
                <Form.Item
                    tooltip={props.tooltip}
                    name={thisName}
                    rules={[{required: props.required, message: requiredMessage}, props.maxLength ? maxLength : null ]}
                    valuePropName={valueProp}
                >
                    {inputItem}
                </Form.Item>
            </td>
            <td>
                <Form.Item
                    label=""
                    name={`${thisName}Comment`}
                    rules={[{required: false}]}
                    wrapperCol={{span: 20}}
                >
                    <Input className="mr10" type="text" readOnly={isReadOnly()}/>
                </Form.Item>
            </td>
            <td style={{verticalAlign: "middle"}}>
                {props.instruction ? props.instruction : ""}
            </td>
        </tr>
    );
}