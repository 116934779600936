import React, {FunctionComponent, useEffect, useMemo, useRef, useState} from "react";
import {Button, Collapse, ConfigProvider, Form, FormInstance, Modal, Spin, Tooltip, Typography} from "antd";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import enUS from "antd/lib/locale/en_US";
import {IAppComponentProps, PortalUser} from "../../../components";
import {AgGridReact} from "ag-grid-react";
import {GridApi} from "ag-grid-community";
import deDe from "antd/lib/locale/de_DE";
import {
    getAgGridLocalization,
    injectPageResizeIntoAgGrid,
    processCellForClipboard
} from "../../info-portal/components/grid/GridUtil";
import styles from './styling/po-flip.module.css'
import {
    CompanySelection,
    descriptionFilter,
    InvoiceStatusSelection,
    PartnerSelection
} from "../../info-portal/components/InfoPortalDropdowns";
import {
    cacheFieldValues,
    dayjsToString_yyyy_MM_dd,
    DropDownSelectionMode,
    ExternalStateUpdateCallback,
    FieldValueCache,
    Initialization,
    IValidator,
    OnChangeListener,
    readCache,
    stringToDayjs_yyyy_MM_dd,
    utcDateToFormatTo_yyyy_MM_dd_string,
    Validator
} from "../../info-portal/InfoPortalInterfaces";
import {RuleSet} from "../util/RuleResultCache";
import {v4 as uuidv4} from 'uuid';
import {getI18n} from "../../../utils/Utils";
import {PurchaseOrderFromToSelection} from "../../info-portal/components/FromToDateSelection";
import {
    convertToUserSpecifiedNumericFormat, dateComparatorUsingUserPreferences,
    getDateFormatOrDefault,
    numericComparatorUsingUserPreferences
} from "../../users/UserFormatPreferenceServices";
import {IntlShape} from "react-intl/src/types";
import {retrieveSR1ReportForPOFlip} from "../../info-portal/services/ReportSearch";
import {ColDef, ColGroupDef} from "ag-grid-community/dist/lib/entities/colDef";
import {
    getProviderDocumentType_getProviderDocumentType as DocumentType,
} from "../../documents/__generated__/getProviderDocumentType";
import {
    DocumentRecordInput,
    ECAPDocumentInput,
    LineItemsInput,
    ShippingInformationInput
} from "../../../../__generated__/globalTypes";
import {
    allCompanyInfoPerCategory,
    getCompanyAddressDetails,
    getPartnerAddressDetails
} from "../services/PartnerService";
import {
    InfoPortalSR1QueryApi_findSR1ReportResults as PurchaseOrderHeaderRecord,
    InfoPortalSR1QueryApi_findSR1ReportResults_positions
} from "../../info-portal/graphql/__generated__/InfoPortalSR1QueryApi";
import {
    getCompanyInfoByCompanyPartnerNoCategory_getCompanyInfoByCompanyPartnerNoCategory as CompanyInfo
} from "../__generated__/getCompanyInfoByCompanyPartnerNoCategory";
import dayjs from 'dayjs';
import {getCurrencyListData} from "../services/SelectServices";
import {getCurrencyList_getCurrencyList} from "../__generated__/getCurrencyList";
import {getPartnerAddressByPartnerId_getPartnerAddressByPartnerId} from "../__generated__/getPartnerAddressByPartnerId";
import {
    getCompanyAddressDetailsByCompanyId_getCompanyAddressDetailsByCompanyId
} from "../__generated__/getCompanyAddressDetailsByCompanyId";

import {useAppDispatch} from "../../../main/hooks";
import {setDocumentMode, updateDocumentId} from "../IStoredDocumentRecordSlice";
import {MODE} from "../IStoredDocumentRecord";
import {PurchaseOrderNumber} from "../../info-portal/components/InputWithOperator";
import {EditOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import { retrieveDocumentTypes } from '../../info-portal/services/Selectors';
import { createDraftDocument } from '../services/DocumentRecordService';

function validatator(form: FormInstance<any>): Validator {
    const validator: IValidator = {
        fetchRule(fieldName: string): RuleSet {
            const result = {
                required: false,
                picture: "",
                readOnly: false,
                hidden: false,
                formatMessage: null,
                infoMessage: null
            };
            if (fieldName === "company") {
                result.required = true;
            }
            if (fieldName === "invoicee") {
                result.required = true;
            }
            if (fieldName === "purchaseOrderDate") {
                result.required = true;
            }

            return result;
        }
    };
    return new Validator(validator);
}

function getDefaultColumnDefinitions(intl: IntlShape, user: PortalUser): (ColDef<any> | ColGroupDef<any>)[] {
    const modifyArrayWithTooltip = (colDistList: (ColDef<any> | ColGroupDef<any>)[]) => {
        colDistList.forEach(item => {
            item['headerTooltip'] = item['headerName'];
        })
        return colDistList;
    }


    return modifyArrayWithTooltip([
        {
            field: "firstColumn",
            headerName: " ",
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            headerCheckboxSelection: false,
            lockVisible: true,
            lockPosition: true,
            // @ts-ignore
            width:"40px",
            suppressMovable: true
        },
        {
            field: "numLineItems",
            headerName: intl.formatMessage({"id": "line-item-count"}),
            cellRenderer: "agGroupCellRenderer",
        },
        {
            field: "compname",
            headerName: intl.formatMessage({"id": "grid-heading-company"}),
        },
        {field: "invoicee", headerName: intl.formatMessage({"id": "grid-heading-invoicingParty"})},
        {field: "ponumber",headerName: intl.formatMessage({"id": "grid-heading-purchaseOrderNumber"})},
        {
            field: "podate",
            headerName: intl.formatMessage({"id": "grid-heading-purchaseOrderDate"}),
            valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.podate, getDateFormatOrDefault(user)),
            comparator: dateComparatorUsingUserPreferences(user)
        },
        {field: "podlvstattxt", headerName: intl.formatMessage({"id": "grid-heading-deliveryStatus"})},
        {field: "invstattxt", headerName: intl.formatMessage({"id": "grid-heading-invoiceStatusPurchaseOrder"})},
        {
            field: "povalue",
            headerName: intl.formatMessage({"id": "grid-heading-purchaseOrderValue"}),
            type: "rightAligned",
            valueGetter: params => convertToUserSpecifiedNumericFormat(user, params.data.povalue),
            comparator:numericComparatorUsingUserPreferences(user)
        },

        {field: "currency", headerName: intl.formatMessage({"id": "grid-heading-currency"})},
        { field: "contactponame", headerName: intl.formatMessage({ "id": "grid-heading-purchasingname" }) },
        { field: "contactpoemail", headerName: intl.formatMessage({ "id": "grid-heading-purchasingemail" }) },

    ]);
}

function getLineItemColumnDefinitions(intl: IntlShape, user: PortalUser): (ColDef<any> | ColGroupDef<any>)[] {
    return [
        {
            field: "poItem",
            headerName: intl.formatMessage({
                "id": "grid-heading-purchaseOrderItem",
                defaultMessage: "Purchase Order Item"
            })
        },
        {
            field: "material",
            headerName: intl.formatMessage({"id": "grid-heading-material", defaultMessage: "Material Number"})
        },
        {
            field: "materialText",
            headerName: intl.formatMessage({"id": "grid-heading-materialText", defaultMessage: "Description"})
        },
        {
            field: "quantity",
            headerName: intl.formatMessage({"id": "grid-heading-po-flip-quantity", defaultMessage: "Quantity"}),
            valueGetter: params => convertToUserSpecifiedNumericFormat(user,params.data.quantity, 3),
            comparator:numericComparatorUsingUserPreferences(user)
        },
        {
            field: "pounit",
            headerName: intl.formatMessage({"id": "grid-heading-pounit", defaultMessage: "Unit of Measure"})
        },
        {
            field: "invoicedValue",
            type: "rightAligned",
            headerName: intl.formatMessage({"id": "grid-heading-po-flip-invoicedvalue", defaultMessage: "Invoiced Quantity"}),
            valueGetter: params => convertToUserSpecifiedNumericFormat(user, params.data.invoicedValue),
            comparator:numericComparatorUsingUserPreferences(user)
        },
        {
            field: "openValue",
            type: "rightAligned",
            headerName: intl.formatMessage({"id": "grid-heading-po-flip-openvalue", defaultMessage: "Open Quantity"}),
            valueGetter: params => convertToUserSpecifiedNumericFormat(user, params.data.openValue),
            comparator:numericComparatorUsingUserPreferences(user)
        },
        {
            field: "plantName",
            headerName: intl.formatMessage({"id": "grid-heading-plantName", defaultMessage: "Plant"})
        },
        {
            field: "busArea",
            headerName: intl.formatMessage({"id": "grid-heading-busArea", defaultMessage: "Business Area"})
        },
        {
            field: "changedate",
            headerName: intl.formatMessage({"id": "grid-heading-changedate", defaultMessage: "Change Date"}),
            valueGetter: params => utcDateToFormatTo_yyyy_MM_dd_string(params.data.changedate, getDateFormatOrDefault(user)),
            comparator: dateComparatorUsingUserPreferences(user)
        },
        {
            field: "contactGrName",
            headerName: intl.formatMessage({
                "id": "grid-heading-contactGrName",
                defaultMessage: "Recipient of goods/services (name)"
            })
        },
        {
            field: "contactGrEmail",
            headerName: intl.formatMessage({
                "id": "grid-heading-contactGrEmail",
                defaultMessage: "Recipient of goods/services (email)"
            })
        },
        {
            field: "deliveryaddress",
            headerName: intl.formatMessage({"id": "grid-heading-deliveryaddress", defaultMessage: "Delivery Address"})
        }
    ];
}

export function createDetailCellRenderer(
    intl: IntlShape,
    lineColumnDefinitions: (ColDef<any>[] | ColGroupDef<any>[]),
    lineItemPageSize: React.MutableRefObject<number>) {

    const DetailCellRenderer = ({data, node, api}) => {
        const [lineItems, _] = useState<any[]>(data.positions);

        const rowId = node.id;
        const colDefs = () => [
            {
                headerName: intl.formatMessage({id: "e-cap-capture-document-lines-items-panel-title"}),
                children: lineColumnDefinitions.map(colDef => {
                    return {...colDef}
                })
            }
        ];

        const onGridReady = (params) => {
            const gridInfo = {
                id: node.id,
                api: params.api,
                columnApi: params.columnApi
            };

            api?.addDetailGridInfo(rowId, gridInfo);

            injectPageResizeIntoAgGrid(
                params,
                intl, {
                    selectedSize: lineItemPageSize.current,
                    uniqueIdentifier:`edp-page-size-component-${rowId}`,
                    emitPageSize:(arg:number)=>lineItemPageSize.current = arg
                })
        };

        const isGridVisible = () => lineItems && lineItems.length > 0;

        return (
            <div data-testid={`line-item-${rowId}`}>
                <div hidden={isGridVisible()} style={{width: "100%"}}>
                    <p style={{marginTop: 20, marginLeft: "30px"}}>{intl.formatMessage({id: "no-line-items"})}</p>
                </div>
                <div hidden={!isGridVisible()}
                     style={{height: isGridVisible() ? 300 : 50, padding: 20, marginBottom: 30}}>
                    <AgGridReact
                        className={`edp-page-size-component-${rowId}`}
                        defaultColDef={{
                            enableValue: true,
                            sortable: true,
                            resizable: true,
                            autoHeight: true,
                            filter:true
                        }}
                        columnDefs={colDefs()}
                        processCellForClipboard={processCellForClipboard}
                        rowData={lineItems}
                        localeText={getAgGridLocalization(intl)}
                        onGridReady={onGridReady}
                        pagination={true}
                        paginationPageSize={20}
                    />
                </div>
            </div>
        );
    };

    return DetailCellRenderer;
}
const mapSelectedRecordToCreateDraftDocumentPayload = async (intl: IntlShape, user: PortalUser, documentTypes: DocumentType[], selectedRow: PurchaseOrderHeaderRecord) => {

    let companyInfo: CompanyInfo = null;
    let currencyList: getCurrencyList_getCurrencyList[] = null;
    let supplierAddress: getPartnerAddressByPartnerId_getPartnerAddressByPartnerId = null;
    let companyAddress: getCompanyAddressDetailsByCompanyId_getCompanyAddressDetailsByCompanyId = null;


    function getCompanyID(busArea:string) {
        return busArea.split('-')[0].trim();
    }

    await Promise.all(
        [
            allCompanyInfoPerCategory(getCompanyID(selectedRow.compname), selectedRow.partnerlf?.replace(/^0+/g,''), 'V'),
            getCurrencyListData()])
            .then(results => {
                companyInfo = results[0].getCompanyInfoByCompanyPartnerNoCategory;
                currencyList = results[1].getCurrencyList;
            });

    await Promise.all(
        [
            getPartnerAddressDetails(companyInfo.partnerId, 1, 50),
            getCompanyAddressDetails(companyInfo.companyId, 'P')])
            .then(results => {
                supplierAddress = results[0]?.getPartnerAddressByPartnerId?.length ? results[0].getPartnerAddressByPartnerId[0] : null;
                companyAddress = results[1].getCompanyAddressDetailsByCompanyId?.length ? results[1].getCompanyAddressDetailsByCompanyId[0] : null;
            });

    const selectedCurrency =
        currencyList.find(item => item.id === selectedRow.currency);

    const formDocument: ECAPDocumentInput = {};

    function getDocumentTypeId() {
        return Number(documentTypes.find(docType => docType.documentType.toLowerCase() === 'invoice').id);
    }

    function calculateAmount(netValue: string, quantity: string) {
        if (netValue && quantity) {
            const quantityValue = Number(quantity.trim());
            if (quantityValue) {
                return Number(netValue) * quantityValue;
            }
        }
        return 0;
    }

    function getLineItems() {
        return selectedRow.positions?.map(position => {
            const lineItem: LineItemsInput = {
                bmwPartnerNumber: position.material || '0000000',
                orderNumber: position.poNumber || selectedRow.ponumber,
                itemDescription: position.materialText,
                unitOfMeasureFreeText: position.pounit,
                quantity: 0,
                unitPrice: 0,
                grossAmount:0,
                netAmount:0,
                unitQuantity:1,
                taxRate:0,
                taxAmount:0,
            };
            return lineItem;
        }) || [];
    }

    function getDistinctValues(arr: any[], valueExtractor: (arrItem: any) => any) {
        const theSet = new Set<any>();
        arr.forEach(item => {
            if (item) {
                const value = valueExtractor(item);
                if (value) {
                    theSet.add(value);
                }
            }

        })
        return Array.from(theSet);
    }

    const lineItems = getLineItems();

    function getHeaderOrderNumber() {
        const distinctOrderNumbers = getDistinctValues(lineItems, (item) => item['orderNumber']);
        if (distinctOrderNumbers.length === 1) {
            return distinctOrderNumbers[0];
        }
        return 'NA';
    }

    function createShippingInformation() {

        function toShippingAddress(item:InfoPortalSR1QueryApi_findSR1ReportResults_positions): ShippingInformationInput {
            if (item.deliveryaddress) {
                const addressComponents = item.deliveryaddress.split(',');
                if (addressComponents.length===5 &&
                    !addressComponents.map(item=>item || '')
                        .map(item=>item.trim()).every(item=>item.length===0)) {
                    return {
                        shipToStreet:addressComponents[0],
                        shipToCity:addressComponents[1],
                        shipToPostalCode:addressComponents[2],
                        shipToRegionCode:addressComponents[3],
                        shipToCountryCode:addressComponents[4]
                    };
                }
            }

            return null;
        }

        const foundShippingAddress =
            selectedRow.positions?.map(toShippingAddress).find(item=>item!=null);
        if (foundShippingAddress) {
            return foundShippingAddress;
        }

        return null;
    }

    formDocument.documentTypeId = getDocumentTypeId();
    formDocument.companyPartnerId = companyInfo.companyPartnerId;
    formDocument.bmwContactEmail = selectedRow.contactpoemail;
    formDocument.bmwContactName = selectedRow.contactponame?.replace(/\d+/,'').trim().toUpperCase();
    formDocument.documentNumber = '';
    formDocument.companyCode = companyInfo.companyCode;
    formDocument.documentStatusId = 1;
    formDocument.grossAmount = 0;
    formDocument.netAmount =  0;
    formDocument.taxableAmount = 0;
    formDocument.taxAmount = 0;
    formDocument.exchangeRate = 1;
    formDocument.exchangeRateDate = null;
    formDocument.localCurrencyNetAmount = 0;
    formDocument.localCurrencyTaxableAmount = 0;
    formDocument.localCurrencyGrossAmount = 0;
    formDocument.localCurrencyTaxAmount = 0;
    formDocument.deliveryDate = null;
    formDocument.currencyCodeId = selectedCurrency?.id;
    formDocument.orderNumber = getHeaderOrderNumber();
    formDocument.paymentTerms = intl.formatMessage({id: 'ecap-header-notes-payment-terms-default-text'});
    formDocument.bmwIsRetailOutlet = false;
    formDocument.bmwGroupPaymentRequest = false;
    formDocument.documentDate = dayjs().format('YYYY-MM-DD');

    formDocument.bmwCompanyCity = companyAddress?.city;
    formDocument.bmwCompanyCountryCode = companyAddress?.countryCode;
    formDocument.bmwCompanyPostalCode = companyAddress?.postalCode;
    formDocument.bmwCompanyStreet1 = companyAddress?.street1;
    formDocument.bmwCompanyStreet2 = companyAddress?.street2;

    formDocument.bmwCompanyTaxId = ""

    const newDocumentRecord: DocumentRecordInput = {
        document: {...formDocument},
        lineItems,
        shippingInformation: createShippingInformation(),
        supplierDetails: {
            contactName: '',
            contactEmail: '',
            contactDepartment: '',
            companyCode: companyInfo.companyCode,
            partnerNo:companyInfo.partnerNo,
            city: supplierAddress.city,
            countryCode: supplierAddress.countryCode,
            postalCode: supplierAddress.postalCode,
            regionCode: supplierAddress.regionCode,
            street1: supplierAddress.street1,
            street2: supplierAddress.street2,
            companyName: companyInfo.companyName,
            partnerName: companyInfo.partnerName,
            systemCode: companyInfo.systemCode
        }
    };

    return newDocumentRecord;
};

type POFlipParameters = {
    registerFormInstance?: (formInstance:FormInstance<any>)=>void,
    registerAGGridAPI?:(gridAPI:GridApi)=>void
    } &
    IAppComponentProps;

const POFlipDisplay: FunctionComponent<POFlipParameters> = (props) => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const validator = validatator(form);
    const [docGridApi, setDocGridApi] = useState<GridApi>(undefined);
    const [purchaseOrderInvoices, setPurchaseOrderInvoices] = useState([]);
    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
    const [initialValuesCache, setInitialValuesCache] = useState<FieldValueCache>(null);
    const [initState, setState] = useState<any>({});
    const lineItemPageSize = useRef<number>(50);

    const onDocGridReady = (params) => {
        setDocGridApi(params.api);

        if (props.registerAGGridAPI) {
            props.registerAGGridAPI(params.api);
        }

        injectPageResizeIntoAgGrid(params, props.intl)
    };


    const partnerSelectionUpdate = new ExternalStateUpdateCallback<{ companyIds: string[], newVal: string[] }>();

    const companySelectionChange: OnChangeListener<string[]> = {
        performAction(val: string[]) {
            const thePartner = form.getFieldValue("partner");
            const newVal = ((typeof thePartner === "string") ? [thePartner] : thePartner) || [];
            partnerSelectionUpdate.invokeCallBack({companyIds: val, newVal});
        }
    };

    const isLoading = () => Object.keys(initState).map(key => initState[key] === true).reduce((prev, curr) => prev || curr, false);

    const updateInitState =
        (property: string, state: boolean) => setState(currState => {
            const newState = {...currState};
            newState[property] = state;
            return newState;
        });

    const stateInit: Initialization = {
        beginInitialize(): string {
            const randomProp = uuidv4();
            updateInitState(randomProp, true);
            return randomProp;
        }, finishInitialize(initializationId: string): void {
            updateInitState(initializationId, false);
        }
    };

    const poFlipFormFieldValueToCacheObjectValueMapper = () => {
        const map = new Map<string, (input: any) => any>();
        map.set('purchaseOrderDate', dayjsToString_yyyy_MM_dd);
        return map;
    }

    const onFinish = () => {
        const id = stateInit.beginInitialize();

        //clear the grid when searching
        if (purchaseOrderInvoices?.length) {
            docGridApi.setRowData([]);
        }

        retrieveSR1ReportForPOFlip(form).then(result => {
            setPurchaseOrderInvoices(result.map(item=> {
                const newItem = {...item,numLineItems:item.positions?.length || 0}
                return newItem;
            }));
            if (result.length) {
                cacheFieldValues(form, "PO_FLIP", false, 'SR100', poFlipFormFieldValueToCacheObjectValueMapper());
                if (result.length>=5000) {
                    Modal.warning({
                        title: props.intl.formatMessage({id: "Download Warning",}),
                        content: props.intl.formatMessage({id: "info-portal-maximum-results-message",})
                    });
                }
            }
            else {
                Modal.warning({
                    title: props.intl.formatMessage({id: "no-results-found",}),
                    content: props.intl.formatMessage({id: "info-portal-no-results",})
                });
            }

        }).finally(() => {
            stateInit.finishInitialize(id);
        })
    }

    const createDocument = async () => {
        const selectedRows = docGridApi.getSelectedRows();
        if (!selectedRows?.length) {
            Modal.warning({
                title: props.intl.formatMessage({
                    id: "create-ecap-document",
                }),
                content: props.intl.formatMessage({
                    id: "select-po-reminder",
                    defaultMessage: ""
                })
            });
            return Promise.resolve(-1);
        }

        console.log('selected rows', selectedRows)
       const payload = await mapSelectedRecordToCreateDraftDocumentPayload(props.intl, props.currentUser, documentTypes, selectedRows[0]);
       console.log('Try to create record', payload);
        return createDraftDocument(payload).then(response=> {
           console.log('created document', response.document);
           return response.document.id
       });
    }

    const createDocumentHandler = () => {
        const id = stateInit.beginInitialize();
        function redirectToDraftDocumentInEditMode(result: number) {
            dispatch(updateDocumentId(result));
            dispatch(setDocumentMode(MODE.EDIT));
            navigate("/ecap/document-processing");
        }

        createDocument().then(result => {
            if (result !== -1) {
                redirectToDraftDocumentInEditMode(result);
            }
        }).catch(e=> {
            Modal.error({
                title: props.intl.formatMessage({id: "Download Error"}),
                content: props.intl.formatMessage({id:'draft-document-creation-error'})
            })
            console.error(e);
        }).finally(() => {
            stateInit.finishInitialize(id);
        })
    }

    const resetFields = ()=> {
        form.resetFields();
    }

    const detailCellRenderer = useMemo(() => {
        return createDetailCellRenderer(
            props.intl,
            getLineItemColumnDefinitions(props.intl, props.currentUser),
            lineItemPageSize)
    }, []);

    function loadDocumentTypes() {
        const id = stateInit.beginInitialize();
        retrieveDocumentTypes().then(result => {
            setDocumentTypes(result.data.getProviderDocumentType)
        }).finally(() => {
            stateInit.finishInitialize(id);
        })
    }

    useEffect(() => {

        if (props.registerFormInstance) {
            props.registerFormInstance(form);
        }

        loadDocumentTypes();
        const fieldValueCache = readCache('PO_FLIP', false, 'SR100');
        setInitialValuesCache(fieldValueCache);
    }, []);

    return (
        initialValuesCache &&
        <>
            <ConfigProvider locale={localStorage.getItem("locale") === "en" ? enUS : deDe}>

                <div className={styles.poFlipHeading}>
                    <Collapse items={[
                        {
                            key: '1',
                            label: props.intl.formatMessage({id: 'purchase-order-flip'}),
                            children: getI18n("purchase-order-flip-message", "", props.intl).split('\\n')
                                .map((line, idx) => <Typography.Title level={5} key={`paragraph-idx-${idx}`}>
                                    {line}
                                </Typography.Title>)
                        }
                    ]}/>
                </div>

                <Spin spinning={isLoading()} size={"large"} data-testid={isLoading()? 'poflip-busy-loading':'poflip-finished-loading'}>
                    <div style={{marginLeft: "2%"}}>
                        <Form
                            size={"middle"}
                            form={form}
                            labelWrap={true}
                            labelAlign={"right"}
                            layout={'vertical'}
                            onFinish={onFinish}>
                            <div className={styles.searchContainer}>
                                <div>
                                    <CompanySelection
                                        selectionMode={DropDownSelectionMode.SINGLE}
                                        currentUser={props.currentUser}
                                        intl={props.intl}
                                        initialValue={initialValuesCache?.getValue("company")}
                                        validator={validator}
                                        distinctEntitlements={props.distinctEntitlements}
                                        filterOptions={descriptionFilter}
                                        initialization={stateInit} form={form}
                                        onSelectionChange={companySelectionChange}
                                        customFilter={item => ['MU','UH'].find(companyCode=>companyCode === item.companyCode)!==undefined}
                                        companyEntitlement={"create_dp@ecap"}/>
                                    <PartnerSelection
                                        selectionMode={DropDownSelectionMode.SINGLE}
                                        currentUser={props.currentUser}
                                        intl={props.intl}
                                        initialValue={initialValuesCache?.getValue("invoicee")}
                                        customLabel={props.intl.formatMessage({id: 'partner-number-invoicing-party'})}
                                        customFormFieldName={'invoicee'}
                                        partnerEntitlement={"create_dp@ecap"}
                                        validator={validator}
                                        distinctEntitlements={props.distinctEntitlements}
                                        initialization={stateInit} form={form}
                                        filterOptions={descriptionFilter}
                                        customFilter={partner => {
                                            return partner?.companies.find(company=>['MU','RK','UH'].find(companyCode=>companyCode === company.companyCode)!==undefined)!==undefined
                                        }}
                                        externalStateUpdater={partnerSelectionUpdate}
                                        companyIds={initialValuesCache?.getValue("company")}/>

                                    <InvoiceStatusSelection
                                        currentUser={props.currentUser}
                                        selectionMode={DropDownSelectionMode.MULTIPLE}
                                        intl={props.intl}
                                        initialValue={initialValuesCache?.getValue("invoiceStatus")}
                                        validator={validator}
                                        distinctEntitlements={props.distinctEntitlements}
                                        initialization={stateInit}
                                        isPOFlip={true}
                                        customLabel={props.intl.formatMessage({id: 'select-invoice-status-orders'})}
                                        customDescription={(item) => `${item.id} - ${item.description}`}
                                        sortByDescription={true}
                                        form={form}
                                        reportName={"sr1"}/>

                                </div>

                                <div>
                                    <PurchaseOrderFromToSelection
                                        validator={validator}
                                        dateFormatter={getDateFormatOrDefault(props.currentUser)}
                                        initialValue={initialValuesCache?.getValue("purchaseOrderDate", stringToDayjs_yyyy_MM_dd)}
                                        currentUser={props.currentUser}
                                        intl={props.intl} distinctEntitlements={props.distinctEntitlements}
                                        initialization={stateInit}
                                        form={form}/>

                                    <PurchaseOrderNumber
                                        currentUser={props.currentUser} intl={props.intl} distinctEntitlements={props.distinctEntitlements}
                                        initialInputValue={initialValuesCache?.getValue("purchaseOrderNumber")}
                                        initialSelectedOperator={'CP'}
                                        initialization={stateInit} form={form} />
                                </div>
                            </div>

                            <Tooltip title={props.intl.formatMessage({
                                'id': 'info-portal-search',
                                'defaultMessage': "Search"
                            })}>
                                <Button
                                    type={'primary'}
                                    htmlType="submit"
                                    size="large"
                                    icon={<SearchOutlined/>}
                                    className="w-100 mt10"
                                    data-testid={'btn-search'} />
                            </Tooltip>

                            <Tooltip title={props.intl.formatMessage({'id': 'do-clear', 'defaultMessage': "Clear"})}>
                                <Button
                                    data-testid="info-portal-clear"
                                    type="primary"
                                    style={{marginLeft: "10px"}}
                                    icon={<ReloadOutlined/>}
                                    onClick={(e) => resetFields()}
                                    size={'large'}/>
                            </Tooltip>

                            <Tooltip title={props.intl.formatMessage({'id': 'create-ecap-document', 'defaultMessage': ""})}>
                                <Button
                                    type={'primary'}
                                    onClick={_ => createDocumentHandler()}
                                    size="large"
                                    style={{marginLeft: "10px", width: "40px"}}
                                    icon={<EditOutlined/>}
                                    className="w-100 mt10"
                                    data-testid={'btn-create-document'}/>
                            </Tooltip>
                        </Form>
                    </div>
                </Spin>

                <div className={"pl15 ag-theme-balham"}
                     style={{flex: "auto", height: "450px", padding: "20px", marginBottom: "30px"}}>
                    <AgGridReact
                        className={'edp-page-size-component'}
                        defaultColDef={{
                            enableValue: true,
                            sortable: true,
                            resizable: true,
                            autoHeight: true,
                            filter:true,
                            headerCheckboxSelection: false,
                        }}
                        masterDetail={true}
                        processCellForClipboard={processCellForClipboard}
                        detailCellRenderer={detailCellRenderer}
                        rowSelection={"single"}
                        api={docGridApi}
                        columnDefs={getDefaultColumnDefinitions(props.intl, props.currentUser)}
                        rowData={purchaseOrderInvoices}
                        sideBar={false}
                        localeText={getAgGridLocalization(props.intl)}
                        suppressMenuHide={true}
                        onGridSizeChanged={() => docGridApi?.sizeColumnsToFit()}
                        pagination={true}
                        onGridReady={onDocGridReady}
                        paginationPageSize={15}
                        suppressRowClickSelection={true}
                        enableBrowserTooltips={true}
                        data-testid="poflip-grid-id"/>
                </div>
            </ConfigProvider>
        </>
    );
};
export default POFlipDisplay;
