import {Card, Checkbox, Col, Collapse, Form, FormInstance, Input, Row} from "antd";
import {IAppComponentProps} from "../../../components";
import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {getI18n} from "../../../utils/Utils";
import {ECAPAddressInfo} from "../componets/ECAP-Address-component";
import {Form_Item} from "../componets/FormItem-component";
import {getControlRuleset, getDefaultRequiredRuleset} from "../RulesEngine";
import {CaretRightOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../../main/hooks";
import {
    updateCollapsedState,
    updateShippingToCountryCode,
    updateShippingToRegionCode
} from "../IStoredDocumentRecordSlice";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {populateShippingInformationDetails} from "../view-document-helper";

type ShippingInfoDetailsProps = {
    form: FormInstance<any>;
} & IAppComponentProps;


const ShippingInfoDetails: React.FunctionComponent<ShippingInfoDetailsProps> = (props) => {
    const intl = useIntl();
    const {Panel} = Collapse;
    const dispatch = useAppDispatch();
    const {document} = useAppSelector((state) => state.document);
    const rules = useAppSelector((state) => state.rules);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        console.log("document: ",document);
        const fields = props.form.getFieldsValue();
        console.log("fields: ",fields);
        const {shippingInformation} = fields;
        console.log("shippingInfo: ", shippingInformation);
        console.dir(shippingInformation);
        const isBmwAddress = document?.bmwCompanyStreet1 === shippingInformation?.shipToStreet && document.bmwCompanyPostalCode === shippingInformation.shipToPostalCode &&
            document.bmwCompanyCity === shippingInformation.shipToCity;
        console.log("shippingInfo: isBMWAddress ", isBmwAddress);
        if (document.documentId !== null) {
            setIsChecked(isBmwAddress);
        }
    },[document.documentId,document.bmwCompanyStreet1, document.bmwCompanyPostalCode, document.bmwCompanyCity]);

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0
            },
            sm: {
                span: 16,
                offset: 8
            }
        }
    };

    const setBMWHeadOfficeAddress = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {

            const fields = props.form.getFieldsValue();
            const {shippingInformation, companyStreet1, companyPostalCode, companyCountry, companyCity} = fields;
            const newShippingInformation = {
                ...shippingInformation,
                shipToStreet: companyStreet1, shipToCountry: companyCountry,
                shipToCity: companyCity, shipToPostalCode: companyPostalCode
            };
            props.form.setFieldsValue({shippingInformation: newShippingInformation});
            //console.log(`Company Country Code: ${document.companyCountryCode}`);
            dispatch(updateShippingToCountryCode(document.companyCountryCode));
        }
        setIsChecked(!isChecked);
    };


    const getOrderNumber = () => {
        return document.orderNumber === "N/A";
    };

    return (
        <>
            <Collapse
                defaultActiveKey={2}
                className={"ml-10"}
                expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                activeKey={document.collapsedState}
                onChange={(newActiveKeys) => {
                    // otherwise panels couldn't be open/closed by click
                    dispatch(updateCollapsedState(newActiveKeys));
                }}>
                <Panel key={4.4}
                       header={getI18n("shippingInfo-ship-from", "Ship From", intl)}
                       forceRender={true}>

                    <ECAPAddressInfo streetFldName={["shippingInformation", "shipFromStreet"]}
                                     streetFldRule="SHIPPED_FROM_STREET"
                                     countryDisplayFldName={["shippingInformation", "shipFromCountry"]}
                                     countryCodeFldName={["shippingInformation", "shipFromCountryCode"]}
                                     postalCodeFldName={["shippingInformation", "shipFromPostalCode"]}
                                     regionDisplayFldName={["shippingInformation", "shipFromRegion"]}
                                     regionCodeFldName={["shippingInformation", "shipFromRegionCode"]}
                                     regionCodeFldRule="ShipFrom_Address_US_OR_Canada"
                                     cityFldName={["shippingInformation", "shipFromCity"]}
                                     form={props.form}
                                     noTitle={true}
                                     editable={true}/>
                    <Card size={"small"}>
                        <Row gutter={24}>
                            <Col span={10}>

                                <Form.Item name={["shippingInformation", "shipFromContact"]}
                                           label={getI18n("shippingInfo-shipping-From-contact-person",
                                               "Contact Person", intl)}>
                                    <Input placeholder={getI18n(
                                        "shippingInfo-shipping-From-contact-person",
                                        "Contact Person", intl)
                                    }
                                           allowClear={true}/>
                                </Form.Item>
                            </Col>
                            <Col span={10}>

                                <Form.Item name={["shippingInformation", "shipFromDepartment"]}
                                           label={getI18n("shippingInfo-shipping-From-department",
                                               "Department", intl)}>
                                    <Input placeholder={intl.formatMessage({
                                        id: "shippingInfo-shipping-From-department-hint",
                                        defaultMessage: "Department"
                                    })}
                                           allowClear={true}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Panel>
            </Collapse>
            <Collapse
                defaultActiveKey={2}
                className={"ml-10"}
                expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                activeKey={document.collapsedState}
                onChange={(newActiveKeys) => {
                    // otherwise panels couldn't be open/closed by click
                    dispatch(updateCollapsedState(newActiveKeys));
                }}>
                <Panel key={4.1} forceRender={true}
                       header={getI18n("shippingInfo-ship-to", "Ship To / Service Delivery Address", intl)}>
                    <Row gutter={24}>
                        <Col span={8} className={"ml10 mt5 mb-10"}>
                            <Form.Item
                                name="addressSameAsBMWHeadOffice"
                               // valuePropName="checked"
                            >
                                <Checkbox checked={isChecked} onChange={setBMWHeadOfficeAddress}>
                                    {getI18n("shippingInfo-address-bmw-head-office-address",
                                        "Address = BMW Address", intl)}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <ECAPAddressInfo streetFldName={["shippingInformation", "shipToStreet"]}
                                     streetFldRule="SHIPPED_TO_STREET"
                                     countryDisplayFldName={["shippingInformation", "shipToCountry"]}
                                     countryFldRule="SHIPPED_TO_COUNTRYCODE"
                                     countryCodeFldName={["shippingInformation", "shipToCountryCode"]}
                                     postalCodeFldName={["shippingInformation", "shipToPostalCode"]}
                                     regionDisplayFldName={["shippingInformation", "shipToRegion"]}
                                     regionCodeFldName={["shippingInformation", "shipToRegionCode"]}
                                     regionCodeFldRule="ShipTo_Address_US_OR_Canada"
                                     cityFldName={["shippingInformation", "shipToCity"]}
                                     cityFldRule="SHIPPED_TO_CITYNAME"
                                     form={props.form}
                                     noTitle={true}
                                     editable={true}/>
                </Panel>
            </Collapse>


        </>
    );
};

export default ShippingInfoDetails;