import { getDocumentRecord_getDocumentRecord_shippingInformation } from "./__generated__/getDocumentRecord";
import dayjs from 'dayjs';
import { getCompanyAddressDetails } from "./services/PartnerService";
import { FormInstance } from "antd/lib/form/hooks/useForm";
import {useAppDispatch } from "../../main/hooks";
import { updateCompanyCountryCode } from "./IStoredDocumentRecordSlice";



export const populateShippingInformationDetails = (shippingInformation) => {
  return {
    "ecDocumentShippingInfoId": shippingInformation?.ecDocumentShippingInfoId,
    "shipFromCity": shippingInformation?.shipFromCity,
    "shipFromContact": shippingInformation?.shipFromContact,
    "shipFromCountry": shippingInformation?.shipFromCountry,
    "shipFromDepartment": shippingInformation?.shipFromDepartment,
    "shipFromPostalCode": shippingInformation?.shipFromPostalCode,
    "shipFromRegion": shippingInformation?.shipFromRegion,
    "shipFromStreet": shippingInformation?.shipFromStreet,
    "shipToCity": shippingInformation?.shipToCity,
    "shipToContact": shippingInformation?.shipToContact,
    "shipToCountry": shippingInformation?.shipToCountry,
    "shipToDepartment": shippingInformation?.shipToDepartment,
    "shipToPostalCode": shippingInformation?.shipToPostalCode,
    "shipToRegion": shippingInformation?.shipToRegion,
    "shipToStreet": shippingInformation?.shipToStreet
  };
};
export const populateDocumentDetails = (documentDetails) => {

  return {
    "documentTypeId": documentDetails?.documentType,
    "documentStatusId": documentDetails?.documentStatusId,
    "companyPartnerId": documentDetails?.companyPartnerId,
    "companyCode": documentDetails?.companyCode,
    "partnerVatCodeId": documentDetails?.partnerVatCode,
    "bmwIsRetailOutlet": documentDetails?.bmwIsRetailOutlet,
    "bmwBusinessAreaId": documentDetails?.bmwBusinessAreaId,
    "bmwBusinessUnitId": documentDetails?.documentStatusId,
    "bmwContactName": documentDetails?.bmwContactName,
    "bmwContactDepartment": documentDetails?.bmwContactDepartment,
    "bmwContactTelephone": documentDetails?.bmwContactTelephone,
    "bmwContactEmail": documentDetails?.bmwContactEmail,
    "currencyCodeId": documentDetails?.currencyCodeId,
    "headerCurrency": documentDetails?.currencyCodeId,
    "localCurrencyCodeId": documentDetails?.localCurrencyCodeId,
    "exchangeRate": documentDetails?.exchangeRate,
    "exchangeRateDate": documentDetails.exchangeRateDate? dayjs(documentDetails.exchangeRateDate):null,
    "documentNumber": documentDetails?.documentNumber,
    "documentDate": documentDetails.documentDate?dayjs(documentDetails?.documentDate):null,
    "orderNumber": documentDetails?.orderNumber,
    "deliveryNumber": documentDetails?.deliveryNumber,
    "deliveryDate": documentDetails.deliveryDate?dayjs(documentDetails?.deliveryDate):null,
    "documentFormatId": documentDetails?.documentFormatId,
    "processCodeId": documentDetails?.processCodeId,
    "originalInvoiceNumber": documentDetails?.originalInvoiceNumber,
    "agreementReference": documentDetails?.agreementReference,
    "headerText": documentDetails?.headerText,
    "footerText": documentDetails?.footerText,
    "grossAmount": documentDetails?.grossAmount,
    "netAmount": documentDetails?.netAmount,
    "taxAmount": documentDetails?.taxAmount,
    "taxableAmount": documentDetails?.taxableAmount,
    "localCurrencyGrossAmount": documentDetails?.localCurrencyGrossAmount,
    "localCurrencyTaxAmount": documentDetails?.localCurrencyTaxAmount,
    "localCurrencyTaxableAmount": documentDetails?.localCurrencyTaxableAmount,
    "localCurrencyNetAmount": documentDetails?.localCurrencyNetAmount,
    "paymentTerms": documentDetails?.paymentTerms,
    "bmwGroupPaymentRequest": documentDetails?.bmwGroupPaymentRequest,
    "companyVatCodeId": documentDetails?.companyVatCodeId,
    "companyTaxCode": documentDetails?.bmwCompanyTaxId,
    "earlyPaymentDescription": documentDetails?.earlyPaymentDescription,
    "earlyPaymentDiscountDate": documentDetails.earlyPaymentDiscountDate?dayjs(documentDetails.earlyPaymentDiscountDate):null,
    "earlyPaymentDiscountDueDate": documentDetails.earlyPaymentDiscountDueDate?dayjs(documentDetails.earlyPaymentDiscountDueDate):null
  };
};
export const populateSupplierDetails = (supplierDetails) => {
  //console.log("supplierDetails", supplierDetails);
  return {
    "documentPartnerProfileId": supplierDetails?.documentPartnerProfileId,
    "bankAccountNumber": supplierDetails?.bankAccountNumber,
    "bankBranchCode": supplierDetails?.bankBranchCode,
    "bankIban": supplierDetails?.bankIban,
    "bankSwiftBicNumber": supplierDetails?.bankSwiftBicNumber,
    "placeOfRegistration": supplierDetails?.placeOfRegistration,
    "managingDirector": supplierDetails?.managingDirector,
    "legalFormTypeId": supplierDetails?.legalFormType,
    "liquidationRemark": supplierDetails?.liquidationRemark,
    "inLiquidation": supplierDetails?.inLiquidation,
    "hasFiscalRepresentative": supplierDetails?.hasFiscalRepresentative,
    "chairmanOfSupervisoryBoard": supplierDetails?.chairmanOfSupervisoryBoard,
    "companyRegistrationNumber": supplierDetails?.companyRegistrationNumber,
    "companyRegistryData": supplierDetails?.companyRegistryData,
    "contactDepartment": supplierDetails?.contactDepartment,
    "contactEmail": supplierDetails?.contactEmail,
    "contactName": supplierDetails?.contactName,
    "contactTelephone": supplierDetails?.contactTelephone,
    "fiscalRepresentativeAddressCity": supplierDetails?.fiscalRepresentativeAddressCity,
    "fiscalRepresentativeAddressPostalCode": supplierDetails?.fiscalRepresentativeAddressPostalCode,
    "fiscalRepresentativeAddressCountry": supplierDetails?.fiscalRepresentativeAddressCountry,
    "fiscalRepresentativeAddressRegion": supplierDetails?.fiscalRepresentativeAddressRegion,
    "fiscalRepresentativeAddressStreet": supplierDetails?.fiscalRepresentativeAddressStreet,
    "fiscalRepresentativeName": supplierDetails?.fiscalRepresentativeName,
    "fiscalRepresentativeVatId": supplierDetails?.fiscalRepresentativeVatId,
    "partnerVatCode": supplierDetails?.partnerVatCode
  };
}


