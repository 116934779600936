import {IAppComponentProps} from "../../../components";
import {
    DropDownSelection,
    ExternalStateUpdateCallback,
    Initialization,
    LabelLocation,
    OnChangeListener,
    Validator
} from "../InfoPortalInterfaces";
import React, { useEffect, useRef, useState } from "react";
import { getOperators } from "../services/Selectors";
import { Col, Form, FormInstance, Input, InputNumber, Row, Select } from "antd";
import { Form_Item } from "../../e-cap/componets/FormItem-component";
import {useAppSelector} from "../../../main/hooks";
import { antDInputNumberFormatter, antDInputParser, FormatterWrapper } from "../../users/UserFormatPreferenceServices";

type InputWithOperatorProperties =
    {
        initialization: Initialization,
        form: FormInstance<any>,
        rerenderCallback?: ExternalStateUpdateCallback<any>,
        onInputChange?: OnChangeListener<string>,
        onOperatorChange?: OnChangeListener<string>,
        initialInputValue?: string,
        initialSelectedOperator?: string,
        validator?: Validator,
        labelLocation?: LabelLocation,
        displayColon?: boolean,
        isNumeric?: boolean,
        numDecimals?: number,
        operatorFilterPredicate?:(arg:DropDownSelection)=>boolean,
    }
    & IAppComponentProps;


type BaseInputWithOperatorProperties =
    {
        heading: string,
        placeholder: string,
        inputFieldName: string,
        operatorFieldName: string,
        props: InputWithOperatorProperties
    };

function InputWithOperator(
    {
        heading,
        placeholder,
        inputFieldName,
        operatorFieldName,
        props: {
            form,
            intl,
            initialization,
            rerenderCallback,
            onInputChange,
            onOperatorChange,
            validator,
            initialInputValue,
            initialSelectedOperator,
            currentUser,
            distinctEntitlements,
            labelLocation,
            displayColon,
            isNumeric,
            numDecimals,
            operatorFilterPredicate
        }
    }: BaseInputWithOperatorProperties) {
    const [operators, setOperators] = useState<DropDownSelection[]>([]);
    const [val, setValue] = useState<string>();
    const [operatorVal,setOperator] = useState<string>();
    const [_, rerender] = useState<boolean>(false);

  const numericFormatter = useRef<FormatterWrapper>(new FormatterWrapper());

    const { rerenderAfterLanguageChange } = useAppSelector((state) => state.globalStateReducer);
    const forceRerender = () => rerender(current => !current);
    const updateInputField = (eventParam) => {
        if (typeof eventParam === "number") {
            setValue(`${eventParam}`);
        } else {
            if (eventParam?.target?.value) {
                onInputChange?.performAction(eventParam.target.value);
                setValue(eventParam.target.value);
            }
            else {
                onInputChange?.performAction(null);
                setValue(null);
            }
        }
    };

    const isInputEnabled = (arg:string)=> {
        return arg !== 'IN' && arg !== 'IE';
    }

    const isDropDownEnabled = ()=> {
        const numOptions = operators?.length || 0;
        return numOptions > 1;
    };

    const selectOperator = (eventParam) => {
        onOperatorChange?.performAction(eventParam);
        if (!isInputEnabled(eventParam)) {
            const updateInput = {};
            updateInput[inputFieldName] = null;
            form.setFieldsValue(updateInput);
            setValue(null);
        }

        setOperator(eventParam);
        
        forceRerender();
    };

    function setInitialValue() {
        if (initialInputValue) {
            if (!form.getFieldValue(inputFieldName)) {
                const initialValue = {};
                initialValue[inputFieldName] = initialInputValue;
                form.setFieldsValue(initialValue);
                setValue(initialInputValue);
            }
        }

        if (initialSelectedOperator) {
            if (!form.getFieldValue(operatorFieldName)) {
                const initialValue = {};
                initialValue[operatorFieldName] = initialSelectedOperator;
                form.setFieldsValue(initialValue);
                setOperator(initialSelectedOperator);
            }
        }
    }

    useEffect(() => {
        rerenderCallback?.register({
            performAction() {
                forceRerender();
            }
        });

        const initID = initialization.beginInitialize();
        getOperators(currentUser.user_id, distinctEntitlements,isNumeric)
            .then(retrievedOperators => {
                if (operatorFilterPredicate) {
                   setOperators(retrievedOperators.filter(operatorFilterPredicate))
                }
                else {
                    setOperators(retrievedOperators);
                }
            }
        )
            .catch(console.error)
            .then(_ => {
                initialization.finishInitialize(initID);
                setInitialValue();
            });
    }, [rerenderAfterLanguageChange]);

    return <div className={"info-portal-input-with-operator"}>
      <Form.Item label={heading}>
        <Row gutter={24} style={{ marginRight: "-18px" }}>
          <Col style={{ width: "30%" }}>
            <Form.Item name={operatorFieldName}>
              <Select
                size={"middle"}
                showSearch={true}
                defaultValue={isNumeric? 'EQ':'CP'}
                placeholder={intl.formatMessage({ id: "survey-management-grid-header-select" })}
                defaultActiveFirstOption={true}
                filterOption={false}
                disabled={!isDropDownEnabled()}
                onChange={selectOperator}
                notFoundContent={null}>
                {operators.map((item,idx) => (
                  <Select.Option
                      data-testid={`${idx+1}_${operatorFieldName}_selector_${item.id}`}
                      key={`${heading}_${item.id}`} value={item.id}>
                    {`${item.description}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col style={{ paddingLeft: "5px", paddingRight: "0px", width: "70%" }}>
            {!isNumeric &&
            <Form_Item label={""} name={inputFieldName} itemRules={validator?.fetchRule(inputFieldName)}>
              <Input  data-testid={`input_with_operator_${inputFieldName}`} placeholder={placeholder} onChange={updateInputField} disabled={!isInputEnabled(operatorVal)} allowClear={true}/>
            </Form_Item>
            }
            {isNumeric &&
            <Form_Item label={""} name={inputFieldName} itemRules={validator?.fetchRule(inputFieldName)}>
              <InputNumber placeholder={placeholder} onChange={updateInputField} controls={false}
                           formatter={(value,info)=>numericFormatter.current.antDInputNumberFormatter(currentUser, value,  info, numDecimals || 0)}
                           parser={(value)=> numericFormatter.current.antDInputParser(currentUser,value)}
                           precision={numDecimals || 0} />
            </Form_Item>
            }
          </Col>
        </Row>
      </Form.Item>


      {/*       </div>*/}
    </div>;
}

export function PartnerInvoiceNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
        heading={props.intl.formatMessage({id: "partner-invoice-number", defaultMessage: "Partner Invoice Number"})}
        placeholder={""}
        operatorFieldName={"partnerInvoiceOperator"}
        inputFieldName={"partnerInvoiceNumber"} props={props}/>;
}

export function BMWInvoiceNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"bmwInvoiceNumberOperator"}
        inputFieldName={"bmwInvoiceNumber"}
        heading={props.intl.formatMessage({id: "bmw-invoice-number", defaultMessage: "BMW Invoice Number"})}
        placeholder={""}
        props={props}/>;
}

export function GRSCDocumentNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
      operatorFieldName={"grScDocumentNumberOperator"}
      inputFieldName={"grScDocumentNumber"}
      heading={props.intl.formatMessage({ id: "bmw-gr-sc-document-number", defaultMessage: "GR/SC Document Number" })}
      placeholder={""}
      props={props}/>;
}

export function PurchaseOrderNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"purchaseOrderNumberOperators"}
        inputFieldName={"purchaseOrderNumber"}
        heading={props.intl.formatMessage({id: "purchase-order-number", defaultMessage: "Purchase Order Number"})}
        placeholder={""}
        props={props}/>;
}

export function DeliveryNoteNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"deliveryNoteNumberOperators"}
        inputFieldName={"deliveryNoteNumber"}
        heading={props.intl.formatMessage({id: "bmw-delivery-note-number", defaultMessage: "Delivery Note Number"})}
        placeholder={""}
        props={props}/>;
}

export function GrossAmount(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"grossAmountOperator"}
        inputFieldName={"grossAmount"}
        heading={props.intl.formatMessage({id: "line-item-gross-amount-title"})}
        placeholder={""}
        props={props}/>;
}

export function SPABOrderNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"spabOrderNumberOperator"}
        inputFieldName={"spabOrderNumber"}
        heading={props.intl.formatMessage({id: "spab-order-number"})}
        placeholder={""}
        props={props}/>;
}

export function PaymentAmount(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"paymentAmountOperator"}
        inputFieldName={"paymentAmount"}
        heading={props.intl.formatMessage({id: "info-portal-payment-amount"})}
        placeholder={""}
        props={props}/>;
}

export function PaymentAdviceNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"paymentAdviceNumberOperator"}
        inputFieldName={"paymentAdviceNumber"}
        heading={props.intl.formatMessage({id: "info-portal-payment-advice-number"})}
        placeholder={""}
        props={props}/>;
}

export function ConfirmationNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
      operatorFieldName={"confirmationNumberOperator"}
      inputFieldName={"confirmationNumber"}
      heading={props.intl.formatMessage({ id: "info-portal-payment-confirmation-number" })}
      placeholder={""}
      props={props}/>;
}

export function FapiaoNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"fapiaoNumberOperator"}
        inputFieldName={"fapiaoNumber"}
        heading={props.intl.formatMessage({ id: "info-portal-fapiao-number" })}
        placeholder={""}
        props={props}/>;
}

export function SupplierClaimNumber(props: InputWithOperatorProperties) {
    return <InputWithOperator
        operatorFieldName={"supplierClaimNumberOperator"}
        inputFieldName={"supplierClaimNumber"}
        heading={props.intl.formatMessage({ id: "info-portal-supplier-claim-number" })}
        placeholder={""}
        props={props}/>;
}

