import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {gql} from "@apollo/client";
import LoginLayout from "../layouts/LoginLayout";
import Banner from "../layouts/Banner"
// eslint-disable-next-line import/named
import {Route, Routes, useNavigate} from "react-router-dom";
import client from "../config/GraphQLApolloClient";
import {
    clearStorage,
    getCurrentLoggedInUser,
    getJwtPromise,
    getLastDocumentReferrer,
    getParameterByName,
    getRealm,
    isValidReferrer,
    replaceUrlDomain,
    setLastDocumentReferrer,
    setRealm,
    storeInitialPathParameters
} from "./OauthUtils";
import MainLayout from "../layouts/MainLayout";
import {PortalUser} from "../components";
import CallbackLayout from "../layouts/WebEAMCallbackLayout";
import {LicenseManager} from "ag-grid-enterprise";
import PortalLoading from "../layouts/PortalLoading";
import LogOutLayout from "../layouts/LogOutLayout";
import {entitlementLookup} from "./entitlementService";
import {
    getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners
} from "./__generated__/getUserEntitlementResultByUserId";

LicenseManager.setLicenseKey(
    // tslint:disable-next-line
    "CompanyName=Titus Corporation_on_behalf_of_BMW SA,LicensedApplication=eInvoicing,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-029969,SupportServicesEnd=5_July_2023_[v2]_MTY4ODUxMTYwMDAwMA==8fdb15c1328ca21d51cc402a3f21b986"
);

const QUERY_GET_WEBEAM_VALIDATE_TOKEN = gql`
    query OAuth_getWebEamValidateToken($token: String!) {
        OAuth_getWebEamValidateToken(token: $token)
    }
`;


const App = () => {
    const intl = useIntl();
    const [currentUser, setCurrentUser] = useState<PortalUser>(undefined);
    const [userLoading, isUserLoading] = useState<boolean>(true);
    const [userLogout, isUserLogout] = useState<boolean>(false);
    const [validToken, isValidToken] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>(undefined);
    const [authorized, isAuthorized] = useState<boolean>(undefined);
    const [userDistinctEntitlements, setUserDistinctEntitlements] = useState<string[]>(undefined);
    const [entitlementPartner, setEntitlementPartner] = useState<getUserEntitlementResultByUserId_getUserEntitlementResultByUserId_partners[]>(undefined);
    const navigate = useNavigate();
    const getUserDistinctEntitlements = () => {
        const userName = getJwtPromise()?.sub;
        entitlementLookup(userName)
            .then((data) => {
                const foundEntitlements = data?.getUserEntitlementResultByUserId?.distinctEntitlements;
                setEntitlementPartner(data?.getUserEntitlementResultByUserId?.partners);
                if (foundEntitlements === null || foundEntitlements === undefined || foundEntitlements.length === 0) {
                    console.error("NO USER ENTITLEMENTS FOUND");
                    setUserDistinctEntitlements([]);
                } else {
                    setUserDistinctEntitlements(foundEntitlements);
                }
            });

    };

    useEffect(() => {
        storeInitialPathParameters();
        setRealm("", "init clear");
        getRealm(true);//call getRealm here to ensure we save the initial realm passed in via query string
        console.warn(`Referred from:: ${document.referrer}`);
        setLastDocumentReferrer();
        const token = localStorage.getItem("access_token");
        if (token !== null && token !== undefined && token !== "undefined") {
            client
                .query({
                    query: QUERY_GET_WEBEAM_VALIDATE_TOKEN,
                    variables: {token},
                    fetchPolicy: "network-only"
                })
                .then((respond) => {
                   isValidToken(true);
                   isAuthorized(true);
                   getUserDistinctEntitlements();
                   sessionStorage.removeItem("sessionExpired");
                   //sessionStorage.removeItem("callBackAlreadyPerformed");
                })
                .catch((err) => {
                    console.error(`Validate token failed: ${err}`);
                    console.log("Calling clear storage on token validation failed");
                    clearStorage();
                    isAuthorized(false);
                    isValidToken(false);
                    sessionStorage.setItem("sessionExpired","true");
                });
        } else {
            if (window.location.pathname.indexOf("logout") === 1) {
                navigate("/logout");
                isUserLoading(true);
            } else {
                isAuthorized(false);
            }
        }
    }, [validToken]);

    if (validToken && currentUser === undefined) {
        Promise.resolve(getCurrentLoggedInUser())
            .then((user) => {
                setCurrentUser(user);
                isUserLoading(false);
                return user;
            })
            .then(user => {
                if (user.language !== localStorage.getItem("locale")) {
                    localStorage.setItem("locale", user.language)
                    window.location.reload();
                }
            })
            .catch((err) => {
                setErrorMessage(err.message);
                isUserLoading(false);
                console.log("Could not determine current user clearing storage");
                if (!isValidReferrer(getLastDocumentReferrer()) && !getParameterByName("realm"))
                {
                    clearStorage();
                    window.location.href = "/logout";
                }

            });
    }


    return (
        <>

            {/*{!userLogout && userLoading || errorMessage &&
                <PortalLoading userLoading={userLoading} errorMessage={errorMessage}/>}*/}

            {false === authorized && <LoginLayout/>}


            <Routes>
                <Route key={"main"} path={"*"}
                       element={
                           <>
                               {currentUser !== undefined ? <Banner intl={intl} currentUser={currentUser}/> : null}
                               <MainLayout currentUser={currentUser} intl={intl}
                                           distinctEntitlements={userDistinctEntitlements} entitlementPartner={entitlementPartner}/>

                           </>
                       }/>
                <Route key={"authorization"} path={"/authorization"} element={<LoginLayout/>}/>
                <Route key={"callback"} path={"/callback"} element={<CallbackLayout/>}/>
                <Route key={"logout"} path={"/logout"}
                       element={<LogOutLayout authenticated={false} oAuthUrl={""} error={""} isReady={false}/>}/>


            </Routes>


        </>
    );
};
export default App;