import { IAppComponentProps } from "../../components";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, FormInstance, notification, Row, Select} from "antd";
import {PortalFormItem} from "../../components/FormUtils";
import { CompanyCode } from "../../components/CompanyCode";
import { SurveyStatus} from "../../components/SurveyStatus";
import _, { keys } from "lodash";
import {  getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners }
   from "./../../main/__generated__/getSelfEntitlementResultByFilter";
import { getI18n } from "./../../utils/Utils";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../main/hooks";
import { updateSurveyManagementCriteria } from "./IStoredSurveySlice";
import { PartnerLookup } from "../../components/PartnerLookup";

type SurveyManagementFormProps = {
    onSearch: any;
    bubbleFormInstance?: (formInstance:FormInstance<any>)=>void;
    hideButtons?:boolean;

} & IAppComponentProps;

const Survey_Overview_Entitlement = "display@survey";

const SurveyManagementForm: React.FunctionComponent<SurveyManagementFormProps> = (props) => {

    const intl = useIntl();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [loading, isLoading] = useState<boolean>(false);
    const [partnerName, setPartnerName] = useState<string>(undefined);
    const [partnerNo, setPartnerNo] = useState(undefined);
    const [selectOptions, setSelectOption] = useState<getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[]>([]);
    const dispatch = useAppDispatch();
    const [filterOption, setFilterOption] = useState<string>("contains")

    const surveyState = useAppSelector((state) => state.survey);

    const onSearch = () => {
        form.validateFields()
            .then((values) => {
                dispatch(updateSurveyManagementCriteria(values));
                props.onSearch(values);
            })
            .catch((err) => {
                console.log(err)
                notification.error((err));
            })
    };

    const onClear = () => {
        // eslint-disable-next-line no-console
        form.resetFields();
    };

    const handlePartnerChange = (value: string) => {
    //console.log(`Selected supplier: ${value}`);
    setPartnerNo(value);
    };

    const handleFilterOptionChange = (value) => {
        setFilterOption(value);
    }

    const additionalPartnerFilter  = (data: getSelfEntitlementResultByFilter_getSelfEntitlementResultByFilter_partners[],partnerName: string) =>{
        if (filterOption.toLowerCase() === 'exact')
        {
            return Array.from(data.filter(item => item?.partnerNo === partnerName).values());
        }
        else if (filterOption.toLowerCase() === 'startswith')
        {
            return Array.from(data.filter(item => item?.partnerNo.startsWith(partnerName)).values());
        }
        return data;
        
    }
    
    useEffect(()=> {
    if (props.bubbleFormInstance) {
        props.bubbleFormInstance(form);
    }
    if (surveyState.survey.managementSearchCriteria !== undefined)
    {
        //console.log("survey management");
        //console.log(surveyState.survey.managementSearchCriteria);
       
        form.setFieldsValue(surveyState.survey.managementSearchCriteria);
        props.onSearch(surveyState.survey.managementSearchCriteria);
        setPartnerNo(surveyState.survey.managementSearchCriteria.partnerNo);
        setPartnerName(surveyState.survey.managementSearchCriteria.partnerName);
    }
    },[])

    return (
        <>
            <Form id="survey-management-form" size={'large'} form={form} layout={'vertical'} onFinish={() => onSearch()}>
                <Row gutter={24} className="mb10" hidden={props.hideButtons}>
                    <Col xs={24} sm={6} md={4}>
                        <Button
                            id={'survey-management-search-button'}
                            type={'primary'}
                            htmlType="submit"
                            size="middle"
                            className="w-100 mt10"
                            data-testid={'btn-search'}
                        >
                            {props.intl.formatMessage({ id: 'management-search-btn-text', defaultMessage: 'SEARCH' })}
                        </Button>
                    </Col>

                    <Col xs={24} sm={6} md={4}>
                        <Button
                            id={'survey-management-clear-button'}
                            type="primary"
                            onClick={onClear}
                            size="middle"
                            className="w-100 mt10"
                            data-testid={'btn-clear'}
                        >
                            {props.intl.formatMessage({ id: 'management-clear-btn-text', defaultMessage: 'CLEAR' })}
                        </Button>
                    </Col>
                </Row>
                <Card
                    title={props.intl.formatMessage({
                        id: 'management-searchmask-title',
                        defaultMessage: 'Search Mask',
                    })}
                    size={'small'}
                >
                    <Row gutter={24}>
                        <Col span={7}>
                            <Form.Item label={getI18n('partner-lookup-filter-options-item-label', 'Partner Filter Option', intl)} name="filterOption">
                                <Select defaultValue="contains" style={{ width: 120 }} onChange={handleFilterOptionChange} >
                                    <Option value="contains">{getI18n('partner-lookup-filter-options-contains', 'Contains', intl)}</Option>
                                    <Option value="exact">{getI18n('partner-lookup-filter-options-exact', 'Exact', intl)}</Option>
                                    <Option value="startswith">{getI18n('partner-lookup-filter-options-startswith', 'Starts With', intl)}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <PartnerLookup
                            intl={props.intl}
                            entitlement={Survey_Overview_Entitlement}
                            onChange={handlePartnerChange}
                            currentUser={props.currentUser}
                            distinctEntitlements={props.distinctEntitlements}
                            filter={additionalPartnerFilter}
                            colSpan={7}
                        />
                        <CompanyCode
                            currentUser={props.currentUser}
                            intl={props.intl}
                            distinctEntitlements={props.distinctEntitlements}
                        />
                        <PortalFormItem
                            label={props.intl.formatMessage({
                                id: 'management-surveyid-label',
                                defaultMessage: 'Survey ID',
                            })}
                            name={'surveyId'}
                            placeHolder={props.intl.formatMessage({
                                id: 'management-surveyid-placeholder',
                                defaultMessage: 'Survey ID',
                            })}
                        />
                        <SurveyStatus
                            currentUser={props.currentUser}
                            intl={props.intl}
                            distinctEntitlements={props.distinctEntitlements}
                        />
                        <PortalFormItem
                            label={props.intl.formatMessage({
                                id: 'management-partnername-label',
                                defaultMessage: 'Partner Name',
                            })}
                            name={'partnerName'}
                            placeHolder={props.intl.formatMessage({
                                id: 'management-partnername-placeholder',
                                defaultMessage: 'Partner Name',
                            })}
                        />
                        <PortalFormItem
                            label={props.intl.formatMessage({
                                id: 'management-user-email-label',
                                defaultMessage: 'User Email Address',
                            })}
                            name={'email'}
                            placeHolder={props.intl.formatMessage({
                                id: 'management-user-email-placeholder',
                                defaultMessage: 'User Email Address',
                            })}
                        />
                    </Row>
                </Card>
            </Form>
        </>
    );
};
export default SurveyManagementForm;